import { IEvent } from 'services/api/organizationsV2/event/IEvent';

export interface IEventDetails {
  eventPk: number;
  eventName: string;
  eventTypeName: string | undefined;
  isClosed: boolean;
  eventBillingAccount: {
    billingAccountPk: number;
    address: string;
    codeNumber: string;
  };
}

const toEventDetails = (event: IEvent): IEventDetails => {
  const {
    event_pk: eventPk,
    event_name: eventName,
    event_type: { event_type_rendition: eventTypeName },
    event_billing_account: {
      billing_account_code_number: codeNumber,
      billing_account_address: address,
      billing_account_pk: billingAccountPk,
    },
    event_is_closed: isClosed,
  } = event;
  return {
    eventPk,
    eventTypeName,
    eventName,
    isClosed,
    eventBillingAccount: {
      billingAccountPk,
      address: address || '',
      codeNumber,
    },
  };
};
export default toEventDetails;
