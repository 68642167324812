import Task from 'models/Task';
import { IFormValuesTask } from '../TaskForm';
import { useSelector } from 'react-redux';
import {
  getDefaultStatusFromStore,
  getTaskFormValuesFromStore,
} from 'store/task/selectors';
import { NEW_TASK_KEY } from '../constants/NEW_TASK';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { useMemo } from 'react';
import {
  formatDateFromServer,
  isDateInPast,
} from '../../../../services/utils/dateHelper/dateHelper';

type UseGetTaskDefaultValuesProps = {
  taskPk?: number;
  initialValuesFromOrder?: IFormValuesTask;
  initialValuesFromParentTask?: IFormValuesTask | null;
  newTask: IFormValuesTask | Task;
  isDefaultAccountOrCounterparty: boolean;
};

function mergeValuesWithStatus(
  current: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask;

function mergeValuesWithStatus(
  current?: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask | undefined;

function mergeValuesWithStatus(
  current?: IFormValuesTask,
  statusDefault?: IClientSelectOptionV2
): IFormValuesTask | undefined {
  if (!current) {
    return undefined;
  }

  return current?.status ? current : { ...current, status: statusDefault };
}

const getValuesForDefaultAccountOrCounterparty = (
  newTask: IFormValuesTask | Task,
  defaultStatus?: IClientSelectOptionV2,
  current?: IFormValuesTask
) => {
  const mergedValues = {
    ...current,
    account: newTask.account,
    counterparty: newTask?.counterparty,
  };

  return mergeValuesWithStatus(mergedValues, defaultStatus);
};

// Функция для проверки и обновления internal_task_date_duedate
const updateTaskDueDateIfPast = (
  task: IFormValuesTask,
  getCurrentDate: () => string
): IFormValuesTask => {
  if (
    task?.internal_task_date_duedate &&
    isDateInPast(task.internal_task_date_duedate)
  ) {
    return {
      ...task,
      internal_task_date_duedate: getCurrentDate(),
    };
  }
  return task;
};

const useGetTaskDefaultValues = ({
  taskPk,
  newTask,
  initialValuesFromOrder,
  initialValuesFromParentTask,
  isDefaultAccountOrCounterparty,
}: UseGetTaskDefaultValuesProps): IFormValuesTask | Task => {
  const formTaskValues = useSelector(getTaskFormValuesFromStore);
  const taskId = taskPk ?? NEW_TASK_KEY;
  const defaultStatus = useSelector(getDefaultStatusFromStore)

  const { current, initial } = formTaskValues?.[taskId] || {};

  const getCurrentDateToClientDate = () => formatDateFromServer(new Date());

  return useMemo(() => {
    if (initialValuesFromParentTask) {
      return mergeValuesWithStatus(initialValuesFromParentTask, defaultStatus);
    }

    if (initialValuesFromOrder) {
      return mergeValuesWithStatus(initialValuesFromOrder, defaultStatus);
    }

    if (isDefaultAccountOrCounterparty) {
      return getValuesForDefaultAccountOrCounterparty(
        newTask,
        defaultStatus,
        current
      );
    }

    if (taskId === NEW_TASK_KEY && current) {
      const mergeNewTaskWithStatus = mergeValuesWithStatus(
        current,
        defaultStatus
      );

      return updateTaskDueDateIfPast(
        mergeNewTaskWithStatus,
        getCurrentDateToClientDate
      );
    }

    return mergeValuesWithStatus(current, defaultStatus) || initial || newTask;
  }, [
    current,
    defaultStatus,
    initial,
    initialValuesFromOrder,
    initialValuesFromParentTask,
    isDefaultAccountOrCounterparty,
    newTask,
    taskId,
  ]);
};

export default useGetTaskDefaultValues;
