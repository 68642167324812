import {
  IGetPaginatedTasksParams,
  ITaskFilters,
} from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import config from 'config.json';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import TObjectKeysInArray from 'services/types/TObjectKeys';

type TTaskArrayKey =
  | 'internal_task_author_dk_array'
  | 'internal_task_supervisor_dk_array'
  | 'internal_task_type_fk_array'
  | 'internal_task_billing_account_fk_array'
  | 'internal_task_status_fk_array'
  | 'internal_task_counterparty_fk_array';

type TTaskArrayOptions = Record<TTaskArrayKey, number[]>;

class TaskParamsTransformer {
  private readonly arrayKeys: TTaskArrayKey[] = [
    'internal_task_author_dk_array',
    'internal_task_supervisor_dk_array',
    'internal_task_type_fk_array',
    'internal_task_billing_account_fk_array',
    'internal_task_status_fk_array',
    'internal_task_counterparty_fk_array',
  ];

  private readonly exeptionKeys: TObjectKeysInArray<IGetPaginatedTasksParams> =
    ['internal_task_is_pruned'];
  private readonly removableKeys: TObjectKeysInArray<ITaskFilters> = [
    'isActiveCounterpartyFilter',
  ];

  private readonly isPrunedEnabled: boolean;

  private params: ITaskFilters;

  constructor(params: ITaskFilters) {
    this.params = params;
    this.isPrunedEnabled = config.isPrunedOn;
  }

  private extractArrayOptions(filters: ITaskFilters): TTaskArrayOptions {
    return composeClientSelectOptionsInNumbersArray<
      ITaskFilters,
      TTaskArrayOptions
    >(filters, this.arrayKeys);
  }

  private determineIsPruned(
    isPrunedOption?: IClientSelectOptionV2<boolean>
  ): boolean | undefined {
    return this.isPrunedEnabled
      ? extractValueFromClientSelectOptionV2(isPrunedOption)
      : false;
  }

  private deleteUnnecessaryParams(params: IGetPaginatedTasksParams) {
    return deleteUnnecessaryParameters(
      params,
      this.removableKeys,
      this.exeptionKeys
    );
  }

  public transform(): IGetPaginatedTasksParams {
    const selectOptions = this.extractArrayOptions(this.params);
    const isPrunedOption = this.determineIsPruned(
      this.params?.internal_task_is_pruned
    );

    const composedParams: IGetPaginatedTasksParams = {
      ...this.params,
      ...selectOptions,
      internal_task_is_pruned: isPrunedOption,
    };

    const trimmedParams = trimStringInObject(composedParams);

    return this.deleteUnnecessaryParams(trimmedParams);
  }
}

export const transformTaskParams = (
  params: ITaskFilters
): IGetPaginatedTasksParams => {
  const transformer = new TaskParamsTransformer(params);
  return transformer.transform();
}
