import React, { FC } from 'react';
import ExternalRequestEditPage from '../ExternalRequestEditPage/ExternalRequestEditPage';
import useFirstExternalRequestStatus from '../hooks/useFirstExternalRequestStatus';
import useClearExternalRequestValues from '../hooks/useClearExternalRequestValues';

type OrderEditPagePropTypes = {
  id?: string;
};

const ExternalRequestCreateDuplicatePage: FC<OrderEditPagePropTypes> = () => {
  useClearExternalRequestValues();
  useFirstExternalRequestStatus();

  return <ExternalRequestEditPage isDuplicate />;
};
export default ExternalRequestCreateDuplicatePage;
