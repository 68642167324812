import MainLayout from 'components/layouts/MainLayout/MainLayout';
import React, { FC, useCallback, useMemo } from 'react';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import useGetRefreshingData from '../../../hooks/useGetRefreshingData';
import {
  PATH_CONTROLLER_PAGE,
  TAB_QUERY_KEY,
  TAB_QUERY_PARAMS,
} from '../../../services/pathConstants';
import DocumentsPage from 'pages/documents/DocumentsPage/DocumentsPage';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import PagerWithParameterSwitch from 'components/misc/Pagers/PagerWithParameterSwitch/PagerWithParameterSwitch';
import { parentUIElement } from 'services/constants/parentUIElement';
import SubHeaderEditEntity from 'components/controls/FormHeader/FormHeaderEditEntity/SubHeaderEditEntity';
import { useParams } from 'react-router-dom';
import {
  getHistoryControllerApiV2,
  readControllerApi,
} from 'services/api/controller/controllerApi';
import Controller from 'models/Controller/Controller';
import { targetAccessPolicyController } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyController';
import useControllerTabs from './hooks/useGetControllerTabs/useGetControllerTabs';
import ControllerForm from 'components/controller/controller/ControllerForm/ControllerForm';
import ControllerHistory from 'components/controller/controller/ControllerHistory/ControllerHistory';
import { formTitleController } from 'services/constants/FormTitles/FormTitles';
import useUtilsControllerEdit from './hooks/useUtilsControllerEdit/useUtilsControllerEdit';
import useSuccessHandlerNavigate from 'hooks/useSuccessHandlerNavigate/useSuccessHandlerNavigate';

type ControllerPropsType = {
  pk?: string;
};

const ControllerEditPage: FC<ControllerPropsType> = () => {
  const { id } = useParams<{ id: string }>();

  const pk = id as string;

  const isNew = !pk;

  const initialParams = useMemo(
    () => ({
      pk,
    }),
    [pk]
  );

  const { tabs, refreshDocumentTabs, isLoadingTab } = useControllerTabs({
    id: pk,
  });

  const { toController, controllerConvertHistory } = useUtilsControllerEdit();

  const {
    data: initialValue,
    isLoading,
    refreshData,
  } = useGetRefreshingData({
    getDataApi: readControllerApi,
    params: initialParams,
    condition: !isNew,
    convertData: toController,
    initialData: new Controller(pk),
  });

  const {
    data: historyData,
    isLoading: isLoadingHistory,
    refreshData: refreshHistory,
  } = useGetRefreshingData({
    getDataApi: getHistoryControllerApiV2,
    initialData: null,
    params: initialParams,
    condition: !isNew,
    convertData: controllerConvertHistory,
  });

  const [haveAccessToUpdate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyController,
    targetAction: ['U'],
  });

  const refresh = useCallback(() => {
    refreshHistory();
    refreshData();
  }, [refreshData, refreshHistory]);

  const successHandler = useSuccessHandlerNavigate(PATH_CONTROLLER_PAGE);

  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.edit]: (
      <ControllerForm
        refreshData={refresh}
        successHandler={successHandler}
        controller_pk={pk}
        initialValues={initialValue}
        isLoading={isLoading}
        isViewOnly={!haveAccessToUpdate}
      />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.controller}
        parentUIPk={pk + ''}
        refreshTabs={refreshDocumentTabs}
        viewOnly={!haveAccessToUpdate}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <ControllerHistory
        controllerRequestHistory={historyData?.rows}
        creationControllerInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
  };

  const subHeaderTitle = formTitleController.getFormTitle(pk);

  const targetTab =
    (useGetQueryParameter(
      TAB_QUERY_KEY.controller
    ) as keyof typeof CONTENT_MAP) || TAB_QUERY_PARAMS.edit;

  const ContentForRender = CONTENT_MAP[targetTab];

  return (
    <MainLayout>
      <SubHeaderEditEntity title={subHeaderTitle} />
      {!isNew && (
        <PagerWithParameterSwitch
          items={tabs}
          targetTab={targetTab}
          isLoadingCountTabs={isLoadingTab}
        />
      )}
      {ContentForRender}
    </MainLayout>
  );
};

export default ControllerEditPage;
