import { IBillingAccount, IBillingAccountShort } from 'services/api/organizationsV2/billing-account/IBillingAccount';
import ICounterPartyInEntities from '../../api/organizationsV2/counterparty/ICounterPartyInEntities';
import { t } from '../translation';
import getInitials from '../getInicials/getInicials';
import { regBillingAccountNumberInLabel } from '../myRegex/myRegex';
 
export const isLegalStatusCompany = (
  accountCounterparty: ICounterPartyInEntities
) => !!accountCounterparty?.counterparty_company_full_name;

export const extractCounterpartyCompanyName = (
  counterparty: ICounterPartyInEntities
) => {
  const { counterparty_company_full_name: companyFullName } = counterparty;
  if (!!companyFullName) {
    return companyFullName;
  } else {
    return '';
  }
};

export const extractCounterpartyIndividualName = (
  counterparty: ICounterPartyInEntities,
  isInitials?: boolean
) => {
  const {
    counterparty_individual_last_name: lastName,
    counterparty_individual_first_name: firstName,
    counterparty_individual_middle_name: middleName = '',
  } = counterparty;
  return !isInitials
    ? `${lastName ?? ''} ${firstName ?? ''} ${middleName}`.trim()
    : getInitials({firstName, lastName, middleName});
};

export const extractCounterpartyName = (
  counterparty: ICounterPartyInEntities
): string => {
  return isLegalStatusCompany(counterparty)
    ? extractCounterpartyCompanyName(counterparty)
    : extractCounterpartyIndividualName(counterparty);
};

export const extractCounterpartyFromAccount = (account: IBillingAccountShort) =>
  account?.billing_account_counterparty || null;

const BILLING_ACCOUNT_TITLE_CAPTIONS = {
  BILLING_ACCOUNT: t('ЛC'),
  COUNTERPARTY: t('Контрагент'),
  ADDRESS: t('адрес'),
};

export const toTitleBillingAccount = (
  billingAccount: IBillingAccount | IBillingAccountShort
): string => {
  //TODO: адрес брать из адреса после выполнения таски Undercurrent #86
  const {
    billing_account_code_number: billingAccountCodeNumber,
    billing_account_description: address,
  } = billingAccount;

  const titleParts = {
    [BILLING_ACCOUNT_TITLE_CAPTIONS.BILLING_ACCOUNT]: billingAccountCodeNumber,

    [BILLING_ACCOUNT_TITLE_CAPTIONS.COUNTERPARTY]: extractCounterpartyName(
      billingAccount.billing_account_counterparty
    ),
    [BILLING_ACCOUNT_TITLE_CAPTIONS.ADDRESS]: address,
  };
  return Object.entries(titleParts).reduce(
    (currentValue, [label, value]: [string, string | undefined]) => {
      return value
        ? currentValue + `${label}: ${value} `
        : currentValue + `${label}: - `;
    },
    ''
  );
};

export const extractClientSelectOptionsFromCounterpartyInEntity = (
  counterparty?: ICounterPartyInEntities,
  isInitials?: boolean
) => {
  if (counterparty === undefined) {
    return undefined;
  }
  return isLegalStatusCompany(counterparty)
    ? {
        label: extractCounterpartyCompanyName(counterparty),
        value: counterparty.counterparty_pk,
      }
    : {
        label: extractCounterpartyIndividualName(counterparty, isInitials),
        value: counterparty.counterparty_pk,
      };
};
export const getAccountNumber = (tittle: string) =>
  tittle.match(regBillingAccountNumberInLabel) + '';
