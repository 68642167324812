import { RootStateOrAny } from 'react-redux';
import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';

export const getBillingAccountFilters = (
  state: RootStateOrAny
): IBillingAccountFilters => state.billingAccounts.tableFilters;

export const getBillingAccountMenuItems = (state: RootStateOrAny) =>
  state.billingAccounts.menu;

export const getBillingAccountTaskTableFiltersFromStore = (
  state: RootStateOrAny
): { [x: number]: ITaskFilters } => state.billingAccounts.taskTableFilters;

export const getBillingAccountExternalRequestTableFiltersFromStore = (
  state: RootStateOrAny
): { [x: number]: IExternalRequestFilters } =>
  state.billingAccounts.externalRequestTableFilters;
