import { FC } from 'react';
import DocumentsPage from '../DocumentsPage/DocumentsPage';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';

const FileBucketArchivePage: FC = () => {
  return (
    <MainLayout>
      <Subheader />
      <DocumentsPage isDocumentRegistrarPage />
    </MainLayout>
  );
};

export default FileBucketArchivePage;
