import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import React, { ReactNode, memo } from 'react';
import { createTooltipId } from 'services/utils/createId/createId';

interface WithTooltipProps {
  id: string;
  children: ReactNode;
  isVisible?: boolean;
  textTooltip: string;
}

const WithTooltip: React.FC<WithTooltipProps> = ({
  id,
  children,
  textTooltip,
  isVisible = true,
}) => {
  const tooltipId = createTooltipId(id) as string;
  return (
    <div id={tooltipId}>
      {children}
      <ToolTipWrapper target={tooltipId} placement="top" isVisible={isVisible}>
        {textTooltip}
      </ToolTipWrapper>
    </div>
  );
};

export default memo(WithTooltip);
