import { IHandlerExternalRequestFormProps } from '../types/IHandlerExternalRequestFormProps';
import { t } from '../../../../services/utils/translation';
import styles from './index.module.scss';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import { ChangeEvent, FC, memo } from 'react';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectOnlyActiveEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import { IThirdSectionExternalRequestState } from '../types/IStateExternalRequestForm';
import config from 'config.json';
import DatePickerField from 'components/controls/DatePickerField';
import { getForSelectWorkCategoryApiV2 } from '../../../../services/api/external-request/work-category/workCategoryApi';
import { getForSelectCounterpartyAffiliateApiV2 } from 'services/api/organizationsV2/counterparty/counterpartyApi';
// import TextField from 'components/controls/TextField';
// import SelectField from 'components/controls/SelectField';
import RadiosField from 'components/controls/RadiosField';
import { useFormikContext } from 'formik';
import { IFormValuesExternalRequestFromTask } from '../../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import ExternalRequest from '../../../../models/ExternalRequest';
// import useHandleBlurWrappersForExternalRequest from '../formUtils/useHandleBlurWrappersForExternalRequest';
import useSetAndGetExternalRequestAccessPolicy from 'pages/external-request/external-requests/ExternalRequestPage/hooks/useSetExternalRequestAccessPolicy';
import {
  EXTERNAL_REQUEST_NO_PERFORMERS,
  EXTERNAL_REQUEST_SET_PERFORMERS,
} from '../../../../pages/external-request/external-requests/ExternalRequestEditPage/utils/consts';

// const [CASH, BANK_TRANSFER, FROM_STEK] = [
//   t('Нал.'),
//   t('Безнал.'),
//   t('Со счета СТЕК'),
// ];

// // const paymentTypes = {
// //   [CASH]: CASH,
// //   [BANK_TRANSFER]: BANK_TRANSFER,
// //   [FROM_STEK]: FROM_STEK,
// // };

// const [NOT_PAID, RECEIVED, ACCEPTED_AT_CHECKOUT, REPAID] = [
//   t('Не оплачено'),
//   t('Получено на руки'),
//   t('Принято в кассу'),
//   t('Погашено'),
// ];
// const paymentStatuses = {
//   [NOT_PAID]: NOT_PAID,
//   [RECEIVED]: RECEIVED,
//   [ACCEPTED_AT_CHECKOUT]: ACCEPTED_AT_CHECKOUT,
//   [REPAID]: REPAID,
// };

interface PropsTypeExternalExternalRequestThirdSection {
  handlerFormProps: IHandlerExternalRequestFormProps;
  state: IThirdSectionExternalRequestState;
}

const ExternalRequestThirdSection: FC<PropsTypeExternalExternalRequestThirdSection> =
  (props) => {
    const {
      handlerFormProps: { saveValueInStoreForExternalRequest, hasChange },
      state: {
        isDisable,
        initialValues: {
          initialWorkCategory,
          initialImplementers,
          initialExecutiveImplementer,
          initialAffiliate,
          // initialPaymentKind,
          // initialPaymentAmount,
          // initialPaymentStatus,
          initialSupposedDuedate,
        },
      },
    } = props;

    const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
      useFormikContext<IFormValuesExternalRequestFromTask | ExternalRequest>();

    const { workCategoryHaveAccessToRead, employeeHaveAccessToRead } =
      useSetAndGetExternalRequestAccessPolicy();

    const {
      workCategory,
      performerType,
      implementers,
      executiveImplementer,
      affiliate,
      // external_request_payment_kind: paymentKind,
      // external_request_payment_amount: paymentAmount,
      // external_request_payment_status: paymentStatus,
      external_request_date_supposed_duedate: supposedDuedate,
    } = values;

    const handlePerformerTypeChange = (
      performerType: string,
      setFieldValue: Function
    ) => {
    if (performerType === EXTERNAL_REQUEST_NO_PERFORMERS) {
        setFieldValue('implementers', [], false);
        saveValueInStoreForExternalRequest({
          value: {
            implementers: [],
            performerType: performerType,
          },
        });
        return;
    } else if (performerType === EXTERNAL_REQUEST_SET_PERFORMERS) {
        saveValueInStoreForExternalRequest({
          value: {
            performerType: performerType,
          },
        });
        return;
      } else {
        console.error(
          t(
            'Некорректный тип ответственного лица. Обратитесь к администратору.'
          )
        );
      }
    };

    // const { handleBlurPaymentAmount } =
    //   useHandleBlurWrappersForExternalRequest();

    return (
      <>
        <DynamicSelectPaginatedFieldV2
          id="workCategory"
          label={t('Классификация работ')}
          placeholder={t('Выберите класс работ...')}
          hasError={errors.workCategory != null && touched.workCategory != null}
          selectHandler={getForSelectWorkCategoryApiV2}
          options={workCategory}
          additionalErrorKey="external_request_work_category"
          disabled={isDisable}
          onChange={(workCategory: IClientSelectOptionV2<number>) => {
            saveValueInStoreForExternalRequest({
              value: { workCategory },
            });
          }}
          highlightEditing={hasChange(workCategory, initialWorkCategory)}
          required={true}
          haveReadPermission={workCategoryHaveAccessToRead}
        />
        <DatePickerField
          id="external_request_date_supposed_duedate"
          label={t('Срок исполнения')}
          hasError={
            errors.external_request_date_supposed_duedate != null &&
            touched.external_request_date_supposed_duedate != null
          }
          onBlur={handleBlur}
          onChange={async (date: string) => {
            await setFieldValue('external_request_date_supposed_duedate', date);
            saveValueInStoreForExternalRequest({
              value: {
                external_request_date_supposed_duedate: date,
              },
            });
          }}
          defaultValue={supposedDuedate}
          format={config.clientDateFormat}
          disabled={isDisable}
          highlightEditing={hasChange(initialSupposedDuedate, supposedDuedate)}
          minDate={new Date()}
          required={true}
        />
        <fieldset className={styles.performer} disabled={isDisable}>
          <h4>{t('Исполнитель')}</h4>
          <RadiosField
            name="performerType"
            hasErrors={
              errors.performerType != null && touched.performerType != null
            }
            options={[
              {
                label: t('Не требуется'),
              value: EXTERNAL_REQUEST_NO_PERFORMERS,
              },
              {
                label: t('Назначить'),
              value: EXTERNAL_REQUEST_SET_PERFORMERS,
              },
            ]}
            checkedValue={performerType}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handlePerformerTypeChange(event.target.value, setFieldValue);
              handleChange(event);
              saveValueInStoreForExternalRequest({
                value: { performerType: event.target.value },
              });
            }}
            inline={true}
            required={true}
            disabled={isDisable}
          />
        {performerType === EXTERNAL_REQUEST_SET_PERFORMERS && (
            <>
              <DynamicSelectPaginatedFieldV2
                id="executiveImplementer"
                label={t('Ответственный исполнитель')}
                placeholder={t('Выберите ответственного исполнителя...')}
                hasError={
                  (errors.executiveImplementer != null &&
                    touched.executiveImplementer != null) ||
                  (errors.executiveImplementer != null &&
                    touched.executiveImplementer != null)
                }
                options={executiveImplementer}
                isMulti={false}
                additionalErrorKey="external_request_executive_implementer"
                selectHandler={getForSelectOnlyActiveEmployeeApiV2}
                // required={values?.status?.value === 4}
                onChange={(
                  executiveImplementer: IClientSelectOptionV2<number>
                ) => {
                  saveValueInStoreForExternalRequest({
                    value: {
                      executiveImplementer,
                    },
                  });
                }}
                highlightEditing={hasChange(
                  initialExecutiveImplementer,
                  executiveImplementer
                )}
                disabled={isDisable}
                haveReadPermission={employeeHaveAccessToRead}
              />
              <DynamicSelectPaginatedFieldV2
                id="implementers"
                label={t('Исполнители')}
                placeholder={t('Выберите исполнителей...')}
                hasError={
                  errors.implementers != null && touched.implementers != null
                }
                selectHandler={getForSelectOnlyActiveEmployeeApiV2}
                options={implementers}
                isMulti={true}
                additionalErrorKey="external_request_implementers_dk_array"
                onChange={(implementers: IClientSelectOptionV2<number>[]) => {
                  saveValueInStoreForExternalRequest({
                    value: {
                      implementers,
                    },
                  });
                }}
                highlightEditing={hasChange(initialImplementers, implementers)}
                disabled={isDisable}
                haveReadPermission={employeeHaveAccessToRead}
              />
            </>
          )}
        </fieldset>
        <fieldset className={styles.customer} disabled={isDisable}>
          <DynamicSelectPaginatedFieldV2
            id="affiliate"
            label={t('Партнер')}
            placeholder={t('Выберите партнера')}
            hasError={errors.affiliate != null && touched.affiliate != null}
            selectHandler={getForSelectCounterpartyAffiliateApiV2}
            options={affiliate}
            disabled={isDisable}
            onChange={(affiliate: IClientSelectOptionV2<number>) => {
              saveValueInStoreForExternalRequest({
                value: { affiliate },
              });
            }}
            highlightEditing={hasChange(initialAffiliate, affiliate)}
          />
          {/*
        <SelectField
          key="external_request_payment_kind"
          id="external_request_payment_kind"
          label={t('Тип оплаты')}
          hasError={
            errors.external_request_payment_kind != null &&
            touched.external_request_payment_kind != null
          }
          onBlur={handleBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            saveValueInStoreForExternalRequest({ event });
            handleChange(event);
          }}
          defaultValue={paymentKind}
          options={paymentTypes}
          showDefaultOption={true}
          highlightEditing={hasChange(initialPaymentKind, paymentKind)}
          disabled={isDisable}
        />

        <TextField
          id="external_request_payment_amount"
          type="number"
          label={t('Сумма')}
          hasError={
            errors.external_request_payment_amount != null &&
            touched.external_request_payment_amount != null
          }
          onBlur={handleBlurPaymentAmount}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleChange(event);
          }}
          defaultValue={paymentAmount ? paymentAmount : ''}
          highlightEditing={hasChange(initialPaymentAmount, paymentAmount)}
          disabled={isDisable}
        />

        <SelectField
          key="external_request_payment_status"
          id="external_request_payment_status"
          label={t('Статус оплаты')}
          hasError={
            errors.external_request_payment_status != null &&
            touched.external_request_payment_status != null
          }
          onBlur={handleBlur}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            saveValueInStoreForExternalRequest({ event });
            handleChange(event);
          }}
          defaultValue={paymentStatus}
          options={paymentStatuses}
          showDefaultOption={true}
          highlightEditing={hasChange(initialPaymentStatus, paymentStatus)}
          disabled={isDisable}
        /> */}
        </fieldset>
      </>
    );
  };

export default memo(ExternalRequestThirdSection);
