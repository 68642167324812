import { t } from '../utils/translation';

const taskFormValuesLocalization = {
  account: t('лицевой счет'),
  affiliate: t('партнер'),
  childExternalRequests: t('дочерние заявки'),
  childInternalTasks: t('дочерние задачи'),
  internal_task_author: t('автор'),
  internal_task_date_duedate: t('запланировано'),
  internal_task_description: t('текст'),
  internal_task_is_pruned: t('архив'),
  internal_task_parent_external_request_fk: t('родительская заявка'),
  internal_task_parent_internal_task_fk: t('родительская задача'),
  internal_task_reporter_phone_number: t('контактный телефон'),
  internal_task_time_adjustment: t('корректировка'),
  supervisor: t('исполнитель'),
  status: t('статус'),
  taskPk: t('номер задачи'),
  taskType: t('тип задачи'),
  selectedCounterparty: t('Выбран контрагент'),
  counterparty: t('контрагент'),
} as const;

export default taskFormValuesLocalization;
