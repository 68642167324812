import { parseISO } from 'date-fns';
import { IFileBucket } from 'services/api/documents/file-bucket/IGetResponseFileBuckets';
import { formatDateTime } from 'services/utils/dateHelper/dateHelper';

export interface IFileBucketForList extends IFileBucket {
  open: boolean;
  timeAdded: string;
}

const toDocumentsForList = (fileBuckets: IFileBucket[]) => {
  return fileBuckets.map((fileBucket: IFileBucket) => ({
    ...fileBucket,
    timeAdded: formatDateTime(parseISO(fileBucket.time_added)),
    open: false,
  }));
};

export default toDocumentsForList;
