import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearTaskFormValues } from 'store/task/actions';
import isInitialValueTaskAndCurrentValueEqual from '../TaskEditPage/utils/isInitialValueTaskAndCurrentValueEqual';

/** Этот хук по сути нужен только для того чтобы
 *  при переходе между задачами мы удаляли из стора не нужные записи 
с предыдущей задаче й если это надо с этим не может справиться хук 
useTrackAndClearFormOnRouteChange. можно назвать это костылем. в будущем нужно что-то менять и жертовать оптимизацией оставив
**/

const useManageTaskFormEntry = () => {
  const dispatch = useDispatch();
  const previousTaskIdRef = useRef<string | undefined>();
  const currentTaskId = useGetIdFromPath();

  const [currentFormValues, setCurrentFormValues] =
    useState<IFormValuesTask | null>(null);
  const [initialFormValues, setInitialFormValues] =
    useState<IFormValuesTask | null>(null);

  const shouldClearFormValues = useCallback((): boolean => {
    return isInitialValueTaskAndCurrentValueEqual(
      initialFormValues,
      currentFormValues
    );
  }, [currentFormValues, initialFormValues]);

  useEffect(() => {
    const previousTaskId = previousTaskIdRef.current;

    if (
      previousTaskId &&
      currentTaskId !== previousTaskId &&
      shouldClearFormValues()
    ) {
      dispatch(clearTaskFormValues(previousTaskId));
    }

    previousTaskIdRef.current = currentTaskId;
  }, [
    currentTaskId,
    currentFormValues,
    initialFormValues,
    dispatch,
    shouldClearFormValues,
  ]);

  return {
    setCurrentFormValues,
    setInitialFormValues,
  };
};

export default useManageTaskFormEntry;
