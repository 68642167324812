import { isEqual } from 'lodash';
import { TValueOf } from 'services/utils/TSUtils/TValueOf';

//ВОЗВРАЩАЕТ ФУНКЦИЮ КОТОРАЯ ПРИНИМАЕТ ДВА ОБЪЕКТА И ВОЗВРАЩАЕТ НОВЫЙ ОБЪЕКТ ТОЛЬКО С ТЕМИ ЗНАЧЕНИЯМИ КОТОРЫЕ ОТЛИЧАЮТСЯ
const useGetDifferentValues = () => {
  return <TypeFormValue extends { [x: string]: any }>(
    initial: TypeFormValue,
    current: TypeFormValue
  ) => 
    Object.entries(current).reduce(
      (acc: TypeFormValue, [key, value]: [string, TValueOf<TypeFormValue>]) => {
        const tKey = key as keyof TypeFormValue;
        if (!isEqual(initial[tKey], value)) {
          return {
            ...acc,
            [key]: value,
          };
        }
        return acc;
      },
      {} as TypeFormValue
    );
};

export default useGetDifferentValues;
