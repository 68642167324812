import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearTaskFormValues } from 'store/task/actions'; // Добавляем setExitForm
import { getTaskFormValuesFromStore } from 'store/task/selectors';
import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import isInitialValueTaskAndCurrentValueEqual from 'pages/internal-task/task/TaskEditPage/utils/isInitialValueTaskAndCurrentValueEqual';

/**  Этот хук нужен для удаления из стора записей по id из задачи
 которую покинули если в ней не было никаких изменений или она была сохранена  **/

const useTrackAndClearFormOnRouteChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const form = useSelector(getTaskFormValuesFromStore);

  const previousTaskIdRef = useRef<string | null>(null);
  const [clearTaskCalled, setClearTaskCalled] = useState(false); // Флаг предотвращения повторной очистки
  const [hasExited, setHasExited] = useState(false); // Флаг выхода из формы
  const currentTaskId = useGetIdFromPath();

  useEffect(() => {
    const previousId = previousTaskIdRef.current;

    const shouldClearForm =
      previousId && (!currentTaskId || previousId !== currentTaskId);

    const formIsUnchanged = (
      initialValues: IFormValuesTask,
      currentValues?: IFormValuesTask
    ) => {
      if (!currentValues) {
        return true;
      }

      return isInitialValueTaskAndCurrentValueEqual(
        currentValues,
        initialValues
      );
    };

    // Принудительный сброс формы при переходе на другую задачу
    if (shouldClearForm && !clearTaskCalled && hasExited) {
      const initialFormValues = form[previousId]?.initial;
      const currentFormValues = form[previousId]?.current;

      if (formIsUnchanged(initialFormValues, currentFormValues)) {
        dispatch(clearTaskFormValues(previousId));
        setClearTaskCalled(true);
      }
    }

    // Сбрасываем флаг выхода и очистки при переходе на новую задачу
    if (currentTaskId) {
      previousTaskIdRef.current = currentTaskId;
      setClearTaskCalled(false);
      setHasExited(false);
    }
  }, [
    location.pathname,
    form,
    dispatch,
    clearTaskCalled,
    hasExited,
    currentTaskId,
  ]);

  // Принудительный вызов setExitForm перед сменой задачи
  useEffect(() => {
    const previousId = previousTaskIdRef.current;

    if (previousId && previousId !== currentTaskId) {
      setHasExited(true); // Фиксируем момент выхода
    }
  }, [location.pathname, dispatch, currentTaskId]);
};

export default useTrackAndClearFormOnRouteChange;
