import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { initialTasksFilters } from './initialTasksFilters';
import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import { IMarkedItem } from 'store/utils/addUniqItems';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { TChangesValuesForm } from 'hooks/useDraftConflictDetector/useGettDifferentValues';
import { NEW_TASK_KEY } from 'components/tasks/TaskForm/constants/NEW_TASK';
import Task from 'models/Task';

export interface ITaskClientAccessPolicy {
  employeeHaveAccessToRead: boolean;
  billingAccountHaveAccessToRead: boolean;
  taskStatusHaveAccessToRead: boolean;
  taskTypeHaveAccessToRead: boolean;
  task: {
    haveAccessToCreate: boolean;
    haveAccessToExport: boolean;
    haveAccessToArchive: boolean;
    haveAccessToUpdate: boolean;
  };
  counterpartyHaveAccessToRead: boolean;
}
interface ITaskClientAccessPolicyStore {
  isInstalled: boolean;
  accessPolicy: ITaskClientAccessPolicy;
}

export interface ITaskFormStore {
  [key: string]: {
    initial: IFormValuesTask;
    current?: IFormValuesTask;
    difValues: TChangesValuesForm[];
  };
}

const initialAccessPolicy: ITaskClientAccessPolicyStore = {
  isInstalled: false,
  accessPolicy: {
    employeeHaveAccessToRead: false,
    billingAccountHaveAccessToRead: false,
    counterpartyHaveAccessToRead: false,
    taskStatusHaveAccessToRead: false,
    taskTypeHaveAccessToRead: false,
    task: {
      haveAccessToCreate: false,
      haveAccessToExport: false,
      haveAccessToArchive: false,
      haveAccessToUpdate: false,
    },
  },
};

export interface IInitialTaskStore {
  isEnteredForm: boolean;
  tableFilters: ITaskFilters;
  marked: IMarkedItem[];
  defaultStatus?: IClientSelectOptionV2<number>;
  taskAccessPolicy: ITaskClientAccessPolicyStore;
  form: ITaskFormStore;
}

export const taskNewDefaultInitialValuesStore = {
  initial: new Task(),
  current: new Task(),
  difValues: [],
};
export const initialFormValuesNewTaskInStore = {
  [NEW_TASK_KEY]: taskNewDefaultInitialValuesStore,
};

export const initialTaskState: IInitialTaskStore = {
  isEnteredForm: false,
  form: initialFormValuesNewTaskInStore,
  tableFilters: initialTasksFilters,
  marked: [],
  defaultStatus: undefined,
  taskAccessPolicy: initialAccessPolicy,
};
