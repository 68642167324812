import classNames from 'clsx';
import { Formik } from 'formik';
import { FC, useState, useMemo } from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { t } from 'services/utils/translation';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import { useDispatch, useSelector } from 'react-redux';
import { usePostV3 } from 'hooks/usePostV3';
import {
  createTaskV2,
  updateTaskV2,
} from '../../../services/api/tasks/tasks/tasksApiV2';
import Task from '../../../models/Task';
import {
  IChildInternalTask,
  ITaskListOfComment,
} from '../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import { IUpdateTask } from '../../../services/api/tasks/tasks/ICreateTask';
import { IPersonV2 } from 'services/interfaces/IPersonV2';
import toFormValuesTask from 'store/utils/toFormValuesTaskV2';
import TaskComments from '../TaskComments/TaskComments';
import { IChildExternalRequest } from '../../../services/api/external-request/external-request/IExternalRequest';
import { getCurrentEmployeePkFromStore } from '../../../store/general/selectors';
import { IWrapperTask } from '../../../services/api/tasks/tasks/ITask';
import useTaskFormUtils from './hooks/useTaskFormUtils';
import TaskFormFieldsWrapper from './ui/TaskFormFieldsWrapper/TaskFormFieldsWrapper';
import { SELECTED_COUNTERPARTY_IN_TASK } from './constants/selectedCounterparty';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import {
  clearTaskFormValues,
  saveTaskCurrentValues,
  setInitialFormTask,
  setInitialNewTask,
} from 'store/task/actions';
import { getTaskFormValuesFromStore } from 'store/task/selectors';
import { ReactComponent as UndoIcon } from './../../../img/icons/icon-refund.svg';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import useGetTaskDefaultValues from './hooks/useGetTaskDefaultValues';
import isEqual from 'react-fast-compare';
import useExtractRefundValue from './hooks/useExtractRefundValue';
import AlertWrapper from 'components/misc/AlertWrapper/AlertWrapper';
import { BootstrapColors } from 'services/constants/BootstrapColors';
import { IS_BILLING_ACCOUNT_CLOSED_KEY } from 'services/constants/IS_BILLING_ACCOUNT_CLOSED_KEY';

export interface IFormValuesTask {
  taskPk?: number;
  internal_task_reporter_phone_number?: string;
  internal_task_date_duedate?: string;
  status?: IClientSelectOptionV2<number>;
  internal_task_description?: string;
  taskType?: IClientSelectOptionV2<number>;
  account?: IClientSelectOptionV2<number>;
  supervisor?: IClientSelectOptionV2<number>;
  internal_task_time_adjustment?: number;
  internal_task_author?: IPersonV2;
  internal_task_list_of_comments?: ITaskListOfComment[];
  internal_task_new_comment?: string;
  internal_task_is_pruned?: boolean;
  internal_task_comment_text?: string;
  internal_task_parent_internal_task_fk?: number;
  internal_task_parent_external_request_fk?: number;
  childInternalTasks?: IChildInternalTask[];
  childExternalRequests?: IChildExternalRequest[];
  counterparty?: IClientSelectOptionV2<number>;
  selectedCounterparty?: SELECTED_COUNTERPARTY_IN_TASK;
  isSelectedAffiliate?: boolean;
  [IS_BILLING_ACCOUNT_CLOSED_KEY]?: boolean;
}

type PropsType = {
  initialValue?: IFormValuesTask | null;
  className?: string;
  successHandler: (isSaveAndExit: boolean) => void;
  taskPk?: number;
  viewOnly: boolean;
  refreshHistory?: () => void;
  isLoadingTask?: boolean;
  defaultStatus?: IClientSelectOptionV2<number>;
  isDuplicate?: boolean;
  initialValuesFromOrder?: IFormValuesTask;
  counterpartyPk?: number;
  billingAccountId?: number;
  initialValuesFromParentTask?: IFormValuesTask | null;
  actualTask?: IFormValuesTask | null;
  setCurrentFormValues?: React.Dispatch<
    React.SetStateAction<IFormValuesTask | null>
  >;
  refreshTask?: () => void;
};

const TaskForm: FC<PropsType> = (props) => {
  const {
    successHandler,
    taskPk,
    className,
    viewOnly,
    refreshHistory,
    isLoadingTask,
    defaultStatus,
    isDuplicate = false,
    initialValuesFromOrder,
    counterpartyPk,
    billingAccountId,
    initialValuesFromParentTask,
    actualTask,
    setCurrentFormValues,
    refreshTask,
  } = props;

  const [isSaveAndExit, setIsSaveAndExit] = useState<boolean>(false);

  const [isLoadingUpdateForm, setIsLoadingUpdateForm] =
    useState<boolean>(false);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const isNew = !taskPk;

  const isDefaultAccountOrCounterparty = !!counterpartyPk || !!billingAccountId;

  const newTask = useMemo(
    () => new Task(taskPk, defaultStatus, billingAccountId, counterpartyPk),
    [billingAccountId, counterpartyPk, defaultStatus, taskPk]
  );

  const formTaskValues = useSelector(getTaskFormValuesFromStore);

  const initialValuesFromStore = taskPk
    ? formTaskValues?.[taskPk]?.initial
    : null;

  const authorId = useSelector(getCurrentEmployeePkFromStore);

  const {
    extractHandlePayloadCreate,
    extractedDisabledFieldTask,
    convertParams,
  } = useTaskFormUtils();

  const isInitialValuesFromOrder = !!initialValuesFromOrder;

  const disableFieldTask = extractedDisabledFieldTask(
    viewOnly,
    isLoadingUpdateForm
  );

  const hasDefaultBillingAccount = !!billingAccountId;
  const dispatchRedux = useDispatch();

  const handlePayloadCreate = extractHandlePayloadCreate(isSaveAndExit);

  const createTask = usePostV3({
    fetchApi: createTaskV2,
    successMessage: t('Задача создана успешно.'),
    successHandler: () => successHandler(isSaveAndExit),
    convertParams,
    handlePayload: handlePayloadCreate,
    viewClientTextToast: true,
  });

  const handlePayload = (payload: IWrapperTask) => {
    const stringPk = taskPk + '';
    if (!isSaveAndExit && taskPk) {
      const taskFormValues = toFormValuesTask(payload.internal_task);
      dispatchRedux(setInitialFormTask(stringPk)(taskFormValues));
      dispatchRedux(saveTaskCurrentValues(stringPk)(taskFormValues));
      refreshHistory && refreshHistory();
    } else {
      dispatchRedux(clearTaskFormValues(stringPk));
    }
  };

  const updateTask = usePostV3<IWrapperTask, IUpdateTask>({
    fetchApi: updateTaskV2,
    successMessage: t('Задача изменена успешно.'),
    successHandler: () => successHandler(isSaveAndExit),
    convertParams,
    handlePayload,
    viewClientTextToast: true,
  });

  const handleUpdateTask = async (
    values: IFormValuesTask,
    setErrors: Function,
    resetForm: Function
  ) => {
    setIsLoadingUpdateForm(true);
    await updateTask(values as IUpdateTask, setErrors, resetForm);
    setIsLoadingUpdateForm(false);
  };

  const submitForm = async (
    values: IFormValuesTask,
    setErrors: Function,
    resetForm: Function
  ) => {
    setIsSubmit(true);
    if (isNew) {
      await createTask(values, setErrors, resetForm);
      dispatchRedux(setInitialNewTask());
    }
    values?.taskPk && (await handleUpdateTask(values, setErrors, resetForm));
  };

  const extractRefundValue = useExtractRefundValue(newTask, taskPk, actualTask);

  const defaultValue = useGetTaskDefaultValues({
    taskPk,
    newTask,
    initialValuesFromOrder,
    initialValuesFromParentTask,
    isDefaultAccountOrCounterparty,
  }) as IFormValuesTask | Task;

  const viewForm = isNew || (!isLoadingTask && defaultValue?.taskPk);

  return (
    <Row className="mr-0">
      <Formik
        enableReinitialize={true}
        initialValues={defaultValue}
        validationSchema={Task.validationSchema(defaultValue?.taskPk == null)}
        onSubmit={async (values, { setErrors, resetForm, setSubmitting }) => {
          setSubmitting(true);

          await submitForm(values, setErrors, resetForm);
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, isSubmitting, resetForm, setValues }) => {
          const handleRefundOnClick = extractRefundValue(resetForm, setValues);

          return (
            <Col>
              {viewForm && (
                <Form
                  onSubmit={handleSubmit}
                  noValidate={true}
                  className={classNames({
                    className,
                    'd-flex flex-column flex-md-row justify-content-md-between ml-4  mr-md-auto pl-3':
                      taskPk != null || isDuplicate || isInitialValuesFromOrder,
                  })}
                  style={{ maxWidth: values?.taskPk != null ? '100%' : '' }}
                >
                  <div
                    className={classNames('my-2 my-md-3 mr-4 w-100')}
                    style={{
                      maxWidth:
                        values?.taskPk != null || isInitialValuesFromOrder
                          ? '800px'
                          : '',
                    }}
                  >
                    <TaskFormFieldsWrapper
                      values={values}
                      formState={{
                        isDisableField: disableFieldTask,
                        isNew,
                        hasDefaultBillingAccount,
                        isDuplicate,
                        isDefaultAccountOrCounterparty,
                        permanentInitialValues: initialValuesFromStore,
                        isSubmit,
                      }}
                      setCurrentFormValues={setCurrentFormValues}
                    />
                    <FormGroup
                      className={classNames('mt-4 mt-4', {
                        ' d-md-block': values?.taskPk != null,
                      })}
                    >
                      <Row className="d-flex-row">
                        <Col className="display-flex">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting || viewOnly}
                          >
                            {t('Сохранить')}
                          </Button>
                          <Button
                            className="ml-3"
                            type="submit"
                            color="primary"
                            disabled={isSubmitting || viewOnly}
                            onClick={() => {
                              // setIsBlockedNavigation(false);
                              setIsSaveAndExit(true);
                            }}
                          >
                            {t('Сохранить и выйти')}
                          </Button>
                        </Col>
                        <Button
                          disabled={isEqual(initialValuesFromStore, values)}
                          className="mr-3"
                          id="refundButton"
                          color="secondary"
                          onClick={handleRefundOnClick}
                        >
                          <ToolTipWrapper target={'refundButton'}>
                            {t('Сбросить изменения')}
                          </ToolTipWrapper>
                          <UndoIcon />
                        </Button>
                      </Row>
                    </FormGroup>
                  </div>
                </Form>
              )}
              {!isLoadingTask &&
                !defaultValue?.taskPk &&
                !isNew &&
                refreshTask && (
                  <AlertWrapper
                    color={BootstrapColors.danger}
                    onAgree={{
                      onAgreeCall: refreshTask,
                      onAgreeText: t('Перезагрузить форму'),
                    }}
                    text={t('Ошибка загрузки формы')}
                    helpText={t(
                      'Что-то пошло не так. Пожалуйста, попробуйте перезагрузить форму'
                    )}
                  />
                )}

              {isLoadingTask && <LoaderFetch />}
            </Col>
          );
        }}
      </Formik>

      <div
        className={classNames('ml-md-4', {
          'd-none': taskPk == null,
        })}
      >
        {!!taskPk && (
          <TaskComments pk={taskPk} authorDk={authorId} viewOnly={viewOnly} />
        )}
      </div>
    </Row>
  );
};

TaskForm.whyDidYouRender = false;

export default TaskForm;
