import { useCallback } from 'react';
import { usePostV2 } from 'hooks/usePostV2';
import { doArchivedDocumentApi } from 'services/api/documents/file-bucket/fileBucketApi';
import { IDocumentDoArchivedveParams } from 'services/api/documents/file-bucket/IDocumentDoArchivedveParams';
import { IDocumentFormValues } from 'components/documents/FileBucketForm/IDocumentFormValues';
import { TValueOf } from 'services/utils/TSUtils/TValueOf';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';

const toFormData = (params: IDocumentDoArchivedveParams) => {
  return Object.entries(params).reduce(
    (
      formData,
      [key, value]: [keyof IDocumentFormValues, TValueOf<IDocumentFormValues>]
    ) => {
      if (value) {
        formData.append(key as string, value);
      }
      return formData;
    },
    new FormData()
  );
};

const useDocumentToArchive = (refreshData: () => void) => {
  const doArchivedDocument = usePostV2({
    fetchApi: doArchivedDocumentApi,
    // viewClientTextToast: true,
    // successMessage: SUCCESS_MESSAGE_DOCUMENT,
  });

  return useCallback(
    async (params: IDocumentDoArchivedveParams) => {
      const newParams = deleteUnnecessaryParameters(params, ['pk']);
      const formData = toFormData(newParams);
      await doArchivedDocument({ params: formData, pk: params.pk });
      refreshData();
    },
    [doArchivedDocument, refreshData]
  );
};

export default useDocumentToArchive;
