import { FC, memo } from 'react';
import TDSubtitle from '../TDSubtitle/TDSubtitle';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import { BILLING_ACCOUNT_TEXT } from 'services/localLocalization/servicesAndModels';
import { PATH_ORGANIZATIONS_ACCOUNTS_VIEW } from 'services/pathConstants';
import { composeTitleBillingAccount } from 'services/utils/bilingAccountUtils/composeTitleBillingAccount';
import { makeLink } from 'services/localPaths';
import { Link } from 'react-router-dom';
import {   IBillingAccountShort } from 'services/api/organizationsV2/billing-account/IBillingAccount';
 
type TDBillingAccountPropsType = {
  billingAccount: IBillingAccountShort;
};

const TDBillingAccount: FC<TDBillingAccountPropsType> = ({
  billingAccount,
}) => {
  return (
    <>
      <TDSubtitle>{makeFirstLetterUppercase(BILLING_ACCOUNT_TEXT)}:</TDSubtitle>
      <br />
      <Link
        to={makeLink(
          PATH_ORGANIZATIONS_ACCOUNTS_VIEW,
          billingAccount.billing_account_pk
        )}
      >
        {composeTitleBillingAccount(billingAccount)}
      </Link>
    </>
  );
};

export default memo(TDBillingAccount);
