import { useCallback } from 'react';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import ExternalRequestConvertParams from '../utils/ExternalRequestConvertParams/ExternalRequestParamsTransformer';

const useComposeExternalRequestFilters = () => {
  return useCallback((params: IExternalRequestFilters) => {
    const convertParams = new ExternalRequestConvertParams(params);
    return convertParams.transform();
  }, []);
};

export default useComposeExternalRequestFilters;
