import {
  CLEAR_TASK_DIF_VALUES,
  CLEAR_TASK_INITIAL_VALUES,
  CLEAR_TASK_VALUES,
  SET_ACCESS_POLICY_TASK,
  SET_DEFAULT_STATUS,
  SET_INITIAL_NEW_TASK,
  SET_INITIAL_TASK_FIELD_VALUES,
  SET_IS_ACTIVE_COUNTERPARTY_FILTER,
  SET_MARKED_TASK,
  SET_TASK_CURRENT_VALUES,
  SET_TASK_DIF_VALUES,
  TASKS_CLEAR_FILTERS,
  TASKS_SAVE_FILTERS,
} from './actionTypes';
import { changeOfMarking } from '../utils/changeMark';
import { NEW_TASK_KEY } from 'components/tasks/TaskForm/constants/NEW_TASK';
import { taskNewDefaultInitialValuesStore } from './initialTask';

/**
 * Ticket reducer.
 *
 * Don't forget to initialize default values in ../CompaniesPage.tsx.
 *
 * @param state
 * @param action
 */
const tasksReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case TASKS_SAVE_FILTERS:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...action.filters,
        },
      };
    case TASKS_CLEAR_FILTERS:
      return {
        ...state,
        tableFilters: action.payload,
      };

    case SET_MARKED_TASK:
      const newTicketsMarked = changeOfMarking(state.marked, action.payload);
      return {
        ...state,
        marked: newTicketsMarked,
      };

    case SET_DEFAULT_STATUS: {
      return {
        ...state,
        defaultStatus: action.payload,
      };
    }
    case SET_IS_ACTIVE_COUNTERPARTY_FILTER: {
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          isActiveCounterpartyFilter: action.payload,
        },
      };
    }

    case SET_ACCESS_POLICY_TASK: {
      return {
        ...state,
        taskAccessPolicy: {
          isInstalled: true,
          accessPolicy: { ...action.payload },
        },
      };
    }

    case SET_TASK_CURRENT_VALUES: {
      const { pk, values } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          [pk]: { ...state.form?.[pk], current: values },
        },
      };
    }

    case CLEAR_TASK_INITIAL_VALUES: {
      const formCopy = {
        ...state.form,
      };
      delete formCopy[action.payload].initial;
      return {
        ...state,
        form: formCopy,
      };
    }

    case CLEAR_TASK_VALUES: {
      const formCopy = {
        ...state.form,
      };
      delete formCopy[action.payload];
      return {
        ...state,
        form: formCopy,
      };
    }

    case SET_INITIAL_TASK_FIELD_VALUES: {
      const { pk, values } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          [pk]: { ...state.form?.[pk], initial: values },
        },
      };
    }

    case SET_TASK_DIF_VALUES: {
      const { pk, difValues } = action.payload;

      return {
        ...state,
        form: {
          ...state.form,
          [pk]: { ...state.form?.[pk], difValues },
        },
      };
    }

    case CLEAR_TASK_DIF_VALUES: {
      const formCopy = {
        ...state.form,
      };
      delete formCopy[action.payload].difValues;
      return {
        ...state,
        form: formCopy,
      };
    }

    case SET_INITIAL_NEW_TASK: {
      return {
        ...state,
        form: {
          ...state.form,
          [NEW_TASK_KEY]: taskNewDefaultInitialValuesStore,
        },
      };
    }

    default:
      return state;
  }
};
export default tasksReducer;
