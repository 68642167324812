import { IControllerFilters } from 'services/api/controller/IControllerFilters';
import {
  IControllerClientAccessPolicyStore,
  initialControllerAccessPolicy,
} from './initialControllerAccessPolicy';
import initialControllerFilters from './initialControllerFilters';
import { IFormValuesController } from '../../../components/controller/controller/ControllerForm/ControllerForm';

export interface IControllerStoreState {
  tableFilters: IControllerFilters;
  accessPolicy: IControllerClientAccessPolicyStore;
  form: {
    fieldValues: { [key: string]: IFormValuesController } | null;
    initialValues: { [key: string]: IFormValuesController } | null;
  };
}

const initialControllerState: IControllerStoreState = {
  tableFilters: initialControllerFilters,
  accessPolicy: initialControllerAccessPolicy,
  form: {
    fieldValues: {},
    initialValues: {},
  },
} as const;

export default initialControllerState;
