import { IHandlerExternalRequestFormProps } from '../types/IHandlerExternalRequestFormProps';
import { t } from '../../../../services/utils/translation';
import styles from './index.module.scss';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import { ChangeEvent, FC, memo } from 'react';
import BillingAccountDetailsSelect from 'components/controls/BillingAccountDetailsSelect/BillingAccountDetailsSelect';
import { getForSelectBillingAccountApiV2 } from '../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import DaDataBaseField, {
  TypeSuggestion,
} from 'components/controls/DaDataBaseField/DaDataBaseField';
import { DaDataAddressSuggestion } from 'react-dadata';
import { getForSelectOnlyActiveEmployeeApiV2 } from '../../../../services/api/staff/employee/employeeApi';
import { ISecondSectionExternalRequestState } from '../types/IStateExternalRequestForm';
import { getForSelectResponsibleDepartmentApiV2 } from 'services/api/external-request/responsible-department/responsibleDepartmentApi';
import { useFormikContext } from 'formik';
import { IFormValuesExternalRequestFromTask } from '../../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import ExternalRequest from '../../../../models/ExternalRequest';
import useHandleBlurWrappersForExternalRequest from '../formUtils/useHandleBlurWrappersForExternalRequest';
import EventDetailsSelect from 'components/external-request/ExternalRequestForm/EventDetailsSelect/EventDetailsSelect';
import { getForSelectEventApi } from 'services/api/organizationsV2/event/eventApi';
import RadiosField from 'components/controls/RadiosField';
import { EVENT_STATE } from './constants';
import { IValueForSaveInStore } from 'hooks/useSaveValueStore';
import useSetAndGetExternalRequestAccessPolicy from 'pages/external-request/external-requests/ExternalRequestPage/hooks/useSetExternalRequestAccessPolicy';

const handleEventStateChange = (
  eventState: EVENT_STATE,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  saveValue: ({ event, value }: IValueForSaveInStore) => void
) => {
  if (eventState === EVENT_STATE.enabled_event) {
    setFieldValue('billingAccount', undefined, false);
    saveValue({
      value: {
        billingAccount: undefined,
        eventState,
      },
    });
    return;
  } else if (eventState === EVENT_STATE.unable_event) {
    setFieldValue('event', undefined, false);

    saveValue({
      value: {
        event: undefined,
        eventState,
      },
    });
    return;
  } else {
    console.error(t('Некорректный тип выбора. Обратитесь к администратору.'));
  }
};

interface PropsTypeExternalRequestSecondSection {
  handlerFormProps: IHandlerExternalRequestFormProps;
  state: ISecondSectionExternalRequestState;
}

const ExternalRequestSecondSection: FC<
  PropsTypeExternalRequestSecondSection
> = (props) => {
  const {
    handlerFormProps: { saveValueInStoreForExternalRequest, hasChange },
    state: {
      isDisable,
      initialValues: {
        initialBillingAccount,
        initialAddressOfIncident,
        initialSupervisor,
        initialResponsibleDepartment,
        initialEvent,
      },
    },
  } = props;

  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext<IFormValuesExternalRequestFromTask | ExternalRequest>();

  const {
    employeeHaveAccessToRead,
    billingAccountHaveAccessToRead,
    eventHaveAccessToRead,
    responsibleDepartmentHaveAccessToRead,
  } = useSetAndGetExternalRequestAccessPolicy();

  const {
    billingAccount,
    external_request_address_of_incident: addressOfIncident,
    supervisor,
    responsibleDepartment,
    event,
    eventState,
  } = values;

  const { handleBlurAddress } = useHandleBlurWrappersForExternalRequest();

  const crutchAddressField = addressOfIncident || addressOfIncident === '';
  return (
    <fieldset className={styles.performer} disabled={isDisable}>
      <RadiosField
        name="hasEvent"
        hasErrors={
          errors.performerType != null && touched.performerType != null
        }
        options={[
          {
            label: t('Лицевой счет'),
            value: EVENT_STATE.unable_event,
          },
          {
            label: t('Событие'),
            value: EVENT_STATE.enabled_event,
          },
        ]}
        checkedValue={eventState}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleEventStateChange(
            event.target.value as EVENT_STATE,
            setFieldValue,
            saveValueInStoreForExternalRequest
          );
        }}
        inline={true}
        required={true}
        disabled={isDisable}
      />
      {eventState === EVENT_STATE.unable_event && (
        <BillingAccountDetailsSelect billingAccountPk={billingAccount?.value}>
          <DynamicSelectPaginatedFieldV2
            id="billingAccount"
            label={t('Лицевой счет')}
            placeholder={t('Выберите лицевой счет...')}
            hasError={
              (errors.billingAccount != null &&
                touched.billingAccount != null) ||
              (errors.billingAccount != null && touched.billingAccount != null)
            }
            selectHandler={getForSelectBillingAccountApiV2}
            options={billingAccount}
            additionalErrorKey="external_request_billing_account_fk"
            onChange={(billingAccount: IClientSelectOptionV2<number>) => {
              saveValueInStoreForExternalRequest({
                value: {
                  billingAccount,
                },
              });
            }}
            highlightEditing={hasChange(
              initialBillingAccount?.value,
              billingAccount?.value
            )}
            disabled={isDisable}
            required={true}
            haveReadPermission={billingAccountHaveAccessToRead}
          />
        </BillingAccountDetailsSelect>
      )}
      {eventState === EVENT_STATE.enabled_event && (
        <EventDetailsSelect eventPk={event?.value}>
          <DynamicSelectPaginatedFieldV2
            id="event"
            label={t('Событие')}
            placeholder={t('Выберите событие...')}
            hasError={
              (errors.event != null && touched.event != null) ||
              (errors.event != null && touched.event != null)
            }
            selectHandler={getForSelectEventApi}
            options={event}
            additionalErrorKey="external_request_event_fk"
            onChange={(event: IClientSelectOptionV2<number>) => {
              saveValueInStoreForExternalRequest({
                value: {
                  event,
                },
              });
            }}
            highlightEditing={hasChange(initialEvent?.value, event?.value)}
            disabled={isDisable}
            required={true}
            haveReadPermission={eventHaveAccessToRead}
          />
        </EventDetailsSelect>
      )}
      {crutchAddressField && (
        <DaDataBaseField
          key={1}
          id="external_request_address_of_incident"
          label={t('Уточнить адрес нарушения')}
          highlightEditing={hasChange(
            initialAddressOfIncident,
            addressOfIncident
          )}
          inputProps={{
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              handleChange(event);
            },
            onBlur: handleBlurAddress,

            disabled: isDisable,
          }}
          onChangeSelect={async (suggestion?: DaDataAddressSuggestion) => {
            saveValueInStoreForExternalRequest({
              value: {
                external_request_address_of_incident: suggestion?.value,
              },
            });
           await setFieldValue(
              'external_request_address_of_incident',
              suggestion?.value
            );
          }}
          typeSuggestion={TypeSuggestion.address}
          defaultValue={addressOfIncident}
          hasError={
            errors.external_request_address_of_incident != null &&
            touched.external_request_address_of_incident != null
          }
          disabled={isDisable}
        />
      )}
      <DynamicSelectPaginatedFieldV2
        id="responsibleDepartment"
        label={t('Ответственная служба')}
        placeholder={t('Выберите ответственную службу')}
        hasError={
          errors.responsibleDepartment != null &&
          touched.responsibleDepartment != null
        }
        selectHandler={getForSelectResponsibleDepartmentApiV2}
        options={responsibleDepartment}
        additionalErrorKey="responsibleDepartment"
        disabled={isDisable}
        onChange={(responsibleDepartment: IClientSelectOptionV2<number>) => {
          saveValueInStoreForExternalRequest({
            value: { responsibleDepartment },
          });
        }}
        highlightEditing={hasChange(
          initialResponsibleDepartment,
          responsibleDepartment
        )}
        required={true}
        haveReadPermission={responsibleDepartmentHaveAccessToRead}
      />
      <DynamicSelectPaginatedFieldV2
        id="supervisor"
        label={t('Ответственный руководитель')}
        placeholder={t('Выберите ответственного руководителя...')}
        hasError={errors.supervisor != null && touched.supervisor != null}
        selectHandler={getForSelectOnlyActiveEmployeeApiV2}
        options={supervisor}
        additionalErrorKey="supervisor"
        disabled={isDisable}
        // required={values?.status?.value === 3}
        onChange={(supervisorTarget: IClientSelectOptionV2<number>) => {
          saveValueInStoreForExternalRequest({
            value: {
              supervisor: supervisorTarget,
            },
          });
        }}
        highlightEditing={hasChange(initialSupervisor, supervisor)}
        required={true}
        haveReadPermission={employeeHaveAccessToRead}
      />
    </fieldset>
  );
};

export default memo(ExternalRequestSecondSection);
