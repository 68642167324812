import { useFormikContext } from 'formik';
import { useCallback } from 'react';

const useHandlerDateTaskForm = () => {
  const { setFieldValue } = useFormikContext();

  return useCallback(
    async (date: string) => {
      await setFieldValue('internal_task_date_duedate', date);
    },
    [setFieldValue]
  );
};

export default useHandlerDateTaskForm;
