import { IDocumentFormValues } from '../IDocumentFormValues';
import IFileFormValues from '../IFileFormValues';
import { useFormikContext } from 'formik';
import DOCUMENT_ADDING_FILES_KEY from '../const/DOCUMENT_ADDING_FILES_KEY';
import useSetUploadedFilesFile from './useSetUploadedFilesFile';

const useDocumentRemoveFile = () => {
  const {
    setFieldValue,
    values: {
      string_of_comma_separted_file_names_to_delete: stringNamesToDelete,
    },
    values,
  } = useFormikContext<IDocumentFormValues>();
  const setUploadedFilesFile = useSetUploadedFilesFile(false);

  const deleteUploadedFilesFiles = async(file: IFileFormValues) => {
   await setUploadedFilesFile(file);
    const defaultStringNamesToDelete = stringNamesToDelete || '';
    const fileNamesToDelete = `${defaultStringNamesToDelete},${file.file.name}`;
   await setFieldValue(
      'string_of_comma_separted_file_names_to_delete',
      fileNamesToDelete
    );
  };

  const removeAddedFiles = async (targetFile: File) => {
    const updatedFileLIst = values[DOCUMENT_ADDING_FILES_KEY].filter(
      (file: File) => file.name !== targetFile.name
    );
  await setFieldValue(DOCUMENT_ADDING_FILES_KEY as string, updatedFileLIst);
  };

  return { deleteUploadedFilesFiles, removeAddedFiles };
};

export default useDocumentRemoveFile;
