import { useMemo } from 'react';
import config from '../../../../config.json';
import { IDocumentsPaginationParams } from 'services/api/documents/file-bucket/IFiltersDocuments';

type PropsTypeUseGetInitialDocumentPaginationParams = {
  isDocumentRegistrarPage?: boolean;
  parentUIName?: string;
  parentUIPk?: string | number;
};

const useGetInitialDocumentPaginationParams = ({
  isDocumentRegistrarPage,
  parentUIName,
  parentUIPk,
}: PropsTypeUseGetInitialDocumentPaginationParams): IDocumentsPaginationParams => {
  return useMemo(
    () =>
      !isDocumentRegistrarPage && parentUIPk && parentUIName
        ? {
            parent_ui_element: { [parentUIName]: parentUIPk },
            skip: 0,
            length: config.pageLength,
          }
        : {
            sent_to_archive: true,
            skip: 0,
            received_to_archive: false,
            length: config.pageLength,
          },
    [isDocumentRegistrarPage, parentUIName, parentUIPk]
  );
};

export default useGetInitialDocumentPaginationParams;
