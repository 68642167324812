export const getParenTaskFk = (props: {
  parentTaskFk?: number;
  isDuplicate?: boolean;
  isChildrenExternalRequest?: boolean;
}) => {
  const { parentTaskFk, isDuplicate, isChildrenExternalRequest } = props;
  if (isDuplicate || isChildrenExternalRequest) {
    return undefined;
  }
  return parentTaskFk;
};
