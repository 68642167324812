import { ChangeEvent, useCallback } from 'react';
import { SELECTED_COUNTERPARTY_IN_TASK } from '../constants/selectedCounterparty';
import { t } from 'services/utils/translation';
import { useFormikContext } from 'formik';

const useHandleSelectedCounterpartyStateChange = () => {
  const { setFieldValue } = useFormikContext();

  return useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const selectedCounterparty = event.target
        .value as SELECTED_COUNTERPARTY_IN_TASK;
      await setFieldValue('selectedCounterparty', selectedCounterparty, false);
      const isSelectedBillingAccount =
        selectedCounterparty ===
        SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount;
      if (isSelectedBillingAccount) {
        await setFieldValue('counterparty', undefined, false);
        // saveValueInStoreForTask({
        //   value: {
        //     counterparty: undefined,
        //     selectedCounterparty,
        //   },
        // });
        return;
      } else if (!isSelectedBillingAccount) {
        await setFieldValue('account', undefined, false);
        // saveValueInStoreForTask({
        //   value: {
        //     account: undefined,
        //     selectedCounterparty,
        //   },
        // });
        return;
      } else {
        console.error(
          t('Некорректный тип выбора. Обратитесь к администратору.')
        );
      }
    },
    [setFieldValue]
  );
};

export default useHandleSelectedCounterpartyStateChange;
