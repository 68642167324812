import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ITaskFilters } from 'services/api/tasks/tasks/IGetPaginatedTasksParams';
import { setIsActiveCounterpartyFilter } from 'store/task/actions';
import getHintForSelectArrayOptions from 'services/utils/getHintForSelectArrayOptions/getHintForSelectArrayOptions';
import { COLUMN_LABELS_TASK } from '../../columnTasks';

type PropsType = {
  params: ITaskFilters;
  counterpartyPk?: number;
  billingAccountPk?: number;
};

const useDoubleFilterUtilitiesForTask = (props: PropsType) => {
  const {
    params: {
      internal_task_billing_account_fk_array: filterBillingAccountArray,
      internal_task_counterparty_fk_array: filterCounterpartyArray,
    },
    counterpartyPk,
    billingAccountPk,
  } = props;

  const dispatchRedux = useDispatch();

  const callSetIsActiveCounterpartyFilter = useCallback(
    (isActiveCounterpartyFilter: boolean) => {
      dispatchRedux(setIsActiveCounterpartyFilter(isActiveCounterpartyFilter));
    },
    [dispatchRedux]
  );

  const hintForBillingAccountFilter = getHintForSelectArrayOptions(
    COLUMN_LABELS_TASK.BILLING_ACCOUNT
  )(filterBillingAccountArray);

  const hintForCounterpartyFilter = getHintForSelectArrayOptions(
    COLUMN_LABELS_TASK.COUNTERPARTY
  )(filterCounterpartyArray);

  const hintForDoubleFilters = {
    hintForBillingAccountFilter,
    hintForCounterpartyFilter,
  };

  const isDisabled = !!billingAccountPk || !!counterpartyPk;

  return {
    hintForDoubleFilters,
    callSetIsActiveCounterpartyFilter,
    isDisabledDoubleFilterCounterpartyAndBillingAccount: isDisabled,
  };
};

export default useDoubleFilterUtilitiesForTask;
