import { useFormikContext } from 'formik';
import Task from 'models/Task';
import { IFormValuesTask } from '../../TaskForm';
import { FC } from 'react';
import TextField from 'components/controls/TextField';
import { t } from 'services/utils/translation';
import BillingAccountDetailsSelect from 'components/controls/BillingAccountDetailsSelect/BillingAccountDetailsSelect';
import DynamicSelectPaginatedFieldV2 from 'components/controls/DynamicSelectPaginatedFieldV2/DynamicSelectPaginatedFieldV2';
import { getForSelectBillingAccountApiV2 } from '../../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import useTaskFormUtils from '../../hooks/useTaskFormUtils';
import classNames from 'clsx';
import styles from './index.module.scss';
import PhoneField from 'components/controls/PhoneField/PhoneField';
import DatePickerField from 'components/controls/DatePickerField';
import config from '../../../../../config.json';
import SelectGroupField from 'components/controls/SelectGroupField/SelectGroupField';
import { useGetTaskTypesOptions } from 'hooks/useGetTaskTypesOptions';
import useHandlerTaskFormUtils from '../../hooks/useHandlerTaskStatus';
import { getTaskStatusesForSelectApiV2 } from 'services/api/tasks/task-statusesV2/taskSatusesV2';
import { getForSelectOnlyActiveEmployeeApiV2 } from '../../../../../services/api/staff/employee/employeeApi';
import useHandlerDateTaskForm from '../../hooks/useHandlerDateTaskForm';
import RadiosField from 'components/controls/RadiosField';
import { SELECTED_COUNTERPARTY_IN_TASK } from '../../constants/selectedCounterparty';
import CounterpartyDetailsSelect from '../CounterpartyDetailsSelect/CounterpartyDetailsSelect';
import { InputGroupWithCheckbox } from 'components/controls/InputGroupWithCheckbox/InputGroupWithCheckbox';
import useGetRequestForSelectCounterparty from '../../hooks/useGetRequestForSelectCounterparty';
import useHandleSelectedCounterpartyStateChange from '../../hooks/useHandleSelectedCounterpartyStateChange';
import ACCOUNT_RADIO_OPTIONS from '../../constants/ACCOUNT_RADIO_OPTIONS';
import useSetAndGetTaskAccessPolicy from 'pages/internal-task/task/TaskPage/hooks/useSetAndGetTaskAccessPolicy/useSetAndGetTaskAccessPolicy';
import useSaveTaskFieldValues from '../../hooks/useSaveTaskFieldValue';

const HINT_FOR_CHECKBOX_SELECTED = t('Партнеры');

type PropsTypeTaskFormWrapper = {
  // setNewTask: TypeSetValue<Task | IFormValuesTask>;
  formState: {
    isSubmit: boolean;
    isDisableField: boolean;
    isNew: boolean;
    hasDefaultBillingAccount: boolean;
    isDuplicate: boolean;
    isDefaultAccountOrCounterparty: boolean;
    permanentInitialValues?: IFormValuesTask | null;
  };
  setCurrentFormValues?: React.Dispatch<
    React.SetStateAction<IFormValuesTask | null>
  >;
  values: IFormValuesTask | Task;
};

const TaskFormFieldsWrapper: FC<PropsTypeTaskFormWrapper> = (props) => {
  const {
    //  setNewTask,
    formState: {
      isSubmit,
      isDisableField,
      isNew,
      hasDefaultBillingAccount,
      isDuplicate,
      isDefaultAccountOrCounterparty,
      permanentInitialValues,
    },
    setCurrentFormValues,
  } = props;

  const isDisableAccountOrCounterpartyPage =
    isDefaultAccountOrCounterparty && isNew;

  const {
    values: {
      taskPk: pk,
      internal_task_description: description,
      account,
      internal_task_reporter_phone_number: phoneNumber,
      internal_task_date_duedate: dueDate,
      taskType,
      status,
      supervisor: responsibleUser,
      internal_task_time_adjustment: timeAdjustment,
      internal_task_new_comment: newComment,
      counterparty,
      selectedCounterparty,
      isSelectedAffiliate,
    },
    handleBlur,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormikContext<IFormValuesTask | Task>();

  useSaveTaskFieldValues(isSubmit, setCurrentFormValues);

  const { extractIsEditing, checkEditPhoneNumber } = useTaskFormUtils();

  const isEditing = extractIsEditing(isNew);

  const { handleChangeStatus } = useHandlerTaskFormUtils();
  const handlerDate = useHandlerDateTaskForm();
  const {
    employeeHaveAccessToRead,
    billingAccountHaveAccessToRead,
    counterpartyHaveAccessToRead,
    taskTypeHaveAccessToRead,
  } = useSetAndGetTaskAccessPolicy();

  const { taskTypeOptions } = useGetTaskTypesOptions(taskTypeHaveAccessToRead);

  const { getForselectCounterparty, keyAsyncSelect, handlerAffiliateCheckbox } =
    useGetRequestForSelectCounterparty();

  const handleSelectedCounterpartyStateChange =
    useHandleSelectedCounterpartyStateChange();

  const isVisibleNewComment = isNew || isDuplicate;
  return (
    <>
      <fieldset
        className={classNames(styles.fieldsContainer)}
        disabled={isDisableField}
      >
        <RadiosField
          name="hasEvent"
          hasErrors={
            errors.selectedCounterparty != null &&
            touched.selectedCounterparty != null
          }
          options={ACCOUNT_RADIO_OPTIONS}
          checkedValue={selectedCounterparty}
          onChange={handleSelectedCounterpartyStateChange}
          inline={true}
          required={false}
          disabled={isDisableField || isDisableAccountOrCounterpartyPage}
        />
        {selectedCounterparty ===
          SELECTED_COUNTERPARTY_IN_TASK.selectedBillingAccount && (
          <BillingAccountDetailsSelect billingAccountPk={account?.value}>
            <DynamicSelectPaginatedFieldV2
              id="account"
              label={t('Лицевой счет')}
              placeholder={t('Выберите лицевой счет...')}
              hasError={errors.account != null && touched.account != null}
              selectHandler={getForSelectBillingAccountApiV2}
              options={account}
              disabled={isDisableField || hasDefaultBillingAccount}
              highlightEditing={isEditing(
                permanentInitialValues?.account?.value,
                account?.value
              )}
              haveReadPermission={billingAccountHaveAccessToRead}
            />
          </BillingAccountDetailsSelect>
        )}
        {selectedCounterparty ===
          SELECTED_COUNTERPARTY_IN_TASK.selectedCounterparty && (
          <CounterpartyDetailsSelect counterpartyPk={counterparty?.value}>
            <DynamicSelectPaginatedFieldV2
              keyAsyncSelect={keyAsyncSelect}
              id="counterparty"
              label={t('Контрагент')}
              placeholder={t('Выберите контрагента...')}
              hasError={
                errors.counterparty != null && touched.counterparty != null
              }
              selectHandler={getForselectCounterparty}
              options={counterparty}
              disabled={isDisableField || isDisableAccountOrCounterpartyPage}
              highlightEditing={isEditing(
                permanentInitialValues?.counterparty?.value,
                counterparty?.value
              )}
              haveReadPermission={counterpartyHaveAccessToRead}
              addonSettings={{
                hint: t('Только партнеры'),
                id: 'affiliateCheckbox',
                addonText: (
                  <InputGroupWithCheckbox
                    hint={HINT_FOR_CHECKBOX_SELECTED}
                    id="selectedCounterpartyCheckbox"
                    defaultChecked={isSelectedAffiliate}
                    disable={
                      isDisableField || isDisableAccountOrCounterpartyPage
                    }
                    onChange={handlerAffiliateCheckbox}
                  />
                ),
              }}
            />
          </CounterpartyDetailsSelect>
        )}
      </fieldset>
      <fieldset className={classNames(styles.fieldsContainer)}>
        {/* <h4>{t('Контакт для связи')}</h4> */}
        <PhoneField
          id="internal_task_reporter_phone_number"
          label={t('Дополнительный телефон')}
          placeholder={t('Укажите дополнительный телефон для связи...')}
          hasError={
            errors.internal_task_reporter_phone_number != null &&
            touched.internal_task_reporter_phone_number != null
          }
          onBlur={handleBlur}
          onChange={handleChange}
          required={false}
          defaultValue={phoneNumber}
          value={phoneNumber}
          disabled={isDisableField}
          highlightEditing={
            !isNew &&
            checkEditPhoneNumber(
              permanentInitialValues?.internal_task_reporter_phone_number,
              phoneNumber
            )
          }
        />
        <TextField
          id="internal_task_description"
          type="textarea"
          label={t('Текст')}
          placeholder={t('Описание задачи...')}
          hasError={
            errors.internal_task_description != null &&
            touched.internal_task_description != null
          }
          onBlur={handleBlur}
          onChange={handleChange}
          required={true}
          defaultValue={description}
          disabled={!!pk || isDisableField}
        />
      </fieldset>
      <DatePickerField
        id="internal_task_date_duedate"
        label={t('Запланировано')}
        placeholder={t('Выберите планируемую дату завершения...')}
        hasError={
          errors.internal_task_date_duedate != null &&
          touched.internal_task_date_duedate != null
        }
        onBlur={handleBlur}
        onChange={handlerDate}
        required={true}
        defaultValue={dueDate}
        format={config.clientDateFormat}
        disabled={isDisableField}
        minDate={new Date()}
        highlightEditing={isEditing(
          permanentInitialValues?.internal_task_date_duedate,
          dueDate
        )}
      />
      <SelectGroupField
        id="taskType"
        label={t('Тип задачи')}
        placeholder={t('Выберите тип задачи...')}
        hasError={errors.taskType != null && touched.taskType != null}
        options={taskTypeOptions}
        required={true}
        onChange={async (taskType: IClientSelectOptionV2) => {
          await setFieldValue('taskType', taskType);
        }}
        value={taskType}
        disabled={!!pk || isDisableField}
        highlightEditing={isEditing(
          permanentInitialValues?.taskType?.value,
          taskType?.value
        )}
        haveAccessPolicy={taskTypeHaveAccessToRead}
      />
      <DynamicSelectPaginatedFieldV2
        id="status"
        label={t('Статус')}
        placeholder={t('Выберите статус задачи...')}
        hasError={errors.status != null && touched.status != null}
        onChange={handleChangeStatus}
        required={true}
        options={status}
        selectHandler={getTaskStatusesForSelectApiV2}
        additionalErrorKey="status"
        disabled={isDisableField}
        highlightEditing={isEditing(
          permanentInitialValues?.status?.value,
          status?.value
        )}
      />
      <DynamicSelectPaginatedFieldV2
        id="supervisor"
        label={t('Исполнитель')}
        placeholder={t('Выберите исполнителя...')}
        hasError={errors.supervisor != null && touched.supervisor != null}
        selectHandler={getForSelectOnlyActiveEmployeeApiV2}
        options={responsibleUser}
        required={true}
        disabled={isDisableField}
        highlightEditing={isEditing(
          permanentInitialValues?.supervisor?.value,
          responsibleUser?.value
        )}
        haveReadPermission={employeeHaveAccessToRead}
      />
      <TextField
        id="internal_task_time_adjustment"
        type="number"
        label={t('Корректировка')}
        placeholder={t('Укажите сумму...')}
        hasError={
          errors.internal_task_time_adjustment != null &&
          touched.internal_task_time_adjustment != null
        }
        onBlur={handleBlur}
        onChange={handleChange}
        defaultValue={timeAdjustment}
        disabled={isDisableField}
        highlightEditing={isEditing(
          permanentInitialValues?.internal_task_time_adjustment,
          timeAdjustment
        )}
      />
      {isVisibleNewComment && (
        <TextField
          id="internal_task_new_comment"
          type="textarea"
          label={t('Комментарий')}
          hasError={
            errors.internal_task_new_comment != null &&
            touched.internal_task_new_comment != null
          }
          onBlur={handleBlur}
          onChange={handleChange}
          defaultValue={newComment}
          disabled={isDisableField}
        />
      )}
    </>
  );
};

export default TaskFormFieldsWrapper;
