import DropdownText from 'components/tables/DropdownText/DropdownText';
import { ITaskListOfComment } from '../../../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import {
  PATH_EXTERNAL_REQUEST_EDIT,
  PATH_TASK_EDIT,
} from '../../../../../services/pathConstants';
import { t } from '../../../../../services/utils/translation';
import { ITaskForList } from '../../../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import { parseISO } from 'date-fns';
import ColoredStatusLabel from 'components/misc/ColoredStatusLabel';
import ParentAndChildrenLinks from 'components/tables/ParentAndChildrenList/ParentAndChildrenLinks';
import TDCheckBox from 'components/tables/TDCheckBox/TDCheckBox';
import { ITask } from '../../../../../services/api/tasks/tasks/ITask';
import {
  changeDateFormat,
  formatDateTimeShort,
  isBeforeCurrentDate,
} from '../../../../../services/utils/dateHelper/dateHelper';
import { isPrunedToText } from 'services/utils/stringHelper/extractBooleanToText/extractBooleanToText';
import { COUNTERPARTY_TEXT } from 'services/localLocalization/servicesAndModels';
import { makeFirstLetterUppercase } from '../../../../../services/utils/stringHelper/stringHelper';
import TDSubtitle from 'components/tables/TDSubtitle/TDSubtitle';
import CounterpartyFromEntitiesLink from 'components/misc/CounterpartyFromEntitiesLink/CounterpartyFromEntitiesLink';
import TDBillingAccount from 'components/tables/TDBillingAccount/TDBillingAccount';

const toTaskForList = (tickets: ITask[]): ITaskForList[] =>
  tickets?.map((task: ITask) => {
    const {
      internal_task_pk: pk,
      internal_task_is_pruned: isPruned,
      internal_task_date_duedate: duedate,
      internal_task_datetime_added: createDate,
      internal_task_datetime_edited: editedDate,
      internal_task_status: status,
      internal_task_status: {
        internal_task_status_color: statusColor,
        internal_task_status_rendition: statusRendition,
      },
      internal_task_billing_account: billingAccount,
      internal_task_billing_account_fk: billingAccountFk,
      internal_task_parent_internal_task_fk: parentTaskFk,
      internal_task_parent_external_request_fk: parentExternalRequestFk,
      internal_task_list_of_child_external_requests: childExternalRequests,
      internal_task_list_of_child_internal_tasks: childTasks,
      internal_task_list_of_comments: comments,
      internal_task_counterparty: counterparty,
    } = task;

    const isPrunedHint = isPrunedToText(isPruned);

    return {
      ...task,
      pk,
      isPrunedText: (
        <TDCheckBox hint={isPrunedHint} id={pk} isCheck={isPruned} />
      ),
      isBeforeCurrentDate: isBeforeCurrentDate(duedate),
      duedate: changeDateFormat(duedate),
      createDate: formatDateTimeShort(parseISO(createDate)),

      editedDate: formatDateTimeShort(parseISO(editedDate)),
      statusLabel: status && (
        <ColoredStatusLabel color={statusColor}>
          {statusRendition}
        </ColoredStatusLabel>
      ),
      openComments: false,
      billingAccountAndCounterparty:
        billingAccount && billingAccountFk ? (
          <TDBillingAccount billingAccount={billingAccount} />
        ) : (
          <>
            {!!counterparty && (
              <>
                <TDSubtitle>
                  {makeFirstLetterUppercase(COUNTERPARTY_TEXT)}:
                </TDSubtitle>
                <br />
              </>
            )}
            <CounterpartyFromEntitiesLink counterparty={counterparty} />
          </>
        ),

      parentAndChildTaskLinks: (
        <ParentAndChildrenLinks
          listOfChildren={childTasks}
          parentFk={parentTaskFk}
          path={PATH_TASK_EDIT}
        />
      ),
      parentAndChildExternalRequestLinks: (
        <ParentAndChildrenLinks
          listOfChildren={childExternalRequests}
          parentFk={parentExternalRequestFk}
          path={PATH_EXTERNAL_REQUEST_EDIT}
        />
      ),
      commentElements: comments
        .reverse()
        .map(
          (comment: ITaskListOfComment): JSX.Element => (
            <DropdownText
              str={comment.internal_task_comment_text}
              author={
                comment?.internal_task_comment_author?.full_name ??
                t('Отправитель неизвестен')
              }
              limit={150}
            />
          )
        ),
    };
  });

export default toTaskForList;
