import React, { useState } from 'react';
import { t } from '../../../services/utils/translation';
import { useColumnFilter } from '../../../hooks/useColumnFilter';

import config from 'config.json';

import Subheader from 'components/layouts/Subheader/Subheader';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';

import { ReactComponent as CreateIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as DeleteIcon } from 'img/icons/icon-trash.svg';
import { ReactComponent as AddIcon } from 'img/icons/icon-pencil.svg';

import Pager from 'components/tables/Pager';
import LoaderFetch from '../../../components/layouts/LoaderFetch/LoaderFetch';
import {
  ActionsDropdown,
  ActionsDropdownItem,
} from 'components/tables/ActionsDropdown';
import { Table } from 'reactstrap';

import classNames from 'clsx';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import { ColumnFilter } from 'components/tables/ColumnFilter';
import { ColumnFilterItem } from 'components/tables/ColumnFilter';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import useModal, { IModal } from 'hooks/useModal';
import { useGetListOld, IUseFetchV2 } from '../../../hooks/useGetListOld';
import { IFileBucketSchemaParams } from '../../../services/api/documents/file-bucket-schema/IGetFileBucketSchema';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyFileBucketSchema } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyFileBucketSchema';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import { IFileBucketSchema } from 'services/api/documents/file-bucket-schema/IFileBucketSchema';
import useToAccessPolicyTransformToObject from 'hooks/useToAccessPolisyTransformToObject/useToAccessPolicyTransformToObject';
import FileBucketSchemaFrom from '../../../components/documents/FileBucketSchemaFrom/FileBucketSchemaFrom';
import {
  deleteFileBucketSchemaApi,
  getListFileBucketSchemaApi,
} from 'services/api/documents/file-bucket-schema/fileBucketSchemaApi';

const COLUMN_LABEL = {
  PK: t('№'),
  NAME: t('Название'),
  DESCRIPTION: t('Описание'),
  ACCESS_POLICY: t('Роли'),
};

const filters = [
  { name: 'pk', label: COLUMN_LABEL.PK },
  { name: 'name', label: COLUMN_LABEL.NAME },
  { name: 'description', label: COLUMN_LABEL.DESCRIPTION },
  { name: 'accessPolicy', label: COLUMN_LABEL.ACCESS_POLICY },
] as const;

const columnVisibilityInitial = {
  pk: true,
  name: true,
  description: true,
  accessPolicy: true,
};

const deleteMessageError = t(
  'Не удалось удалить тип документа. Обратитесь к администратору.'
);
const CREATE_BUTTON_TITLE = t('Добавить тип документа');
const MODAL_EDIT_TITLE = t('Редактировать тип документа');
const MODAL_CREATE_TITLE = t('Создать тип документа');
const MODAL_DELETE_TITLE = t('Удалить тип документа');
const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);
const FileBucketSchemaPage = () => {
  const [initialParams] = useState<IFileBucketSchemaParams>({
    file_bucket_schema_name: '',
    pk: null,
    length: config.pageLength,
    skip: 0,
  });

  const { roles, isLoadingRoles } = useToAccessPolicyTransformToObject();

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(columnVisibilityInitial);

  const {
    data: listSchema,
    isLoading: isLoadingListSchemes,
    doRefresh,
    onSearchRequest,
    setStart,
    setLength,
    total,
    params: { skip, length, pk, file_bucket_schema_name },
  }: IUseFetchV2<IFileBucketSchema[], IFileBucketSchemaParams> = useGetListOld({
    getDataApi: getListFileBucketSchemaApi,
    initialParams,
  });

  const [haveAccessToUpdate, haveAccessToCreate, haveAccessToDelete] =
    useHaveAccessPolicyToActs({
      targetAccessPolicy: targetAccessPolicyFileBucketSchema,
      targetAction: ['U', 'C', 'D', 'E'],
    });

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetSchema },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<IFileBucketSchema> = useModal({
    setRefresh: doRefresh,
    deleteRequest: deleteFileBucketSchemaApi,
    entityIdKey: 'pk',
  });

  const isLoading = isLoadingListSchemes || isLoadingRoles;

  return (
    <MainLayout>
      <Subheader>
        {haveAccessToCreate && (
          <SubheaderButton onClick={() => openAddEditForm()} bordered={false}>
            <CreateIcon className="mr-2 text-white" /> {CREATE_BUTTON_TITLE}
          </SubheaderButton>
        )}
      </Subheader>
      <div className="table-responsive-none">
        <Table
          hover
          className="table table-bordered table-hover table-responsive-xl"
        >
          <thead>
            <tr>
              <th
                scope="col"
                className={`id pr-0 ${!visibility.pk ? 'd-none' : ''}`}
              >
                <HeaderWithSearch
                  title={COLUMN_LABEL.PK}
                  onSearch={(searchString: string) =>
                    onSearchRequest(searchString, 'pk')
                  }
                  defaultValue={pk?.toString()}
                  customStyle={{ marginLeft: '0px' }}
                  isOnlyNumbers
                />
              </th>
              <th
                scope="col"
                className={classNames('p-3', 'w-15', {
                  'd-none': !visibility.name,
                })}
              >
                <HeaderWithSearch
                  title={COLUMN_LABEL.NAME}
                  onSearch={(searchString: string) =>
                    onSearchRequest(searchString, 'file_bucket_schema_name')
                  }
                  defaultValue={file_bucket_schema_name}
                />
              </th>

              <th
                scope="col"
                className={classNames('p-3', 'w-15', {
                  'd-none': !visibility.description,
                })}
              >
                {COLUMN_LABEL.DESCRIPTION}
              </th>
              <th
                scope="col"
                className={classNames('p-3', 'w-15', {
                  'd-none': !visibility.accessPolicy,
                })}
              >
                {COLUMN_LABEL.ACCESS_POLICY}
              </th>

              <th scope="col" className="actions p-0   'w-5'">
                <ColumnFilter onApply={applyVisibilityChanges}>
                  {filters &&
                    filters.map(({ name, label }) => (
                      <ColumnFilterItem
                        key={name}
                        name={name}
                        label={label}
                        defaultChecked={visibility[name]}
                        onChange={onFilterItemToggle}
                      />
                    ))}
                </ColumnFilter>
              </th>
            </tr>
          </thead>
          {!isLoading && (
            <tbody>
              {listSchema &&
                listSchema.map((schema: IFileBucketSchema) => (
                  <tr key={schema.pk}>
                    <td className={classNames({ 'd-none': !visibility.pk })}>
                      {schema.pk}
                    </td>
                    <td
                      className={classNames({
                        'd-none': !visibility.name,
                      })}
                    >
                      {schema.file_bucket_schema_name}
                    </td>
                    <td
                      className={classNames({
                        'd-none': !visibility.description,
                      })}
                    >
                      {schema.file_bucket_schema_description}
                    </td>
                    <td
                      className={classNames({
                        'd-none': !visibility.accessPolicy,
                      })}
                    >
                      {schema.access_policies?.map((pkAccessPolicy: number) => (
                        <p key="pk">{[roles[pkAccessPolicy]]}</p>
                      ))}
                    </td>
                    <td align="center">
                      <ActionsDropdown>
                        {haveAccessToUpdate && (
                          <ActionsDropdownItem
                            label={t('Редактировать')}
                            onClick={() => {
                              openAddEditForm(schema);
                            }}
                            icon={<AddIcon className="text-primary" />}
                          />
                        )}
                        {haveAccessToDelete && (
                          <ActionsDropdownItem
                            label={t('Удалить')}
                            onClick={() => {
                              toggleDeleteForm(schema);
                            }}
                            icon={<DeleteIcon className="text-primary" />}
                          />
                        )}
                      </ActionsDropdown>
                    </td>
                  </tr>
                ))}
              {listSchema.length === 0 && (
                <tr>
                  <td colSpan={5}>{t('Нет типов документов')}</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
        {isLoading && <LoaderFetch />}
      </div>
      <Pager
        onPageChange={setStart}
        onLengthChange={setLength}
        total={total}
        length={length}
        start={skip}
      />
      {targetSchema?.pk && (
        <PopupDeleteForm
          headerLabel={MODAL_DELETE_TITLE}
          entityId={targetSchema.pk}
          entityName={targetSchema.file_bucket_schema_name}
          deleteHandler={entityDeleteHandler}
          isOpen={isDeleteFormOpen}
          toggleHandler={toggleDeleteForm}
          deleteMessageError={deleteMessageError}
        />
      )}
      <ModalWrapper
        headerLabel={getModalTitle(!!targetSchema?.pk)}
        isOpen={isAddEditFormOpen}
        closeHandler={closeAddEditForm}
      >
        <FileBucketSchemaFrom
          successHandler={successHandler}
          pk={targetSchema?.pk}
        />
      </ModalWrapper>
    </MainLayout>
  );
};

export default FileBucketSchemaPage;
