import React, { memo } from 'react';
import { GroupedOptionsType, OptionsType, ValueType } from 'react-select';
import Select from 'react-select';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import styles from './index.module.scss';
import classNames from 'clsx';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { hasValueInSelect } from 'services/utils/selects/selects';
import MultiValueLabelWithTooltip from 'components/misc/MultiValueLabelWithTooltip/MultiValueLabelWithTooltip';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import { HEADER_SELECT_COLOR } from 'hooks/useGetSelectStyle';

type PropsType<T = number> = {
  label: string;
  placeholder?: string;
  onChange: Function; //ChangeEventHandler,
  required?: boolean;
  defaultValue?: ValueType<
    IClientSelectOptionV2<T> | IClientSelectOptionV2<T>[],
    boolean
  > | null | null;
  value?: ValueType<
    IClientSelectOptionV2<T> | IClientSelectOptionV2<T>[],
    boolean
  > | null;
  options?:
    | GroupedOptionsType<IClientSelectOptionV2<T>>
    | OptionsType<IClientSelectOptionV2<T>>;
  showDefaultOption?: boolean;
  defaultOptionLabel?: string;
  isMulti?: boolean;
  disabled?: boolean;
  className?: string;
  hint?: string;
  headerColor?: TABLE_COLOR;
};

const HeaderWithSelectAdvanced = <T,>(props: PropsType<T>) => {
  const {
    label,
    onChange,
    className,
    options,
    placeholder,
    required,
    value,
    defaultValue,
    isMulti,
    disabled,
    hint,
    headerColor = TABLE_COLOR.DEFAULT,
  } = props;

  const selectStyles = {
    menu: (styles: any) => ({ ...styles, zIndex: 999, cursor: 'pointer' }),
    multiValueLabel: (styles: any) => ({ ...styles, background: '#D6F1F7' }),
    multiValueRemove: (styles: any) => ({ ...styles, background: '#D6F1F7' }),
    control: (styles: any) => ({
      ...styles,
      '&:hover': { borderColor: '#ACB5C4' },
      border: 'none',
      height: '100%',
      cursor: 'pointer',
      backgroundColor: HEADER_SELECT_COLOR[headerColor],
    }),
    dropdownIndicator: (styles: any) => ({ ...styles, display: 'none' }),
    IndicatorsContainer: (styles: any) => ({ ...styles, display: 'none' }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
    container: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      height: '100%',
    }),
  };

  const id = label;
  const hasValue = hasValueInSelect(defaultValue);

  return (
    <>
      <Select
        id={id}
        placeholder={placeholder ? placeholder : label}
        options={options}
        onChange={(
          value: ValueType<
            IClientSelectOptionV2<T> | IClientSelectOptionV2<T>[],
            boolean
          >
        ) => onChange(value)}
        className={classNames(styles['my-select'], className, {
          [styles['container-backlight']]: hasValue && !disabled,
        })}
        required={required}
        isMulti={isMulti}
        value={value != null ? value : defaultValue}
        defaultValue={defaultValue}
        isDisabled={disabled}
        isClearable={true}
        styles={selectStyles}
        components={{ MultiValue: MultiValueLabelWithTooltip }}
      />
      {hint && id && (
        <ToolTipWrapper target={id} placement="top">
          {hint}
        </ToolTipWrapper>
      )}
    </>
  );
};

export default memo(HeaderWithSelectAdvanced);
