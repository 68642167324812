import { useCallback } from 'react';
import isEqual from 'react-fast-compare';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { IKeyValue } from 'services/interfaces/IKeyValue';
import { t } from 'services/utils/translation';

type TValue =
  | string
  | number
  | IClientSelectOptionV2
  | IClientSelectOptionV2[]
  | boolean;

export type TChangesValuesForm = {
  nameProp: string;
  newValue: string;
  prevValue: string;
};

const TRUE_STRING = t('да');
const FALSE_STRING = t('нет');

const handlers: { [key: string]: (value: TValue) => string } = {
  string: (value: TValue) => `${value}`,
  number: (value: TValue) => `${value}`,
  boolean: (value: TValue) => (value ? TRUE_STRING : FALSE_STRING),
  object: (value?: TValue) => {
    const tValue = value as IClientSelectOptionV2;
    return tValue?.label;
  },
  array: (value: TValue) =>
    Array.isArray(value) &&
    value.every((v) => typeof v === 'object' && 'label' in v && 'value' in v)
      ? value.map((v) => v.label).join(' ')
      : '',
};

const extractValue = (value: TValue): string => {
  const type = Array.isArray(value) ? 'array' : typeof value;
  if (type in handlers) {
    const tType = type as keyof typeof handlers;
    return handlers[tType](value);
  } else {
    return '';
  }
};

const defaultNameProp = `У этого свойства нет локализации. Обратитесь, пожалуйста, к администратору`;

interface PropsType {
  localization: IKeyValue;
}

const useGetDifferentTransformValues = (props: PropsType) => {
  const { localization } = props;

  return useCallback(
    <TypeFormValue extends { [x: string]: any }>(
      initialValues: TypeFormValue, // Значения из начальной формы
      newValues: TypeFormValue, // Текущие значения формы
      currentServerValues: TypeFormValue // Текущие значения с сервера
    ) => {
      const changedOnServerNotEditedByUser: TChangesValuesForm[] = []; // Значения, измененные на сервере, но не редактированные пользователем
      const editedButNotSaved: TChangesValuesForm[] = []; // Значения, измененные пользователем, но не сохраненные

      Object.entries(newValues).forEach(([key, newValue]: [string, TValue]) => {
        const initialValueForKey = initialValues[key];
        const serverValueForKey = currentServerValues[key];

        const nameProp = localization?.[key] ?? defaultNameProp;

        // Если значение на сервере изменилось, но пользователь это не редактировал
        if (
          !isEqual(initialValueForKey, serverValueForKey) &&
          isEqual(initialValueForKey, newValue)
        ) {
          changedOnServerNotEditedByUser.push({
            nameProp,
            newValue: extractValue(serverValueForKey),
            prevValue: extractValue(initialValueForKey),
          });
        }

        // Если пользователь изменил значение, но оно не было сохранено, и серверное значение также поменялось
        if (
          !isEqual(initialValueForKey, newValue) &&
          !isEqual(serverValueForKey, newValue) &&
          !isEqual(initialValueForKey, serverValueForKey)
        ) {
          editedButNotSaved.push({
            nameProp,
            newValue: extractValue(serverValueForKey),
            prevValue: extractValue(newValue),
          });
        }
      });

      return { changedOnServerNotEditedByUser, editedButNotSaved };
    },
    [localization]
  );
};

export default useGetDifferentTransformValues;
