import BillingAccount from 'models/BillingAccount';
import React, { FC } from 'react';
import BillingAccountForm from 'components/organizations/billing-account/BillingAccountForm/BillingAccountForm';
import Subheader from 'components/layouts/Subheader/Subheader';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import { useLocation } from 'react-router-dom';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyBillingAccount } from '../../../../services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import SubHeaderEditEntity from 'components/controls/FormHeader/FormHeaderEditEntity/SubHeaderEditEntity';
import { formTitleBillingAccount } from '../../../../services/constants/FormTitles/FormTitles';
import useGoBackNavigate from 'hooks/useGoBackNavigate/useGoBackNavigate';
import useGetLastBillingAccountsCodeNumber from './hooks/useGetLastBillingAccountCodeNumber';

const titleHelpText = `Номера последних созданных ЛС:`;

const BillingAccountAddPage: FC = () => {
  const [haveAccessToCreate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyBillingAccount,
    targetAction: ['C'],
  });

  const successHandler = useGoBackNavigate();
  const location = useLocation();
  const counterpartyPk = location.state?.counterpartyPk;

  const initialValue = new BillingAccount(undefined, counterpartyPk);

  const subHeaderTitle = formTitleBillingAccount.getFormTitle();

  const {
    lastBillingAccountCodeNumber,
    isLoadingLastCreatedBillingAccountCodeNumber,
  } = useGetLastBillingAccountsCodeNumber();

  const helpText = `${titleHelpText} 
${lastBillingAccountCodeNumber}`;

  return (
    <MainLayout>
      <Subheader />
      <SubHeaderEditEntity title={subHeaderTitle} />
      <BillingAccountForm
        successHandler={successHandler}
        initialValue={initialValue}
        isLoading={isLoadingLastCreatedBillingAccountCodeNumber}
        isViewOnly={!haveAccessToCreate}
        lastBillingAccountCodeNumber={helpText}
      />
    </MainLayout>
  );
};

export default BillingAccountAddPage;
