import { useMemo } from 'react';
import { getPaginatedBillingAccountsV2 } from '../../../../../services/api/organizationsV2/billing-account/billingAccountApi';
import { initialBillingAccountsFilters } from 'store/initialStore/initialBillingAccountsFilters';
import BillingAccountSortCriteria from 'services/api/organizationsV2/billing-account/BillingAccountSortCriteria';
import { useGetList } from 'hooks/useGetList';
 import { IBillingAccountFilters } from 'services/api/organizationsV2/billing-account/IGetPaginatedBillingAccountsParams';
import extractLastCodeNumbers from '../utils/extractLastCodeNumbers';

const MAX_LENGTH = 10;



const useGetLastBillingAccountsCodeNumber = () => {
  const initialParams: IBillingAccountFilters = useMemo(
    () => ({
      ...initialBillingAccountsFilters,
      sort_by: BillingAccountSortCriteria.DateAddedDesc,
      length: MAX_LENGTH,
    }),
    []
  );

  const {
    isLoading: isLoadinglastBillingAccountCodeNumber,
    data: lastBillingAccountCodeNumber,
  } = useGetList({
    getDataApi: getPaginatedBillingAccountsV2,
    initialParams,
    convertData: extractLastCodeNumbers,
    initialData: '',
  });

  return {
    lastBillingAccountCodeNumber,
    isLoadingLastCreatedBillingAccountCodeNumber: isLoadinglastBillingAccountCodeNumber,
  };
};

export default useGetLastBillingAccountsCodeNumber;
