import { FC, memo } from 'react';
import { Alert, Button } from 'reactstrap';
import { BootstrapColors } from '../../../services/constants/BootstrapColors';
import classNames from 'clsx';
import styles from './index.module.scss';

type PropsTypeAlert = {
  color?: BootstrapColors;
  text: string;
  helpText?: string | JSX.Element;
  onAgree?: {
    onAgreeText: string;
    onAgreeCall: () => void;
  };
  onCancel?: {
    onCancelText: string;
    onCancelCall: () => void;
  };
};

const AlertWrapper: FC<PropsTypeAlert> = ({
  text,
  helpText,
  onCancel,
  onAgree,
  color = BootstrapColors.danger,
}) => {
  return (
    <Alert color={color} className={classNames(styles.alert, 'px-4 py-3')}>
      <div className={classNames(styles.alertContent, 'text-center')}>
        <h4 className={styles.alertTitle}>{text}</h4>
        {helpText && <div className={styles.alertHelpText}>{helpText}</div>}
      </div>
      <div className={classNames(styles.alertActions, 'd-flex gap-2 mt-3')}>
        {onAgree && (
          <Button
            onClick={onAgree.onAgreeCall}
            color={BootstrapColors.primary}
            className={classNames(styles.button, 'w-50')}
          >
            {onAgree.onAgreeText}
          </Button>
        )}
        {onCancel && (
          <Button
            outline
            color="danger"
            onClick={onCancel.onCancelCall}
            className={classNames(styles.cancelButton, 'w-50')}
          >
            {onCancel.onCancelText}
          </Button>
        )}
      </div>
    </Alert>
  );
};

export default memo(AlertWrapper);
