import {
  add,
  addDays,
  addHours,
  format,
  formatISO,
  isBefore,
  parse,
  parseISO,
} from 'date-fns';
import config from '../../../config.json';
import { getValueOfYupField } from 'services/yup/utils/getМalueOfAnotherField';
import Yup from 'services/yup/checkDateRange';
import moment from 'moment';
import { ru } from 'date-fns/locale';

//TODO refactoring:  rewrite -  class or divide into parts
/**
 * Check if the value formatted as a date dd.mm.yyyy,
 *
 * @param {string} value
 * @returns {boolean}
 */
export const validateInputDate = (value?: string | null): boolean => {
  if (value == null) {
    return false;
  }
  const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;
  return regex.test(value);
};
export const parseDateFromServer = (date: string): Date =>
  parse(date, config.serverDateFormat, new Date());

export const validateInputClientDateTimeFormatShort = (
  value?: string | null
): boolean => {
  if (value == null) {
    return false;
  }
  const pattern = new RegExp(
    '^(3[01]|[12][0-9]|0[1-9])\\.(1[0-2]|0[1-9])\\.[0-9]{4} (2[0-3]|[01]?[0-9]):([0-5]?[0-9])$',
    'm'
  );
  return pattern.test(value);
};
/**
 * Format date to client format.
 *
 * @deprecated The function name was not ideal. Please use formatDate() instead.
 *
 * @param {Date} date
 */
export const formatDateFromServer = (date: Date): string => {
  return format(date, config.clientDateFormat);
};
export const formatDateForServerV2 = (date: Date): string => {
  return format(date, config.serverDateFormat);
};
export const formatDate = (date: Date): string => {
  return formatDateFromServer(date);
};
export const formatDateTime = (date: Date): string => {
  return format(date, config.clientDateTimeFormat);
};

export const formatTime = (date: Date): string => {
  return format(date, config.clientTimeFormat);
};
export const formatDateTimeShort = (date: Date): string => {
  return format(date, config.clientDateTimeFormatShort);
};
export const parseISOAndFormatDateTime = (date: string) =>
  formatDateTime(parseISO(date));

export const formatDateTimeWithoutYear = (date: Date): string => {
  return format(date, config.clientDateTimeWithoutYear);
};

export const formatDateTimeLocaleDayAndMonth = (date: Date) =>
  format(date, config.clientDateTimeDayAndMonth, {
    locale: ru,
  }).replace('.', '');

export const parseDateFromClientDateFormatShortTime = (date: string) => {
  const [startDateString, startHRAAndMM] = date.split(' ');
  const [hours, minutes] = startHRAAndMM.split(':');
  return add(parse(startDateString, config.clientDateFormat, new Date()), {
    hours: +hours,
    minutes: +minutes,
  });
};

export const createClientDateTimeShort = (date: string): string =>
  formatDateTimeShort(addHours(parseISO(date), config.GMT));

export const splitDateFromClient = (date: string): number[] => {
  const [day, month, year] = date.split('.');
  return [+year, +month, +day];
};
export const createDateFromParts = (dateParts: number[]): Date => {
  const [year, month, day] = dateParts;

  return new Date(year, month - 1, day);
};
export const isBeforeCurrentDate = (periodEnd?: string) =>
  !periodEnd ? false : !isBefore(new Date(), addDays(new Date(periodEnd), 1));

export const createDateFromClientFormForServerV2 = (date: string) => {
  return formatDateForServerV2(createDateFromParts(splitDateFromClient(date)));
};

export const isValidDate = (d: Date): boolean => {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    return !isNaN(d.getTime());
  }

  return false;
};

export const checkStartDateBeforeCurrentDate =
  (key: string) =>
  <Model,>(yupInstance: Yup.TestContext<Model>): boolean => {
    const valueDate = getValueOfYupField(key)(yupInstance);
    const momentDateStart = moment().startOf('day');

    if (valueDate) {
      const [day, month, year] = valueDate.split('.');
      const valueEnd = moment(new Date(year, month - 1, day), 'DD.MM.YYYY');
      const momentDateEnd = moment(valueEnd);
      return (
        momentDateStart.isSame(momentDateEnd) ||
        momentDateStart.isBefore(momentDateEnd)
      );
    }
    return true;
  };

export const validationDateRange = (
  yupInstance: any,
  keyStart: string,
  keyEnd: string
) => {
  const wholeDateStart = yupInstance.from[0].value[keyStart]?.split(' ');
  const wholeDateEnd = yupInstance.from[0].value[keyEnd]?.split(' ');

  const checkTimeStart = new Date();
  const checkTimeEnd = new Date();
  if (wholeDateStart && wholeDateEnd) {
    const [dateStart, timeStart] = wholeDateStart;
    const [dateEnd, timeEnd] = wholeDateEnd;

    const momentDateStart = moment(dateStart, 'DD.MM.YYYY');
    const momentDateEnd = moment(dateEnd, 'DD.MM.YYYY');
    checkTimeStart.setMilliseconds(timeStart.replace(':', ''));
    checkTimeEnd.setMilliseconds(timeEnd.replace(':', ''));

    if (momentDateStart.isSame(momentDateEnd)) {
      return checkTimeStart < checkTimeEnd;
    } else if (!momentDateStart.isBefore(momentDateEnd)) {
      return false;
    }
  }

  return true;
};

export const sliceTimestamp = (fileName: string) =>
  fileName.replace(/^[0-9]+\.?([0-9]+)?-/gm, '');

export const changeDateFormat = (date: string): string => {
  if (!isValidDate(new Date(date))) {
    return 'Invalid Date';
  } else {
    return format(new Date(date), config.clientDateFormat);
  }
};

export const getCurrentDateToClientDate = () => {
  return formatDateFromServer(new Date());
};

export const getCurrentDateServer = () => {
  const today = new Date();
  return formatISO(today, { representation: 'complete' });
};

export const isDateInPast = (date: string | Date): boolean => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    throw new Error('Invalid date provided');
  }

  // Обнуляем время у обеих дат
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const inputDate = new Date(parsedDate);
  inputDate.setHours(0, 0, 0, 0);

  return inputDate < today;
};
