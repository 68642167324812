import {
  IExternalRequestFilters,
  IPaginatedParamsExternalRequest,
} from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import config from 'config.json';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import trimStringInObject from 'services/utils/trimStringInObject/trimStringInObject';
import { composeClientSelectOptionsInNumbersArray } from 'services/utils/selects/composeIClientSelectOptionsInNumbers';
import deleteUnnecessaryParameters from 'services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { extractValueFromClientSelectOptionV2 } from 'services/utils/selects/selects';
import TObjectKeysInArray from 'services/types/TObjectKeys';

// Типы ключей для массивов параметров запроса
type TExternalRequestArrayKey =
  | 'external_request_work_category_fk_array'
  | 'external_request_author_dk_array'
  | 'external_request_responsible_department_fk_array'
  | 'external_request_implementer_dk_array'
  | 'external_request_affiliate_fk_array'
  | 'external_request_status_fk_array'
  | 'external_request_event_fk_array'
  | 'external_request_billing_account_fk_array';

type TExternalRequestArrayOptions = Record<TExternalRequestArrayKey, number[]>;

export default class ExternalRequestParamsTransformer {
  private arrayKeys: TExternalRequestArrayKey[] = [
    'external_request_author_dk_array',
    'external_request_responsible_department_fk_array',
    'external_request_work_category_fk_array',
    'external_request_implementer_dk_array',
    'external_request_affiliate_fk_array',
    'external_request_status_fk_array',
    'external_request_event_fk_array',
    'external_request_billing_account_fk_array',
  ];
  private readonly exeptionKeys: TObjectKeysInArray<IPaginatedParamsExternalRequest> =
    ['external_request_is_pruned'];

  private readonly removableKeys: TObjectKeysInArray<IExternalRequestFilters> =
    [
      'isActiveImplementerDkArrayFilter',
      'isActivePkFilter',
      'isActiveBillingAccountFilter',
      'isActiveWorkCategoryFilter',
    ];

  private readonly filters: IExternalRequestFilters;
  private readonly arrayOptions: TExternalRequestArrayOptions;
  private readonly isPrunedEnabled: boolean;

  constructor(filters: IExternalRequestFilters) {
    this.filters = filters;
    this.isPrunedEnabled = config.isPrunedOn;
    this.arrayOptions = this.extractArrayOptions(filters);
  }

  public transform(): IPaginatedParamsExternalRequest {
    const paramsWithArrayOptions = this.addArrayOptions();
    const cleanedParams = trimStringInObject(paramsWithArrayOptions);
    return this.removeUnnecessaryParams(cleanedParams);
  }

  private extractArrayOptions(
    filters: IExternalRequestFilters
  ): TExternalRequestArrayOptions {
    return composeClientSelectOptionsInNumbersArray<
      IExternalRequestFilters,
      TExternalRequestArrayOptions
    >(filters, this.arrayKeys);
  }

  private addArrayOptions(): IPaginatedParamsExternalRequest {
    const {
      external_request_supervisor_dk,
      external_request_executive_implementer_dk,
      external_request_is_pruned,
    } = this.filters;

    return {
      ...this.filters,
      ...this.arrayOptions,
      external_request_supervisor_dk: external_request_supervisor_dk?.value,
      external_request_executive_implementer_dk:
        external_request_executive_implementer_dk?.value,
      external_request_is_pruned: this.determineIsPruned(
        external_request_is_pruned
      ),
    };
  }

  private removeUnnecessaryParams(
    params: IPaginatedParamsExternalRequest
  ): IPaginatedParamsExternalRequest {
    return deleteUnnecessaryParameters(
      params,
      this.removableKeys,
      this.exeptionKeys
    );
  }

  private determineIsPruned(
    isPrunedOption?: IClientSelectOptionV2<boolean>
  ): boolean | undefined {
    return this.isPrunedEnabled
      ? extractValueFromClientSelectOptionV2(isPrunedOption)
      : false;
  }
}
