import React, {
  useState,
  FC,
  memo,
  ReactChild,
  ReactChildren,
  PropsWithChildren,
} from 'react';
import { Spinner, Tooltip, UncontrolledTooltipProps } from 'reactstrap';
import * as Popper from 'popper.js';
import styles from './index.module.scss';
import classNames from 'clsx';
import { t } from 'services/utils/translation';

const LOADING_TEXT = t('Загрузка...');

type PropsType = {
  isVisible?: boolean;
  target: string | HTMLElement | React.RefObject<HTMLElement>;
  placement?: Popper.Placement;
  children: ReactChild | ReactChildren;
  className?: string;
  isLoadingDescription?: boolean;
} & UncontrolledTooltipProps;

const ToolTipWrapper: FC<PropsWithChildren<PropsType>> = (props) => {
  const {
    children,
    className,
    isVisible = true,
    isLoadingDescription = false,
    target = 'top',
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toolTipText = !isLoadingDescription ? (
    <span>{children}</span>
  ) : (
    <span>
      {LOADING_TEXT}
      <Spinner color="light" size="sm" />
    </span>
  );
  return (
    <>
      {isVisible && (
        <Tooltip
          isOpen={tooltipOpen}
          toggle={toggle}
          innerClassName={classNames(styles.tooltip, className)}
          {...props}
          target={target}
          autohide={false}
        >
          {toolTipText}
        </Tooltip>
      )}
    </>
  );
};

export default memo(ToolTipWrapper);
