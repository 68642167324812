import classNames from 'clsx';
import React, { ChangeEventHandler, FC, memo } from 'react';
import { CustomInput, FormGroup } from 'reactstrap';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';

type TypeProps = {
  onChange: ChangeEventHandler;
  name: string;
  label?: string;
  // Set defaultChecked if you're not going to change it programmatically.
  defaultChecked?: boolean;
  // Set checked programmatically.
  checked?: boolean;
  hasError?: boolean;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  classNameCheck?: string;
};

const Checkbox: FC<TypeProps> = (props) => {
  const {
    onChange,
    label,
    defaultChecked,
    checked,
    name,
    hasError,
    required,
    className,
    disabled,
    classNameCheck,
  } = props;

  const inputClasses = classNames(classNameCheck, { 'is-invalid': hasError });

  const checkedAttribute = checked != null ? { checked } : {};
  const defaultCheckedAttribute =
    defaultChecked != null ? { defaultChecked } : {};

  return (
    <FormGroup className={classNames({ required: required }, className)} check>
      <ErrorWrapperField id={name}>
        <CustomInput
          id={name}
          name={name}
          type="checkbox"
          onChange={onChange}
          label={label}
          className={inputClasses}
          {...checkedAttribute}
          {...defaultCheckedAttribute}
          disabled={disabled}
        />
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default memo(Checkbox);
