const trimStringInObject = <T,>(params: T): T => {
  const newObject = { ...params } as Record<string, unknown>;
  for (const key in params) {
    if (typeof params[key] === 'string') {
      newObject[key] = (params[key] as string).trim();
    }
  }
  return newObject as T;
};
export default trimStringInObject;
