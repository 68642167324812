import { RootStateOrAny } from 'react-redux';
import { ITaskFilters } from '../../services/api/tasks/tasks/IGetPaginatedTasksParams';
import IClientSelectOptionV2 from '../../services/api/interfacesApi/IClientSelectOptionV2';
import { ITaskClientAccessPolicy, ITaskFormStore } from './initialTask';
import { RootState } from 'store/IRootState';

export const getTaskMarkedFromStore = (state: RootState) => state.tasks.marked;

export const getTaskFilters = (state: RootStateOrAny): ITaskFilters =>
  state.tasks.tableFilters;

export const getDefaultStatusFromStore = (
  state: RootState
): IClientSelectOptionV2<number> | undefined => state.tasks.defaultStatus;

export const getTaskAccessPolicyFromStore = (
  state: RootState
): ITaskClientAccessPolicy => state.tasks.taskAccessPolicy.accessPolicy;

export const getTaskIsInstallAccessPolicyFromStore = (
  state: RootState
): boolean => state.tasks.taskAccessPolicy.isInstalled;

export const getTaskFormValuesFromStore = (state: RootState): ITaskFormStore =>
  state.tasks.form;


export const getTaskFormHasEntered = (state: RootState): boolean =>
  state.tasks.isEnteredForm;
