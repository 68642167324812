import transition from './transition.module.scss';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState, PropsWithChildren } from 'react';
import AlertWrapper from '../AlertWrapper/AlertWrapper';

function withAnimation<T>(WrappedComponent: React.ComponentType<T>) {
  return (props: T & PropsWithChildren<{}>) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      setIsVisible(true);
    }, []);

    return (
      <CSSTransition
        timeout={3000}
        classNames={transition}
        appear
        unmountOnExit
        in={isVisible}
      >
        <div>
          <WrappedComponent {...props} />
        </div>
      </CSSTransition>
    );
  };
}

const AnimatedAlertWrapper = withAnimation(AlertWrapper);

export default AnimatedAlertWrapper;
