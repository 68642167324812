import { t } from './utils/translation';

export const PATH_FRONTPAGE = '/';

export const PATH_NOTIFICATIONS = `${PATH_FRONTPAGE}notifications`;
export const PATH_LOGIN = `${PATH_FRONTPAGE}login`;

export const PATH_CONTROLLER_FOLDER = `${PATH_FRONTPAGE}controller-ensemble`;
export const PATH_CONTROLLER_PAGE = `${PATH_FRONTPAGE}controller`;
export const PATH_CONTROLLER_EDIT = `${PATH_FRONTPAGE}controller/:id/edit`;
export const PATH_CONTROLLER_ADD = `${PATH_FRONTPAGE}controller/add`;

export const PATH_TASK = '/internal-task';
export const PATH_TASK_FOLDER = `${PATH_FRONTPAGE}internal-task-ensemble`;
export const PATH_TASK_TABLE = `${PATH_TASK_FOLDER}${PATH_TASK}`;
export const PATH_TASK_EDIT = `${PATH_TASK_TABLE}/:id/edit`;
export const PATH_TASK_CREATE_CHILD_TICKET = `${PATH_TASK_TABLE}/:id/create-child-task`;
export const PATH_TASK_CREATE_FROM_EXTERNAL_REQUEST = `${PATH_TASK_TABLE}/:id/create-from-external-request`;
export const PATH_TASK_TYPES = `${PATH_TASK_TABLE}-type`;
export const PATH_TASK_STATUSES = `${PATH_TASK_TABLE}-status`;

export const PATH_EXTERNAL_REQUEST_FOLDER = `${PATH_FRONTPAGE}external-request-ensemble`;

export const PATH_EXTERNAL_REQUEST = '/external-request';
export const PATH_EXTERNAL_REQUEST_TABLE = `${PATH_EXTERNAL_REQUEST_FOLDER}${PATH_EXTERNAL_REQUEST}`;
export const PATH_EXTERNAL_REQUEST_ADD = `${PATH_EXTERNAL_REQUEST_TABLE}/add`;
export const PATH_EXTERNAL_REQUEST_COPY = `${PATH_EXTERNAL_REQUEST_TABLE}/:id/copy`;
export const PATH_EXTERNAL_REQUEST_FROM_TASK = `${PATH_EXTERNAL_REQUEST_TABLE}/:id/create-from-task`;
export const PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST = `${PATH_EXTERNAL_REQUEST_TABLE}/:id/create-child-external-request`;
export const PATH_EXTERNAL_REQUEST_EDIT = `${PATH_EXTERNAL_REQUEST_TABLE}/:id/edit`;
export const PATH_EXTERNAL_REQUEST_WORK_CATEGORY = `${PATH_EXTERNAL_REQUEST_FOLDER}/work-category`;
export const PATH_EXTERNAL_REQUEST_RESPONSIBLE_DEPARTMENT = `${PATH_EXTERNAL_REQUEST_FOLDER}/responsible-department`;

export const PATH_ORGANIZATIONS = `${PATH_FRONTPAGE}organizations`;
export const PATH_ORGANIZATIONS_COUNTERPARTY = `${PATH_ORGANIZATIONS}/counterparty`;
export const PATH_ORGANIZATIONS_COUNTERPARTY_EDIT = `${PATH_ORGANIZATIONS_COUNTERPARTY}/:id`;
export const PATH_ORGANIZATIONS_COUNTERPARTY_ADD = `${PATH_ORGANIZATIONS_COUNTERPARTY}/add`;

export const PATH_ORGANIZATIONS_BILLING_ACCOUNT_TYPE = `${PATH_ORGANIZATIONS}/billing-account-type`;
export const PATH_ORGANIZATIONS_ACCOUNTS = `${PATH_ORGANIZATIONS}/billing-account`;
export const PATH_ORGANIZATIONS_ACCOUNTS_ADD = `${PATH_ORGANIZATIONS_ACCOUNTS}/add`;
export const PATH_ORGANIZATIONS_ACCOUNTS_EDIT = `${PATH_ORGANIZATIONS_ACCOUNTS}/:id`;
export const PATH_ORGANIZATIONS_ACCOUNTS_VIEW = `${PATH_ORGANIZATIONS_ACCOUNTS}/:id/view`;

export const PATH_ORGANIZATIONS_EVENT = `${PATH_ORGANIZATIONS}/event`;
export const PATH_ORGANIZATIONS_EVENT_ADD = `${PATH_ORGANIZATIONS_EVENT}/add`;
export const PATH_ORGANIZATIONS_EVENT_EDIT = `${PATH_ORGANIZATIONS_EVENT}/:id`;
export const PATH_ORGANIZATIONS_EVENT_VIEW = `${PATH_ORGANIZATIONS_EVENT}/:id/view`;

export const PATH_ORGANIZATIONS_EVENT_TYPE = `${PATH_ORGANIZATIONS}/event-type`;

export const PATH_PERSONNEL = `${PATH_FRONTPAGE}personnel`;
export const PATH_PERSONNEL_ACCESS_POLICY = `${PATH_PERSONNEL}/access-policy`;
export const PATH_PERSONNEL_EMPLOYEE = `${PATH_PERSONNEL}/employee`;
export const PATH_PERSONNEL_EMPLOYEE_ADD = `${PATH_PERSONNEL_EMPLOYEE}/add`;
export const PATH_PERSONNEL_EMPLOYEE_EDIT = `${PATH_PERSONNEL_EMPLOYEE}/:id`;
export const PATH_PERSONNEL_JOB_TITLE = `${PATH_PERSONNEL}/job-title`;

export const PATH_VEHICLES_FOLDER = `${PATH_FRONTPAGE}vehicles`;
export const PATH_VEHICLE_TYPE = `${PATH_VEHICLES_FOLDER}/vehicle-type`;
export const PATH_VEHICLE = `${PATH_VEHICLES_FOLDER}/vehicle`;
export const PATH_VEHICLE_BOOKING = `${PATH_VEHICLES_FOLDER}/booking`;

export const PATH_DOCUMENTS_FOLDER = `${PATH_FRONTPAGE}documents`;
export const PATH_DOCUMENTS_FILE_BUCKET_SCHEMA = `${PATH_DOCUMENTS_FOLDER}/file-bucket-schema`;
export const PATH_DOCUMENTS_DOCUMENTS = `${PATH_DOCUMENTS_FOLDER}/file-bucket`;

export const PATH_FRONTPAGE_TITLE = t('Главная');
export const PATH_USERS_STAFF_USER_ADD_TITLE = t('Добавить сотрудника');
export const PATH_USERS_STAFF_USER_EDIT_TITLE = t('Редактировать сотрудника');
export const PATH_TASK_CREATE_CHILD_TASK_TITLE = t('Создать подзадачу');

export const PATH_ORGANIZATIONS_COUNTERPARTY_ADD_TITLE = t(
  'Добавить контрагента'
);

export const PATH_ORGANIZATIONS_COUNTERPARTY_EDIT_TITLE = t(
  'Редактировать контрагента'
);
export const PATH_ORGANIZATIONS_EVENT_ADD_TITLE = t('Добавить событие');
export const PATH_ORGANIZATIONS_EVENT_EDIT_TITLE = t('Редактировать событие');

export const PATH_ORGANIZATIONS_ACCOUNTS_VIEW_TITLE = t('Лицевой счет');
export const PATH_ORGANIZATIONS_ACCOUNTS_ADD_TITLE = t('Добавить счет');
export const PATH_ORGANIZATIONS_ACCOUNTS_EDIT_TITLE = t('Редактировать счет');

export const PATH_ORGANIZATIONS_EVENT_TITLE = t('Событие');

export const PATH_TICKETS_TICKET_EDIT_TITLE = t('Редактировать задачу');
export const PATH_TICKETS_TICKET_EDIT_BACK_TITTLE = t('Задача №');
export const PATH_TICKETS_TASK_FROM_ORDER_TITTLE = t(
  'Создать задачу из заявки'
);

export const PATH_EXTERNAL_REQUEST_ADD_TITLE = t('Добавить заявку');
export const PATH_EXTERNAL_REQUEST_EDIT_TITLE = t('Редактировать заявку');
export const PATH_EXTERNAL_REQUEST_COPY_TITLE = t('Копировать заявку');
export const PATH_EXTERNAL_REQUEST_CREATE_CHILD_EXTERNAL_REQUEST_TITTLE =
  t('Создать подзаявку');
export const PATH_EXTERNAL_REQUEST_CREATE_FROM_TASK_TITTLE = t(
  'Создать заявку из задачи'
);

export const PATH_CONTROLLER_ADD_TITLE = t('Добавить контроллер');
export const PATH_CONTROLLER_EDIT_TITLE = t('Редактировать контроллер');

export const PATH_EXTERNAL_REQUEST_EDIT_BACK_TITTLE = t('Заявка №');

export const PATH_NOTIFICATION_TITLE = t('Уведомления');

export const TAB_QUERY_KEY = {
  account: 'billing-account-details',
  ticket: 'task',
  order: 'external-request',
  counterparty: 'counterparty',
  event: 'event',
  controller: 'controller',
} as const;

export const TAB_QUERY_PARAMS = {
  tasks: 'tasks',
  externalRequest: 'external-request',
  counterparty: 'counterparty',
  documents: 'documents',
  edit: 'edit',
  history: 'history',
  warehouse: 'warehouse',
  booking: 'booking',
  event: 'event',
  billingAccount: 'billing-account',
  controller: 'controller',
} as const;
