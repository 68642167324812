import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearExternalRequestFieldValue,
  setInitialExternalRequest,
} from 'store/externalRequest/actions';
//TODO Удалить после того как сделаем сохранения values в store по примеру задач
const useClearExternalRequestValues = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialExternalRequest({}));
    dispatch(clearExternalRequestFieldValue());
  }, [dispatch]);
};

export default useClearExternalRequestValues;
