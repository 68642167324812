import classNames from 'clsx';
import { FC, memo } from 'react';
import styles from './index.module.scss';

type PropsType = {
  text: string | number;
  handleClick: Function;
  classNameCustom?: string;
  classNameText?: string;
  disabled?: boolean;
};

const ButtonLink: FC<PropsType> = ({
  text,
  handleClick,
  classNameCustom,
  classNameText,
  disabled = false,
}) => (
  <button
    disabled={disabled}
    className={classNames(styles['button-link'], classNameCustom)}
    onClick={(e: any) => {
      e.preventDefault();
      e.stopPropagation();
      handleClick();
    }}
  >
    <span className={classNames(classNameText)}>{text}</span>
  </button>
);

export default memo(ButtonLink);
