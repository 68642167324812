import React, { FC, ReactNode } from 'react';
import THCustom, { THCustomPropsType, THType } from '../THCustom/THCustom';

interface THCustomWrapperProps {
  className?: string;
  isVisible?: boolean;
  children?: ReactNode;
}

type THCustomWrapperComponent = FC<THCustomWrapperProps>;

const THCustomWrapper: Record<THType, THCustomWrapperComponent> = {
  Unruled: ({ className, isVisible, children }: THCustomPropsType) => (
    <THCustom type={THType.unruled} className={className} isVisible={isVisible}>
      {children}
    </THCustom>
  ),
  Default: ({ className, isVisible, children }: THCustomPropsType) => (
    <THCustom type={THType.default} className={className} isVisible={isVisible}>
      {children}
    </THCustom>
  ),
  Select: ({ className, isVisible, children, disabled }: THCustomPropsType) => (
    <THCustom type={THType.select} className={className} isVisible={isVisible} disabled={disabled}>
      {children}
    </THCustom>
  ),
  Date: ({ className, isVisible, children }: THCustomPropsType) => (
    <THCustom type={THType.date} className={className} isVisible={isVisible}>
      {children}
    </THCustom>
  ),
};

export const THCustomUnruled: THCustomWrapperComponent =
  THCustomWrapper.Unruled;

export const THCustomDefault: THCustomWrapperComponent =
  THCustomWrapper.Default;

export const THCustomSelect: THCustomWrapperComponent = THCustomWrapper.Select;

export const THCustomDate: THCustomWrapperComponent = THCustomWrapper.Date;
