import { useCallback } from 'react';
import {
  getForSelectCounterpartyAffiliateApiV2,
  getForSelectCounterpartyApiV2,
} from '../../../../services/api/organizationsV2/counterparty/counterpartyApi';
import { useFormikContext } from 'formik';
import Task from 'models/Task';
import { IFormValuesTask } from '../TaskForm';

const getAsyncSelectKey = (isSelected: boolean) =>
  `${Number(isSelected)}-keyAsyncSelect`;

const useGetRequestForSelectCounterparty = () => {
  const {
    values: { isSelectedAffiliate },
    setFieldValue,
  } = useFormikContext<IFormValuesTask | Task>();

  const handlerAffiliateCheckbox = useCallback(() => {
    setFieldValue('isSelectedAffiliate', !isSelectedAffiliate);
  }, [isSelectedAffiliate, setFieldValue]);

  const getForselectCounterparty = isSelectedAffiliate
    ? getForSelectCounterpartyAffiliateApiV2
    : getForSelectCounterpartyApiV2;

  const keyAsyncSelect = getAsyncSelectKey(!!isSelectedAffiliate);

  return {
    handlerAffiliateCheckbox,
    getForselectCounterparty,
    keyAsyncSelect,
  };
};

export default useGetRequestForSelectCounterparty;
