import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';
import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import deleteUnnecessaryParameters from '../../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import { makeFirstLetterUppercase } from 'services/utils/stringHelper/stringHelper';
import { BILLING_ACCOUNT_TEXT } from 'services/localLocalization/servicesAndModels';
import { IS_BILLING_ACCOUNT_CLOSED_KEY } from 'services/constants/IS_BILLING_ACCOUNT_CLOSED_KEY';

class TaskValuePreparer {
  private values: IFormValuesTask;
  static readonly namesOfpropertiesToRemove: (keyof IFormValuesTask)[] = [
    'internal_task_comment_text',
    'isSelectedAffiliate',
    IS_BILLING_ACCOUNT_CLOSED_KEY,
  ];
  constructor(currentValues: IFormValuesTask) {
    this.values = currentValues;
  }

  private static normalizePhoneNumber(
    phoneNumber?: string
  ): string | undefined {
    return phoneNumber ? convertToServerPhoneNumber(phoneNumber) : undefined;
  }

  // У нас может отличаться title account и для этого мы сравниваем value и в этом случае мы должны сделать единообразным account

  private updateAccountField(
    newAccountData?: IClientSelectOptionV2
  ): IClientSelectOptionV2 | undefined {
    const currentAccount = this.values?.account;

    if (!currentAccount) {
      return undefined;
    }
    if (!newAccountData) {
      return currentAccount;
    }

    return newAccountData.value === currentAccount.value &&
      newAccountData.label !== currentAccount.label
      ? createClientSelectOptionV2(
          currentAccount.value,
          makeFirstLetterUppercase(BILLING_ACCOUNT_TEXT)
        )
      : currentAccount;
  }

  private deleteFields = (values: IFormValuesTask) => {
    TaskValuePreparer.namesOfpropertiesToRemove.forEach((property) => {
      delete values[property];
    });
  };

  /**
   * Подготавливает значения задачи для сравнения.
   */
  public prepareForComparison(accountNewValues?: IClientSelectOptionV2) {
    const preparedValues: IFormValuesTask = {
      ...this.values,
      internal_task_reporter_phone_number:
        TaskValuePreparer.normalizePhoneNumber(
          this.values?.internal_task_reporter_phone_number
        ),
      account: this.updateAccountField(accountNewValues),
    };

    this.deleteFields(preparedValues);
    this.values = preparedValues;
    return this;
  }

  public removeUndefinedValues = () => {
    this.values = deleteUnnecessaryParameters(this.values);
    return this;
  };

  public getPrepareValues = () => this.values;
}

export default TaskValuePreparer;
