import { t } from './services/utils/translation';

const environment: string = process.env.NODE_ENV || '';

export const isDevEnv = () => environment === 'development';

export const isDev = () =>   isDevEnv() || window.location.host.indexOf('dev') !== -1;


export const ACCOUNT_LEGAL_STATUS_INDIVIDUAL = 'Individual';
export const ACCOUNT_LEGAL_STATUS_COMPANY = 'Company';
export const PROMPT_MESSAGE  = t(
  'Есть несохраненные данные формы. Вы действительно хотите покинуть страницу?'
);

export const navigationBlockPaths =  ["edit"]

export const enum CODE_SIZES {
xs = 'xs',
sm = 'sm',
md  = 'md',
lg  = 'lg'
}
