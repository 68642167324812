import { IFormValuesTask } from 'components/tasks/TaskForm/TaskForm';
import TaskValuePreparer from './TaskValuePreparer';
import { isEqual } from 'lodash';
//!!!FOR TESTS
// type Difference<T> = {
//   [K in keyof T]?: { initial: T[K]; current: T[K] };
// };

// function deepEqual(value1: any, value2: any): boolean {
//   if (value1 === value2) return true;

//   if (
//     typeof value1 === 'object' &&
//     typeof value2 === 'object' &&
//     value1 &&
//     value2
//   ) {
//     const keys1 = Object.keys(value1);
//     const keys2 = Object.keys(value2);

//     if (keys1.length !== keys2.length) return false;

//     for (const key of keys1) {
//       if (!keys2.includes(key) || !deepEqual(value1[key], value2[key])) {
//         return false;
//       }
//     }
//     return true;
//   }

//   return false;
// }

// export function getDifferentProperties<T extends Record<string, any>>(
//   initial: T,
//   current: T
// ): Difference<T> {
//   const result: Difference<T> = {};

//   for (const key in initial) {
//     if (
//       initial.hasOwnProperty(key) &&
//       (!deepEqual(initial[key], current[key]) ||
//         initial[key] === undefined ||
//         current[key] === undefined)
//     ) {
//       result[key] = { initial: initial[key], current: current[key] };
//     }
//   }

//   // Дополнительно проверяем наличие ключей в obj2, если их нет в obj1
//   for (const key in current) {
//     if (
//       current.hasOwnProperty(key) &&
//       (!(key in initial) ||
//         initial[key] === undefined ||
//         current[key] === undefined) &&
//       !deepEqual(initial[key], current[key])
//     ) {
//       result[key] = { initial: initial[key], current: current[key] };
//     }
//   }

//   return result;
// }

const isInitialValueTaskAndCurrentValueEqual = (
  initial?: IFormValuesTask | null,
  current?: IFormValuesTask | null
): boolean => {
  if (!initial || !current) {
    return false;
  }
  const prpareInitialValues =
    initial &&
    new TaskValuePreparer(initial)
      .prepareForComparison(current?.account)
      .removeUndefinedValues()
      .getPrepareValues();

  const prpareCurrentValues =
    current &&
    new TaskValuePreparer(current)
      .prepareForComparison(initial?.account)
      .removeUndefinedValues()
      .getPrepareValues();

  return isEqual(prpareInitialValues, prpareCurrentValues);
};

export default isInitialValueTaskAndCurrentValueEqual;
