import classNames from 'clsx';
import React, { ChangeEventHandler, FC, FocusEventHandler } from 'react';
import {
  FormGroup,
  Input,
  InputGroup,
  Label,
  InputGroupAddon,
  Button,
} from 'reactstrap';
import TipButton from '../TipButton';
import { ChangeEvent } from 'react';
import { IButtonAddon } from '../../../services/interfaces/IButtonAddon';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';

type TypeProps = {
  id: string;
  label?: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  onChange?: ChangeEventHandler;
  hasError?: boolean;
  type?: 'text' | 'email' | 'textarea' | 'number';
  required?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  autoComplete?: string;
  helpText?: string;
  disabled?: boolean;
  className?: string;
  setName?: Function;
  style?: any;
  buttonAddon?: IButtonAddon;
  highlightEditing?: boolean;
};

const TextField: FC<TypeProps> = (props) => {
  const {
    id,
    label,
    placeholder,
    onBlur,
    onChange,
    hasError = false,
    type = 'text',
    required = false,
    defaultValue,
    autoComplete,
    value,
    helpText,
    disabled = false,
    className,
    setName,
    style,
    buttonAddon,
    highlightEditing = false,
  } = props;

  const autoCompleteProps = { autoComplete };
  const valueProp = value ? { value } : {};

  return (
    <FormGroup className={required ? 'required' : ''} name={id}>
      {(label || helpText) && (
        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center">
          {label && (
            <Label className="control-label" for={id}>
              {label}
            </Label>
          )}
          {helpText && <TipButton id={id} helpText={helpText} />}
        </div>
      )}
      <ErrorWrapperField id={id}>
        <InputGroup
          className={classNames('flex-nowrap', { 'is-invalid': hasError })}
        >
          <Input
            placeholder={placeholder ? placeholder : label}
            type={type}
            name={id}
            id={id}
            onBlur={onBlur}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange && onChange(e);
              setName && setName(e.target.value);
            }}
            className={classNames(className, {
              'is-invalid': hasError,
              borderBackLight: highlightEditing && !hasError,
            })}
            required={required}
            {...autoCompleteProps}
            onKeyUp={(e: any) => {
              // We have to manually handle Backspace for textarea fields.
              if (
                type === 'textarea' &&
                e.key === 'Backspace' &&
                onChange != null
              ) {
                onChange(e);
              }
            }}
            disabled={disabled}
            style={{
              ...style,
            }}
            value={defaultValue}
            {...valueProp}
          />
          {buttonAddon && (
            <InputGroupAddon addonType="prepend">
              <Button
                color="primary"
                onClick={() => buttonAddon.handleClickAddon()}
                className={classNames(buttonAddon.classNameButton)}
                disabled={buttonAddon.disabled}
              >
                {buttonAddon.textButton}
              </Button>
            </InputGroupAddon>
          )}
        </InputGroup>
      </ErrorWrapperField>
    </FormGroup>
  );
};

export default TextField;
