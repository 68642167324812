import { CHANGE_INSTALLATION, IDENTITY_LOGOUT } from './actionTypes';
import { appReducer } from './appReducer';
import { initialState } from '../initialStore/InitialState';

const rootReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case IDENTITY_LOGOUT: {
      return initialState;
    }
    case CHANGE_INSTALLATION:
      return {
        ...initialState,
        auth: { ...state.auth },
        notificationsCount: { ...state.notificationsCount },
      };

    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
