import classNames from 'clsx';
import { useField } from 'formik';
import React, { FC, memo, useState, FocusEventHandler } from 'react';
import { ValueType } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import formatOptionLabelHighLighter from '../utils/formatOptionLabelHighLighter';
import shouldLoadMore from '../utils/shouldLoadMore';
import useLoadOptionsInForSelect from '../../../hooks/useLoadOptionsInForSelect/useLoadOptionsInForSelect';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { IForSelectResponseV2 } from './IFroSelectOptionV2';
import ErrorWrapperField from '../ErrorWrapperField/ErrorWrapperField';
import { getValueForSelect } from 'services/utils/selects/getValueForSelect/getValueForSelect';
import { createTooltipId } from 'services/utils/createId/createId';

type TypeProps = {
  id: string;
  placeholder?: string;
  className: string;
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  isMulti?: boolean;
  options?: IClientSelectOptionV2[] | IClientSelectOptionV2 | null;
  'aria-labelledby': string;
  disabled?: boolean;
  onChange?: Function;
  defaultValue?: string;
  isClearable?: boolean;
  onBlur?: FocusEventHandler;
  clearLoadedOptions?: boolean;
  keyAsyncSelect?: string;
  haveReadPermission?: boolean;
};

const DynamicSelectFormikPaginatedV2: FC<TypeProps> = (props) => {
  const {
    id,
    placeholder,
    selectHandler,
    isMulti = false,
    options,
    className,
    'aria-labelledby': ariaLabelledBy,
    disabled = false,
    defaultValue,
    isClearable = true,
    onBlur,
    keyAsyncSelect,
  } = props;

  //TODO: refactoring use func
  let optionsArray: IClientSelectOptionV2[];
  if (options == null) {
    optionsArray = [];
  } else if (!Array.isArray(options)) {
    optionsArray = [options];
  } else {
    optionsArray = options;
  }

  const onChangeHandler = props.onChange;

  const [, , helpers] = useField<
    IClientSelectOptionV2 | IClientSelectOptionV2[] | null
  >(id);
  const [loadedOptions, setLoadedOptions] =
    useState<IClientSelectOptionV2[]>(optionsArray);

  const loadOptions = useLoadOptionsInForSelect({
    selectHandler,
    setLoadedOptions,
    loadedOptions,
  });

  const onChange = async (
    option: ValueType<IClientSelectOptionV2 | IClientSelectOptionV2[], boolean>
  ) => {
    if (option == null) {
      await helpers.setValue(isMulti ? [] : null);
    } else {
      await helpers.setValue(
        isMulti
          ? (option as IClientSelectOptionV2[])
          : (option as IClientSelectOptionV2)
      );
    }
    if (onChangeHandler != null) {
      onChangeHandler(option);
    }
  };
  const value = getValueForSelect(
    loadedOptions,
    optionsArray,
    isMulti,
    defaultValue
  );
  const selectStyles = {
    menu: (styles: any) => ({
      ...styles,
      zIndex: 999,
    }),
    multiValueLabel: (styles: any) => ({ ...styles, background: '#D6F1F7' }),
    multiValueRemove: (styles: any) => ({ ...styles, background: '#D6F1F7' }),
    control: (styles: any, state: any) => ({
      ...styles,
      '&:hover': { borderColor: '#ACB5C4' },
      border: state.isFocused && '1px solid #49A6C8',
      boxShadow: state.isFocused ? '1' : 'none',
    }),
    option: (styles: any) => ({
      ...styles,
      '&:hover': { background: '#D6F1F7' },
    }),
  };

  const tooltipId = createTooltipId(id) as string;

  return (
    <div id={tooltipId}>
      <ErrorWrapperField id={id}>
        <AsyncPaginate
          key={keyAsyncSelect}
          cacheOptions
          shouldLoadMore={shouldLoadMore}
          loadOptions={loadOptions}
          id={id}
          name={id}
          aria-labelledby={ariaLabelledBy}
          placeholder={placeholder}
          className={classNames('f-custom-select', className)}
          isMulti={isMulti}
          onChange={onChange}
          value={value}
          additional={{
            page: 1,
          }}
          styles={selectStyles}
          isDisabled={disabled}
          debounceTimeout={300}
          isClearable={isClearable}
          onBlur={onBlur}
          formatOptionLabel={formatOptionLabelHighLighter}
        />
      </ErrorWrapperField>
    </div>
  );
};

export default memo(DynamicSelectFormikPaginatedV2);
