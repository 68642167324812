import { t } from '../../../services/utils/translation';
export const COLUMN_LABELS_DOCUMENT = {
  PK: t('№'),
  NAME: t('Название'),
  DATE_CREATE: t('Создан'),
  DOCUMENTS: t('Файлы'),
  SENT_TO_ARCHIVE: t('Отправлено в архив'),
  RECIVED_TO_ARCHIVE: t('Принято в архив'),
};

export const filtersDocument = [
  { name: 'pk', label: COLUMN_LABELS_DOCUMENT.PK },
  { name: 'name', label: COLUMN_LABELS_DOCUMENT.NAME },
  { name: 'date_create', label: COLUMN_LABELS_DOCUMENT.DATE_CREATE },
  { name: 'documents', label: COLUMN_LABELS_DOCUMENT.DOCUMENTS },
  { name: 'sentToArchive', label: COLUMN_LABELS_DOCUMENT.SENT_TO_ARCHIVE },
  {
    name: 'receivedToArchive',
    label: COLUMN_LABELS_DOCUMENT.RECIVED_TO_ARCHIVE,
  },
] as const;

export const documentColumnVisibilityInitial = {
  pk: true,
  name: true,
  date_create: true,
  documents: true,
  sentToArchive: true,
  receivedToArchive: true,
};
