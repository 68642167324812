import classNames from 'clsx';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import { FC, memo } from 'react';
import { CustomInput } from 'reactstrap';

type PropsType = {
  id?: number | string;
  hint?: string;
  isCheck: boolean;
  className?: string;
};

const TDCheckBox: FC<PropsType> = (props) => {
  const { hint, id, isCheck } = props;

  return (
    <div
      id={`check_${id || ''}`}
      className={classNames(
        'd-flex align-content-center justify-content-center'
      )}
    >
      <CustomInput
        id={`check_${id || ''}`}
        type="checkbox"
        style={{ width: '46px' }}
        checked={isCheck}
        disabled
      />
      {hint && id && (
        <ToolTipWrapper target={`check_${id || ''}`} placement="top">
          {hint}
        </ToolTipWrapper>
      )}
    </div>
  );
};

export default memo(TDCheckBox);
