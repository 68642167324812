import { useCallback } from 'react';
import onDownloadAll from '../utils/onDownloadAll';

const useDownloadAll = () => {
  return useCallback(async (fileNames: string[]) => {
    onDownloadAll(fileNames);
  }, []);
};

export default useDownloadAll;
