import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';

const END_KEY_NAME = 'ColumnFilter';
interface IColumnVisibility {
  [key: string]: boolean;
}

export type ColumnVisibility<T> = {
  [K in keyof T]: boolean;
};

interface IUseColumnFilter<T> {
  visibility: T;
  onFilterItemToggle: (e: ChangeEvent) => void;
  applyVisibilityChanges: () => void;
}

export const useColumnFilter = <
  T extends ColumnVisibility<T> & IColumnVisibility = IColumnVisibility
>(
  columnVisibilityInitial: T,
  keyNameInLocalStorage?: string
): IUseColumnFilter<ColumnVisibility<T>> => {
  const [shadowVisibility, setShadowVisibility] =
    useState<ColumnVisibility<T> | null>(null);

  const locationArray = useLocation().pathname.split('/');

  const finallyLocation = locationArray[locationArray.length - 1];

  const [visibility, setStoredValue] = useLocalStorage<ColumnVisibility<T>>({
    key: `${keyNameInLocalStorage || finallyLocation}${END_KEY_NAME}`,
    initialValue: columnVisibilityInitial,
  });

  const onFilterItemToggle = useCallback((e: ChangeEvent): void => {
    const target = e.target.id;
    setShadowVisibility((prev: ColumnVisibility<T> | null) => {
      if (prev !== null) {
        return {
          ...prev,
          [target]: !prev[target],
        };
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    setShadowVisibility(visibility);
  }, [visibility]);

  const applyVisibilityChanges = useCallback((): void => {
    if (shadowVisibility !== null) {
      setStoredValue(shadowVisibility);
    }
  }, [setStoredValue, shadowVisibility]);

  return { visibility, onFilterItemToggle, applyVisibilityChanges };
};
