import { IExternalRequestStatusOld } from 'services/api/external-request/external-request/IExternalRequestStatusOld';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import { createClientSelectOptionV2 } from 'services/utils/selects/selects';

export const getAvailableStatuses = (
  nextStatuses?: IExternalRequestStatusOld[]
) => {
  if (!nextStatuses) {
    return [];
  } else {
    return nextStatuses.reduce(
      (acc: IClientSelectOptionV2[], cur: IExternalRequestStatusOld) => {
        if (cur.is_hidden) {
          return acc;
        }
        acc.push(createClientSelectOptionV2(cur.id, cur.name));
        return acc;
      },
      []
    );
  }
};
