import MainLayout from 'components/layouts/MainLayout/MainLayout';
import Subheader from 'components/layouts/Subheader/Subheader';
import React, { FC, memo, PropsWithChildren, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  readExternalRequestV2,
  getHistoryExternalRequestV2,
} from '../../../../services/api/external-request/external-request/externalRequestApi';

import ExternalRequestHeader from 'components/external-request/ExternalRequestHeader/ExternalRequestHeader';
import DocumentsPage from 'pages/documents/DocumentsPage/DocumentsPage';
import IWrapperExternalRequest from '../../../../services/api/external-request/external-request/IWrapperExternalRequest';
import toFormValuesExternalRequest from './utils/toFormValuesExternalRequest/toFormValuesExternalRequest';
import { getHasUnsavedValuesFromStore } from '../../../../store/form/selectors';
import usePreventReload from 'hooks/usePreventReload';
import {
  blockNavigationForm,
  setValueDifference,
} from '../../../../store/form/actions';
import { IHistoryExternalRequestPayload } from '../../../../services/api/external-request/external-request/IHistoryExternalRequest';
import {
  saveExternalRequestFieldValue,
  setInitialExternalRequest,
} from '../../../../store/externalRequest/actions';
import ExternalRequestHistory from 'components/external-request/ExternalRequesHistory/ExternalRequesHistory';
import useGetRefreshingData from '../../../../hooks/useGetRefreshingData';
import { IExternalRequestStatusOld } from '../../../../services/api/external-request/external-request/IExternalRequestStatusOld';
import ExternalRequestWarehouse from 'components/external-request/ExternalRequestWarehouse/ExternalRequestWarehouse';
import { clearExternalRequestFieldValue } from '../../../../store/externalRequest/actions';
import { createClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import useGetQueryParameter from 'hooks/useGetQueryParameter';
import { TAB_QUERY_KEY, TAB_QUERY_PARAMS } from 'services/pathConstants';
import PagerWithParameterSwitch from 'components/misc/Pagers/PagerWithParameterSwitch/PagerWithParameterSwitch';
import BookingPage from 'pages/vehicle/booking/BookingPage';
import { BookingForm } from 'components/vehicle/BookingForm/BookingForm';
import useGetExternalRequestTabs from 'hooks/useGetExternalRequestTabs';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { targetAccessPolicyExternalRequest } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyExternalRequest';
import ExternalRequestForm from 'components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import { parentUIElement } from 'services/constants/parentUIElement';
import { IMenuItemPagerV2 } from '../../../../services/interfaces/IMenuItemPagerV2';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';

const haveBooking = (tabs: IMenuItemPagerV2[]) =>
  !!tabs.find((tab: IMenuItemPagerV2) => tab.name === 'Механизация')
    ?.recordsTotal;

const extractConvertExternalRequest =
  (isDuplicate?: boolean, isChildrenExternalRequest?: boolean) =>
  (data: IWrapperExternalRequest) =>
    toFormValuesExternalRequest(
      data?.external_request,
      isDuplicate,
      isChildrenExternalRequest
    );

type ExternalRequestPagePropsType = {
  isCreateChild?: boolean;
  isDuplicate?: boolean;
};

type PropsType = PropsWithChildren<ExternalRequestPagePropsType>;

const convertHistoryData = (data: IHistoryExternalRequestPayload) => ({
  rows: data?.external_request_list_of_edits,
  creationInfo: {
    internal_task_datetime_added: data?.internal_task_datetime_added,
    internal_task_author_dk: data?.internal_task_author_dk,
    internal_task_author: data?.internal_task_author,
  },
});

export const convertStatuses = (payload: {
  list_of_entities?: IExternalRequestStatusOld[];
}) => {
  if (!payload?.list_of_entities) {
    return [];
  }
  return payload.list_of_entities
    .filter(
      (status: IExternalRequestStatusOld) =>
        status.can_be_first && !status.is_hidden
    )
    .map((availableStatus: IExternalRequestStatusOld) =>
      createClientSelectOptionV2(availableStatus.id, availableStatus.name)
    );
};

const ExternalRequestEditPage: FC<PropsType> = (props) => {
  const { isCreateChild = false, isDuplicate = false } = props;

  const externalRequestPk = Number(useGetIdFromPath());

  const isNew = !externalRequestPk;

  const [haveAccessToUpdate, haveAccessToCreate] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyExternalRequest,
    targetAction: ['U', 'C'],
  });

  const dispatch = useDispatch();

  const initialParams = useMemo(
    () => ({ pk: externalRequestPk }),
    [externalRequestPk]
  );

  const {
    data: historyData,
    refreshData: refreshHistory,
    isLoading: isLoadingHistory,
  } = useGetRefreshingData({
    getDataApi: getHistoryExternalRequestV2,
    initialData: null,
    params: initialParams,
    condition: !isNew,
    convertData: convertHistoryData,
  });

  const convertExternalRequest = extractConvertExternalRequest(
    isDuplicate,
    isCreateChild
  );

  const {
    data: externalRequest,
    isLoading: isLoadingExternalRequest,
    refreshData: refreshExternalRequest,
  } = useGetRefreshingData({
    getDataApi: readExternalRequestV2,
    initialData: null,
    params: initialParams,
    condition: !isNew,
    convertData: convertExternalRequest,
  });

  const [tabs, refreshTabs, isLoadingCountTabs] = useGetExternalRequestTabs({
    id: externalRequestPk,
    warehouseCount: externalRequest?.invoice?.length,
  });

  const showPrompt = useSelector(getHasUnsavedValuesFromStore);

  usePreventReload(showPrompt);



  useEffect(() => {
    if (externalRequest && externalRequestPk) {
      dispatch(saveExternalRequestFieldValue(externalRequest));
      dispatch(setInitialExternalRequest(externalRequest));
    }
    return () => {
      dispatch(setInitialExternalRequest({}));
      dispatch(clearExternalRequestFieldValue());
      dispatch(blockNavigationForm(false));
      dispatch(setValueDifference([]));
    };
  }, [dispatch, externalRequest, externalRequestPk]);

  const isViewOnly =
    !haveAccessToUpdate ||
    (externalRequest?.isPruned && !isCreateChild && !isDuplicate);
  const isHaveBooking = haveBooking(tabs);

  const CONTENT_MAP = {
    [TAB_QUERY_PARAMS.edit]: (
      <ExternalRequestForm
        externalRequestPk={externalRequestPk}
        isLoadingExternalRequest={isLoadingExternalRequest}
        isDuplicate={isDuplicate || isCreateChild}
        refreshHistory={refreshHistory}
        refreshExternalRequest={refreshExternalRequest}
        isViewOnly={isViewOnly}
      />
    ),
    [TAB_QUERY_PARAMS.warehouse]: (
      <ExternalRequestWarehouse
        isViewOnly={isViewOnly}
        externalRequestPk={externalRequestPk}
        externalRequestInvoice={externalRequest?.invoice}
      />
    ),
    [TAB_QUERY_PARAMS.history]: (
      <ExternalRequestHistory
        externalRequestHistory={historyData?.rows}
        creationExternalRequestInfo={historyData?.creationInfo}
        isLoading={isLoadingHistory}
      />
    ),
    [TAB_QUERY_PARAMS.documents]: (
      <DocumentsPage
        parentUIName={parentUIElement.externalRequest}
        parentUIPk={externalRequestPk}
        refreshTabs={refreshTabs}
        viewOnly={isViewOnly}
      />
    ),
    [TAB_QUERY_PARAMS.booking]: isHaveBooking ? (
      <BookingPage
        externalRequest={createClientSelectOptionV2(
          externalRequestPk,
          externalRequest?.external_request_description
        )}
        refreshTabs={refreshTabs}
        isViewOnly={isViewOnly}
        isHaveBooking={isHaveBooking}
      />
    ) : (
      <BookingForm
        successHandler={refreshTabs}
        isViewOnly={isViewOnly}
        externalRequestPk={externalRequestPk}
      />
    ),
  } as const;

  const targetTab =
    (useGetQueryParameter(TAB_QUERY_KEY.order) as keyof typeof CONTENT_MAP) ||
    TAB_QUERY_PARAMS.edit;

  const ContentForRender = CONTENT_MAP[targetTab];

  return (
    <MainLayout>
      <Subheader />
      <ExternalRequestHeader
        externalRequestPk={externalRequestPk}
        externalRequest={externalRequest}
        refreshTabs={refreshTabs}
        isDuplicate={isDuplicate}
        isCreateChild={isCreateChild}
        haveAccessToCreate={haveAccessToCreate}
        refreshForm={refreshExternalRequest}
      />

      {!!externalRequestPk && !isDuplicate && !isNew && (
        <PagerWithParameterSwitch
          items={tabs}
          targetTab={targetTab}
          isLoadingCountTabs={isLoadingCountTabs}
        />
      )}
      {ContentForRender}
    </MainLayout>
  );
};

// ExternalRequestEditPage.whyDidYouRender = true;

export default memo(ExternalRequestEditPage);
