import useGetExternalRequestAccessPolicy from './useGetExternalRequestAccessPolicy';


const useSetAndGetExternalRequestAccessPolicy = () => {
  // const externalRequestAccessPolicyStore = useSelector(
  //   getExternalRequestAccessPolicyFromStore
  // );

  // const isInstalled = useSelector(
  //   getExternalRequestIsInstallAccessPolicyFromStore
  // );
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!isInstalled && externalRequestAccessPolicy) {
  //     dispatch(setExternalRequestAccessPolicy(externalRequestAccessPolicy));
  //   }
  // }, [
  //   dispatch,
  //   externalRequestAccessPolicyStore,
  //   externalRequestAccessPolicy,
  //   isInstalled,
  // ]);

  return useGetExternalRequestAccessPolicy(false);
};

export default useSetAndGetExternalRequestAccessPolicy;
