import convertToServerPhoneNumber from 'services/utils/stringHelper/convertToServerPnoneNumber/convertToServerPnoneNumber';
import { IExternalRequest } from '../../../../../../services/api/external-request/external-request/IExternalRequest';
import { IPersonV2 } from '../../../../../../services/interfaces/IPersonV2';
import {
  toTitleBillingAccount,
  extractClientSelectOptionsFromCounterpartyInEntity,
} from '../../../../../../services/utils/bilingAccountUtils/billingAccountUtils';
import { createClientSelectOptionV2 } from '../../../../../../services/utils/selects/selects';
import { IFormValuesExternalRequest } from '../../../../../../components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import {
  formatDate,
  parseDateFromServer,
} from '../../../../../../services/utils/dateHelper/dateHelper';
import { EVENT_STATE } from 'components/external-request/ExternalRequestForm/ExternalRequestSecondSection/constants';
import {
  EXTERNAL_REQUEST_NO_PERFORMERS,
  EXTERNAL_REQUEST_SET_PERFORMERS,
} from '../consts';
import { getExternalParentFk } from '../getExternalParentFk/getExternalParentFk';
import { getParenTaskFk } from '../getParenTaskFk/getParenTaskFk';
import { getAvailableStatuses } from '../getAvailableStatuses/getAvailableStatuses';

const toFormValuesExternalRequest = (
  externalRequest: IExternalRequest,
  isDuplicate?: boolean,
  isChildrenExternalRequest?: boolean
): IFormValuesExternalRequest => {
  const {
    external_request_billing_account: billingAccount,
    external_request_external_code_number: codeNumber,
    external_request_pk: pk,
    external_request_address_of_incident: address,
    external_request_description,
    external_request_work_category: workCategory,
    external_request_reporter_full_name,
    external_request_reporter_phone_number: reporterPhoneNumber,
    external_request_source_of_information,
    external_request_author: author,
    external_request_date_supposed_duedate: duedate,
    external_request_executive_implementer: executiveImplementer,
    external_request_payment_status,
    external_request_payment_amount,
    external_request_payment_kind,
    external_request_status: status,
    external_request_warehouse_note,
    external_request_responsible_department: responsibleDepartment,
    external_request_list_of_implementers: implementers,
    external_request_parent_external_request_fk: parentFk,
    external_request_parent_internal_task_fk: parentTaskFk,
    external_request_supervisor: supervisor,
    external_request_datetime_of_actual_commencement,
    external_request_datetime_of_actual_completion,
    external_request_list_of_child_external_requests,
    external_request_list_of_child_internal_tasks,
    external_request_affiliate: affiliate,
    external_request_list_of_invoices: invoice,
    external_request_is_pruned,
    external_request_event: event,
  } = externalRequest;

  const hasPerformers =
    !!executiveImplementer || (implementers && implementers?.length > 0);

  const hasEvent = !!event?.event_pk;

  const availableStatuses = getAvailableStatuses(
    externalRequest?.external_request_status?.next_statuses
  );
  return {
    availableStatuses,
    billingAccount:
      billingAccount &&
      createClientSelectOptionV2(
        billingAccount?.billing_account_pk,
        toTitleBillingAccount(billingAccount)
      ),
    pk: !isDuplicate && !isChildrenExternalRequest ? pk : undefined,
    external_request_parent_external_request_fk: getExternalParentFk({
      parentFk,
      isDuplicate,
      isChildrenExternalRequest,
      pk,
    }),
    external_request_parent_internal_task_fk: getParenTaskFk({
      parentTaskFk,
      isDuplicate,
      isChildrenExternalRequest,
    }),
    external_request_datetime_of_actual_commencement:
      !isDuplicate && !isChildrenExternalRequest
        ? external_request_datetime_of_actual_commencement
        : undefined,
    external_request_datetime_of_actual_completion:
      !isDuplicate && !isChildrenExternalRequest
        ? external_request_datetime_of_actual_completion
        : undefined,

    external_request_external_code_number: codeNumber,
    external_request_address_of_incident: address ? address : '',
    external_request_description,
    workCategory:
      workCategory &&
      createClientSelectOptionV2(
        workCategory?.work_category_pk,
        workCategory?.work_category_rendition
      ),
    // external_request_work_category_fk?: number;
    external_request_reporter_full_name,
    external_request_reporter_phone_number: reporterPhoneNumber
      ? convertToServerPhoneNumber(reporterPhoneNumber + '')
      : undefined,
    external_request_source_of_information,
    // author: createClientSelectOptionV2(author.id, author.full_name),
    author: createClientSelectOptionV2(author.id, author.full_name),
    //  responsible_engineer?: IClientSelectOptionV2;
    //  responsible_engineer_id?: number; нет такого свойства Ответственный руководитель
    external_request_date_supposed_duedate:
      !isDuplicate && !isChildrenExternalRequest && duedate
        ? formatDate(parseDateFromServer(duedate))
        : undefined,

    supervisor:
      supervisor &&
      createClientSelectOptionV2(supervisor?.id, supervisor?.full_name),
    executiveImplementer: executiveImplementer
      ? createClientSelectOptionV2(
          executiveImplementer.id,
          executiveImplementer.full_name
        )
      : undefined,
    implementers: implementers?.map((implementer: IPersonV2) =>
      createClientSelectOptionV2(implementer.id, implementer.full_name)
    ),
    // external_request_executive_implementer_dk?: number;
    external_request_payment_status,
    external_request_payment_amount,
    external_request_payment_kind,
    // status: isDuplicate ? initialStatus : undefined,
    currentStatus:
      !isDuplicate && !isChildrenExternalRequest ? status : undefined,
    responsibleDepartment: createClientSelectOptionV2(
      responsibleDepartment?.responsible_department_pk,
      responsibleDepartment?.responsible_department_rendition
    ),
    // external_request_responsible_department_fk?: number;
    external_request_warehouse_note:
      !isDuplicate && !isChildrenExternalRequest
        ? external_request_warehouse_note
        : undefined,

    performerType: hasPerformers
      ? EXTERNAL_REQUEST_SET_PERFORMERS
      : EXTERNAL_REQUEST_NO_PERFORMERS,
    //
    // availableStatuses,
    childExternalRequests: external_request_list_of_child_external_requests,
    childInternalTasks: external_request_list_of_child_internal_tasks,
    isHaveMechanization: false,
    affiliate: extractClientSelectOptionsFromCounterpartyInEntity(affiliate),
    invoice,
    isPruned: external_request_is_pruned,
    event: createClientSelectOptionV2(event?.event_pk, event?.event_name),
    eventState: hasEvent ? EVENT_STATE.enabled_event : EVENT_STATE.unable_event,
  };
};

export default toFormValuesExternalRequest;
