import useGetTaskAccessPolicy from '../useGetTaskAccessPolicy/useGetTaskAccessPolicy';

const useSetAndGetTaskAccessPolicy = () => {
  //const taskAccesPolicyStore = useSelector(getTaskAccessPolicyFromStore);

  //const isInstalled = useSelector(getTaskIsInstallAccessPolicyFromStore);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!isInstalled) {
  //     dispatch(setTaskAccessPolicy(taskAccessPolicy));
  //   }
  // }, [dispatch, taskAccessPolicyStore, taskAccessPolicy, isInstalled]);

  return useGetTaskAccessPolicy(false);
};

export default useSetAndGetTaskAccessPolicy;
