import { IFormValuesExternalRequest } from 'components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import { initialExternalRequestFilters } from './initialExternalRequestFilters';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';

export interface IExternalRequestClientAccessPolicy {
  employeeHaveAccessToRead: boolean;
  billingAccountHaveAccessToRead: boolean;
  responsibleDepartmentHaveAccessToRead: boolean;
  workCategoryHaveAccessToRead: boolean;
  externalRequest: {
    haveAccessToCreate: boolean;
    haveAccessToExport: boolean;
    haveAccessToArchive: boolean;
    haveAccessToUpdate: boolean;
  };
  eventHaveAccessToRead: boolean;
}

interface IExternalRequestClientAccessPolicyStore {
  isInstalled: boolean;
  accessPolicy: IExternalRequestClientAccessPolicy;
}

export interface IInitialExternalRequestStore {
  tableFilters: IExternalRequestFilters;
  marked: number[];
  fieldValues: IFormValuesExternalRequest;
  initialValues: IFormValuesExternalRequest;
  firstStatus: undefined;
  externalRequestAccessPolicy: IExternalRequestClientAccessPolicyStore;
}

const initialAccessPolicy: IExternalRequestClientAccessPolicyStore = {
  isInstalled: false,
  accessPolicy: {
    employeeHaveAccessToRead: false,
    billingAccountHaveAccessToRead: false,
    responsibleDepartmentHaveAccessToRead: false,
    workCategoryHaveAccessToRead: false,
    externalRequest: {
      haveAccessToCreate: false,
      haveAccessToExport: false,
      haveAccessToArchive: false,
      haveAccessToUpdate: false,
    },
    eventHaveAccessToRead: false,
  },
};

export const initialExternalRequestStore: IInitialExternalRequestStore = {
  tableFilters: initialExternalRequestFilters,
  marked: [],
  fieldValues: {},
  initialValues: {},
  firstStatus: undefined,
  externalRequestAccessPolicy: initialAccessPolicy,
};
