import { IUseFetchV2, useGetListOld } from 'hooks/useGetListOld';
import { IDocumentsPaginationParams } from 'services/api/documents/file-bucket/IFiltersDocuments';
import { IGetResponseFileBuckets } from 'services/api/documents/file-bucket/IGetResponseFileBuckets';
import { useMemo, useEffect } from 'react';
import useGetPrevValue from 'hooks/useGetPrevValue';
import { parentUIElement } from 'services/constants/parentUIElement';
import { getFileBuckets } from 'services/api/documents/file-bucket/fileBucketApi';

interface IUseGetTotalDocumentsProps<Id = number> {
  parentUIElement: parentUIElement;
  id: Id;
}

const useGetTotalDocuments = <Id extends number | string>({
  parentUIElement,
  id,
}: IUseGetTotalDocumentsProps<Id>) => {
  const prevId = useGetPrevValue(id);

  const initialParamsDocument: IDocumentsPaginationParams = useMemo(
    () => ({
      parent_ui_element: { [parentUIElement]: id },
      skip: 1,
      length: 0,
    }),
    [id, parentUIElement]
  );

  const {
    total: totalDocument,
    isLoading: isLoadingDocument,
    doRefresh: setRefreshDocuments,
    onSearchRequest,
  }: IUseFetchV2<
    IGetResponseFileBuckets,
    IDocumentsPaginationParams
  > = useGetListOld({
    getDataApi: getFileBuckets,
    initialParams: initialParamsDocument,
    condition: !!id,
    initialData: 0,
  });

  //обновлять список документов при смене заявку в url
  useEffect(() => {
    if (prevId !== id && prevId !== undefined) {
      onSearchRequest({ [parentUIElement]: id }, 'parent_ui_element');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { setRefreshDocuments, isLoadingDocument, totalDocument };
};

export default useGetTotalDocuments;
