import classNames from 'clsx';
import { Formik } from 'formik';

import React, { FC, memo, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { t } from 'services/utils/translation';
import LoaderFetch from 'components/layouts/LoaderFetch/LoaderFetch';
import {
  SAVE_FIELD_VALUES_ACTION,
  useSaveValueStore,
} from 'hooks/useSaveValueStore';
import IClientSelectOptionV2 from '../../../../services/api/interfacesApi/IClientSelectOptionV2';
import ExternalRequest from '../../../../models/ExternalRequest';
import {
  createExternalRequestV2,
  updateExternalRequestV2,
} from '../../../../services/api/external-request/external-request/externalRequestApi';
import {
  ICreateExternalRequest,
  IUpdateExternalRequest,
} from '../../../../services/api/external-request/external-request/ICreateExternalRequest';
import {
  getExternalRequestInitialValuesFromStore,
  getExternalRequestFieldsValueFromStore,
  getExternalRequestFirstStatusFromStore,
} from '../../../../store/externalRequest/selectors';
import { usePostV3 } from 'hooks/usePostV3';
import { IFormValuesExternalRequestFromTask } from '../../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import toFormValuesExternalRequest from '../../../../pages/external-request/external-requests/ExternalRequestEditPage/utils/toFormValuesExternalRequest/toFormValuesExternalRequest';
import IWrapperExternalRequest from '../../../../services/api/external-request/external-request/IWrapperExternalRequest';
import {
  saveExternalRequestFieldValue,
  setInitialExternalRequest,
} from '../../../../store/externalRequest/actions';
import isEqual from 'react-fast-compare';
import ExternalRequestComments from '../../ExternalRequestCommentForm/ExternalRequestCommentForm';
import { IExternalRequestStatusOld } from '../../../../services/api/external-request/external-request/IExternalRequestStatusOld';
import useNextWindow from 'hooks/useNextWindow';
import { PATH_EXTERNAL_REQUEST_EDIT } from '../../../../services/pathConstants';
import { getParametersRenullForExternalRequest } from '../../../../services/utils/renull/renull';
import { extractValueFromClientSelectOptionV2 } from '../../../../services/utils/selects/selects';
import { IChildInternalTask } from '../../../../services/api/tasks/tasks/IGetPaginatedTasksPayload';
import { IChildExternalRequest } from '../../../../services/api/external-request/external-request/IExternalRequest';
import { IInvoice } from 'services/api/warehouse/IGetInvoicesResponse';
import {
  getCurrentEmployeePkFromStore,
  getCurrentEmployeeFromStore,
} from '../../../../store/general/selectors';
import { IStatusControlExternalRequestState } from '../types/IStateExternalRequestForm';
import ExternalRequestStatusControl from '../ExternalRequestSatusControl/ExternalRequestStatusControl';
import ExternalRequestSectionsWrapper from '../ExternalRequestSectionsWrapper/ExternalRequestSectionsWrapper';
import { createDateFromClientFormForServerV2 } from '../../../../services/utils/dateHelper/dateHelper';
import { EVENT_STATE } from '../ExternalRequestSecondSection/constants';
import deleteUnnecessaryParameters from '../../../../services/utils/deleteUnnecessaryParameters/deleteUnnecessaryParameters';
import {
  getDatetimeActualCommencement,
  getDatetimeActualCompletion,
} from './utils/extractGetDateTimeActualForExternalRequest';
import useExternalRequestSuccessHandler from 'pages/external-request/external-requests/hooks/useTaskSuccessHandler';
import { IS_EVENT_CLOSED_KEY } from 'services/constants/IS_EVENT_CLOSED_KEY';

export interface IFormValuesExternalRequest {
  pk?: number;
  author?: IClientSelectOptionV2<number>;
  external_request_external_code_number?: string;
  external_request_address_of_incident?: string;
  external_request_description?: string;
  external_request_reporter_full_name?: string;
  external_request_reporter_phone_number?: string;
  external_request_source_of_information?: string;
  external_request_date_supposed_duedate?: string;
  external_request_new_comment?: string;
  external_request_status_fk?: number;
  currentStatus?: IExternalRequestStatusOld;
  external_request_warehouse_note?: string;
  external_request_parent_external_request_fk?: number;
  external_request_parent_internal_task_fk?: number;
  external_request_comment_text?: string;
  implementers?: IClientSelectOptionV2[];
  supervisor?: IClientSelectOptionV2;
  executiveImplementer?: IClientSelectOptionV2;
  workCategory?: IClientSelectOptionV2;
  billingAccount?: IClientSelectOptionV2;
  event?: IClientSelectOptionV2;
  external_request_payment_status?: string;
  external_request_payment_amount?: string;
  external_request_payment_kind?: string;
  status?: IClientSelectOptionV2<number>;
  responsibleDepartment?: IClientSelectOptionV2<number>;
  availableStatuses?: IClientSelectOptionV2<number>[];
  performerType?: string;
  isHaveMechanization?: boolean;
  external_request_datetime_of_actual_commencement?: string;
  external_request_datetime_of_actual_completion?: string;
  childInternalTasks?: IChildInternalTask[];
  childExternalRequests?: IChildExternalRequest[];
  affiliate?: IClientSelectOptionV2<number>;
  invoice?: IInvoice[];
  isPruned?: boolean;
  eventState?: EVENT_STATE;
  [IS_EVENT_CLOSED_KEY]?: boolean;
}

const UNNECESSARY_PARAMETERS = [
  'billingAccount',
  'supervisor',
  'executiveImplementer',
  'status',
  'responsibleDepartment',
  'performerType',
  'author',
  'isHaveMechanization',
  'workCategory',
  'currentStatus',
  'implementers',
  'availableStatuses',
  'childInternalTasks',
  'childExternalRequests',
  'isPruned',
  'eventState',
  'event',
  IS_EVENT_CLOSED_KEY,
];

const renullParamsExternalRequest = [
  'external_request_billing_account_fk',
  'external_request_supervisor_dk',
  'external_request_executive_implementer_dk',
  'external_request_work_category_fk',
  'external_request_list_of_implementer_dks',
  'external_request_affiliate_fk',
  'external_request_event_fk',
  'external_request_payment_status',
  'external_request_payment_kind',
  'external_request_payment_amount',
];

const extractConvertParams =
  (firstStatus?: IClientSelectOptionV2) =>
  (values: IFormValuesExternalRequest) => {
    const {
      status,
      supervisor,
      executiveImplementer,
      external_request_date_supposed_duedate,
      billingAccount,
      responsibleDepartment,
      workCategory,
      implementers,
      affiliate,
      event,
      external_request_payment_amount,
      external_request_payment_kind,
      external_request_payment_status,
    } = values;

    const datetimeActualCommencement = getDatetimeActualCommencement(
      status?.value
    );

    const datetimeActualCompletion = getDatetimeActualCompletion(status?.value);

    const newParams = {
      ...values,
      ...datetimeActualCommencement,
      ...datetimeActualCompletion,
      external_request_payment_kind: external_request_payment_kind || undefined,
      external_request_payment_status:
        external_request_payment_status || undefined,
      external_request_payment_amount:
        external_request_payment_amount || undefined,

      external_request_work_category_fk:
        extractValueFromClientSelectOptionV2(workCategory),
      external_request_status_fk: extractValueFromClientSelectOptionV2(
        firstStatus ?? status
      ),
      external_request_supervisor_dk:
        extractValueFromClientSelectOptionV2(supervisor),
      external_request_executive_implementer_dk:
        extractValueFromClientSelectOptionV2(executiveImplementer),
      external_request_date_supposed_duedate:
        external_request_date_supposed_duedate
          ? createDateFromClientFormForServerV2(
              external_request_date_supposed_duedate
            )
          : undefined,
      external_request_billing_account_fk:
        extractValueFromClientSelectOptionV2(billingAccount),

      external_request_responsible_department_fk:
        extractValueFromClientSelectOptionV2(responsibleDepartment),
      external_request_list_of_implementer_dks: implementers
        ? implementers.map(
            (implementer: IClientSelectOptionV2<number>) =>
              extractValueFromClientSelectOptionV2(implementer) as number
          )
        : undefined,
      external_request_affiliate_fk:
        extractValueFromClientSelectOptionV2(affiliate),
      external_request_event_fk: extractValueFromClientSelectOptionV2(event),
    };

    const renull =
      values?.pk &&
      getParametersRenullForExternalRequest(
        newParams,
        renullParamsExternalRequest
      );

    const clearedBodyParams: Omit<
      ICreateExternalRequest | IUpdateExternalRequest,
      | 'billingAccount'
      | 'supervisor'
      | 'executiveImplementer'
      | 'status'
      | 'responsibleDepartment'
      | 'implementers'
      | 'eventState'
      | 'event'
    > = deleteUnnecessaryParameters(newParams, UNNECESSARY_PARAMETERS, [
      'external_request_list_of_implementer_dks',
    ]);

    return {
      ...clearedBodyParams,
      ...renull,
    };
  };

export interface IStatusesExternalRequestState {
  currentStatus: IExternalRequestStatusOld | null;
  availableStatuses: IClientSelectOptionV2<number>[];
}

export type FormValuesExternalRequestValueType =
  IFormValuesExternalRequest[keyof IFormValuesExternalRequest];

const extractHasChange =
  (isNew: boolean) =>
  (
    initialValue: FormValuesExternalRequestValueType,
    currentValue: FormValuesExternalRequestValueType
  ) =>
    !isNew && !isEqual(initialValue, currentValue);

const extractHandlePayloadCreate =
  (isSaveAndExit: boolean, nextEditPage: Function) =>
  ({
    external_request: { external_request_pk: pk },
  }: IWrapperExternalRequest) => {
    if (!isSaveAndExit) {
      nextEditPage(pk);
    }
  };

const isDisabledField = (
  status?: string,
  isViewOnly?: boolean,
  isLoading?: boolean
): boolean => isLoading || status === 'Архив' || !!isViewOnly;

type PropsType = {
  className?: string;
  externalRequestPk?: number;
  setActiveContent?: Function;
  initialValuesFromTask?: IFormValuesExternalRequestFromTask | null;
  isLoadingExternalRequest?: boolean;
  isDuplicate?: boolean;
  refreshHistory?: () => void;
  initialStatuses?: IClientSelectOptionV2[];
  refreshExternalRequest?: () => void;
  isViewOnly?: boolean;
  billingAccountId?: number;
  eventPk?: number;
};

const ExternalRequestForm: FC<PropsType> = (props) => {
  const {
    externalRequestPk,
    className,
    isDuplicate,
    isLoadingExternalRequest,
    initialValuesFromTask,
    refreshHistory,
    refreshExternalRequest,
    isViewOnly,
    billingAccountId,
    eventPk,
  } = props;

  const isNew = !externalRequestPk || !!isDuplicate;
  const hasChange = extractHasChange(isNew);
  const currentUser = useSelector(getCurrentEmployeeFromStore);

  const firstStatusFromStore = useSelector(
    getExternalRequestFirstStatusFromStore
  );
  const [externalRequest, setExternalRequest] = useState<ExternalRequest>(
    new ExternalRequest(
      externalRequestPk,
      currentUser,
      billingAccountId,
      eventPk
    )
  );

  const [isSaveAndExit, setIsSaveAndExit] = useState<boolean>(false);

  const [isLoadingUpdateForm, setIsLoadingUpdateForm] =
    useState<boolean>(false);

  const [statuses, setStatuses] = useState<IStatusesExternalRequestState>({
    availableStatuses: [],
    currentStatus: null,
  });

  const [nextStatusValue, setNextStatusValue] = useState<number | null>(null);

  const handlerStatusSelect = (status: IClientSelectOptionV2) =>
    setNextStatusValue(status?.value);

  const dispatchRedux = useDispatch();

  const initialExternalRequestValue = useSelector(
    getExternalRequestInitialValuesFromStore
  );

  const {
    availableStatuses,
    currentStatus,
    status: initialStatus,
  } = initialExternalRequestValue;

  const externalRequestFieldsValue = useSelector(
    getExternalRequestFieldsValueFromStore
  );

  const authorId = useSelector(getCurrentEmployeePkFromStore);

  useEffect(() => {
    if (!isNew || isDuplicate) {
      setExternalRequest(externalRequestFieldsValue as ExternalRequest);
      if (!isDuplicate) {
        availableStatuses &&
          currentStatus &&
          setStatuses({
            availableStatuses,
            currentStatus: currentStatus,
          });
      }
    }
  }, [
    availableStatuses,
    currentStatus,
    dispatchRedux,
    externalRequestFieldsValue,
    isDuplicate,
    isNew,
  ]);

  const saveValueInStoreForExternalRequest = useSaveValueStore(
    SAVE_FIELD_VALUES_ACTION.externalRequest
  );

  const successHandler = useExternalRequestSuccessHandler();

  const handleUpdatePayload = (payload: IWrapperExternalRequest) => {
    if (!isSaveAndExit && externalRequestPk) {
      const externalRequestFormValues = toFormValuesExternalRequest(
        payload.external_request
      );
      dispatchRedux(saveExternalRequestFieldValue(externalRequestFormValues));
      dispatchRedux(setInitialExternalRequest(externalRequestFormValues));
      refreshHistory && refreshHistory();
    }
  };

  const nextEditPage = useNextWindow(PATH_EXTERNAL_REQUEST_EDIT);

  const handlePayloadCreate = extractHandlePayloadCreate(
    isSaveAndExit,
    nextEditPage
  );

  const creteConvertParams = extractConvertParams(firstStatusFromStore);
  const updateConvertParams = extractConvertParams();

  const createExternalRequest = usePostV3({
    fetchApi: createExternalRequestV2,
    successMessage: t('Заявка создана успешно.'),
    successHandler: () => successHandler(isSaveAndExit),
    convertParams: creteConvertParams,
    handlePayload: handlePayloadCreate,
    viewClientTextToast: true,
  });

  const updateExternalRequest = usePostV3({
    fetchApi: updateExternalRequestV2,
    successMessage: t('Заявка изменена успешно.'),
    successHandler: () => successHandler(isSaveAndExit),
    convertParams: updateConvertParams,
    handlePayload: handleUpdatePayload,
    viewClientTextToast: true,
  });

  const handleUpdateExternalRequest = async (
    values: IFormValuesExternalRequest,
    setErrors: Function,
    resetForm: Function
  ) => {
    setIsLoadingUpdateForm(true);
    await updateExternalRequest(values, setErrors, resetForm);

    setIsLoadingUpdateForm(false);
  };
  const submitForm = async (
    values: IFormValuesExternalRequest,
    setErrors: Function,
    resetForm: Function
  ) => {
    values?.pk && !isDuplicate
      ? await handleUpdateExternalRequest(values, setErrors, resetForm)
      : await createExternalRequest(values, setErrors, resetForm);
  };

  const initialValues = initialValuesFromTask || externalRequest;
  const viewForm = isNew || !isLoadingExternalRequest;

  const isDisable = isDisabledField(
    initialValues.currentStatus?.name,
    isViewOnly,
    isLoadingUpdateForm
  );

  return (
    <Row className="mr-0 ">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={ExternalRequest.validationSchema(
          nextStatusValue,
          currentStatus?.id
        )}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          await submitForm(values, setErrors, resetForm);
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => {
          const handlerFormProps = {
            saveValueInStoreForExternalRequest,
            hasChange,
          };

          const formStateStatusControl: IStatusControlExternalRequestState = {
            isDisable,
            initialValues: { initialStatus },
            isSubmitting,
            statuses,
          };

          return (
            <Col>
              {viewForm && (
                <Form
                  onSubmit={handleSubmit}
                  noValidate={true}
                  className={classNames(
                    className,
                    'd-flex flex-column flex-md-row justify-content-md-between  mr-4 mr-md-auto pl-3'
                  )}
                  style={{ maxWidth: '100%' }}
                >
                  <div
                    className={classNames('w-100')}
                    style={{ maxWidth: '800px' }}
                  >
                    <Form
                      onSubmit={handleSubmit}
                      noValidate={true}
                      className={classNames(
                        className,
                        'd-flex flex-column flex-md-row justify-content-md-between ml-4 mr-4 mr-md-auto pl-3'
                      )}
                      style={{ maxWidth: '100%' }}
                    >
                      <ExternalRequestSectionsWrapper
                        customHandlers={{
                          hasChange,
                          saveValueInStoreForExternalRequest,
                        }}
                        formState={{
                          isDisable,
                          isNew,
                          isDuplicate,
                          currentStatus,
                        }}
                      />
                    </Form>
                    {!isNew &&
                      !isDuplicate &&
                      !initialValuesFromTask &&
                      refreshExternalRequest && (
                        <ExternalRequestStatusControl
                          handlerFormProps={{
                            ...handlerFormProps,
                            refreshExternalRequest,
                            handlerStatusSelect: handlerStatusSelect,
                          }}
                          state={formStateStatusControl}
                        />
                      )}
                    <FormGroup
                      className={classNames('d-flex justify-content-end mt-4', {
                        'd-md-block': values.pk != null,
                      })}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        onClick={() => setIsSaveAndExit(false)}
                        disabled={isSubmitting || isDisable}
                      >
                        {t('Сохранить')}
                      </Button>

                      <Button
                        className="ml-3"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting || isDisable}
                        onClick={() => {
                          setIsSaveAndExit(true);
                        }}
                      >
                        {t('Сохранить и выйти')}
                      </Button>
                    </FormGroup>
                  </div>
                </Form>
              )}
              {isLoadingExternalRequest && <LoaderFetch />}
            </Col>
          );
        }}
      </Formik>
      <div
        className={classNames('ml-md-4', {
          'd-none': externalRequestPk == null,
        })}
      >
        {externalRequestPk && !isDuplicate && (
          <ExternalRequestComments
            pk={externalRequestPk}
            authorDk={authorId}
            viewOnly={isDisable}
          />
        )}
      </div>
    </Row>
  );
};

ExternalRequestForm.whyDidYouRender = false;

export default memo(ExternalRequestForm);
