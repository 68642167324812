import { FC, memo, useMemo } from 'react';
import TaskHeader from 'components/tasks/TaskHeader/TaskHeader';
import { PATH_EXTERNAL_REQUEST_TABLE } from '../../../../services/pathConstants';
import useGetIdFromPath from 'hooks/useGetIdFromPath/useGetIdFromPath';
import useTaskSuccessHandler from '../hooks/useTaskSuccessHandler';
import Subheader from 'components/layouts/Subheader/Subheader';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import TaskForm from 'components/tasks/TaskForm/TaskForm';
import { IGetTaskPayload } from '../../../../services/api/tasks/tasks/IGetTaskPayload';
import toFormValuesTask from 'store/utils/toFormValuesTaskV2';
import { readTaskApiV2 } from 'services/api/tasks/tasks/tasksApiV2';
import useGetRefreshingData from 'hooks/useGetRefreshingData';

const TaskCreateChildTaskV2: FC = () => {
  const taskPk = Number(useGetIdFromPath());

  const initialParams = useMemo(
    () => ({
      pk: taskPk,
    }),
    [taskPk]
  );

  const convertTask = (data: IGetTaskPayload) => {
    const formValuesTask = toFormValuesTask(data?.internal_task, true, true);

    return formValuesTask;
  };

  const { data: initialValuesFromParentTask, isLoading: isLoadingTask } =
    useGetRefreshingData({
      getDataApi: readTaskApiV2,
      initialData: null,
      params: initialParams,
      condition: !!taskPk,
      convertData: convertTask,
    });

  const successHandler = useTaskSuccessHandler(PATH_EXTERNAL_REQUEST_TABLE);
  return (
    <MainLayout>
      <Subheader />
      <TaskHeader isInitialValuesFromOrder isDuplicate />
      <TaskForm
        initialValuesFromParentTask={initialValuesFromParentTask}
        successHandler={successHandler}
        viewOnly={false}
        isLoadingTask={isLoadingTask}
        isDuplicate
      />
    </MainLayout>
  );
};

export default memo(TaskCreateChildTaskV2);
