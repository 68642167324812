import {
  IFileBucket,
  IGetResponseFileBuckets,
} from './IGetResponseFileBuckets';
import {
  IValidationError,
  ISuccessfulResponse,
} from '../../interfacesApi/IV2ResponseStatus';
import { IDocumentsPaginationParams } from './IFiltersDocuments';
import { IGetFileBucket } from './IGetFileBucket';
import UndercurrentApiClient from 'services/api/UndercurrentApiClient';
import { UNDERCURRENT_CHILD_MODULES } from '../../../constants/UNDERCURRENT_CHILD_MODULES';
import FileBucket from 'models/FileBucket';
import TBaseOldPostResponse from 'services/api/interfacesApi/TBaseOldPostResponse';
import ApiActions from '../../apiActions';

export async function getFileBuckets(
  params: IDocumentsPaginationParams
): Promise<IGetResponseFileBuckets> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET
  );
  return await api.getPaginated(params);
}

export async function deleteFileBucketApi(
  entity_pk: number
): Promise<ISuccessfulResponse<IFileBucket> | IValidationError> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET
  );
  return await api.delete(entity_pk);
}

export async function getFileBucketApi(pk: number): Promise<IGetFileBucket> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET
  );
  return api.read(pk);
}

export async function createFileBucketApi(
  params: FormData
): Promise<TBaseOldPostResponse<FileBucket>> {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET
  );

  return api.postFile({ params, action: ApiActions.CREATE });
}

export async function updateFileBucketApi(
  params: FormData,
  pk?: number | string
): Promise<TBaseOldPostResponse<IGetFileBucket>> {
  if (!pk) {
    throw new Error('missing pk - update FileBucket');
  }

  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET
  );

  return api.postFile({
    pk,
    params,
    action: ApiActions.UPDATE,
  });
}

export const doArchivedDocumentApi = async ({
  params,
  pk,
}: {
  params: FormData;
  pk?: number | string;
}) => {
  const api = new UndercurrentApiClient().documentMethodCreator(
    UNDERCURRENT_CHILD_MODULES.FILE_BUCKET
  );
  return api.postFile({
    pk,
    params,
    action: ApiActions.UPDATE,
  });
};

export async function createLinkFromFileApiV2(fileNames: string) {
  const api = () =>
    new UndercurrentApiClient().documentMethodCreator(
      UNDERCURRENT_CHILD_MODULES.DOCUMENTS
    );
  return api().uploadsAndCreateFileUrl(fileNames);
}

export async function createBlobFromFileApiV2(fileNames: string) {
  const api = () =>
    new UndercurrentApiClient().documentMethodCreator(
      UNDERCURRENT_CHILD_MODULES.DOCUMENTS
    );
  return api().uploadsAndCreateBlob(fileNames);
}

export async function createFilesApiV2(filenames: string[]) {
  const api = () =>
    new UndercurrentApiClient().documentMethodCreator(
      UNDERCURRENT_CHILD_MODULES.UPLOADS
    );
  return api().uploadsAndCreateFiles(filenames);
}

export function getFileLink(filenames: string) {
  const api = () =>
    new UndercurrentApiClient().documentMethodCreator(
      UNDERCURRENT_CHILD_MODULES.UPLOADS
    );
  return api().getFullUrl(filenames);
}
