import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PATH_LOGIN } from '../../../services/pathConstants';
import MainLayout from 'components/layouts/MainLayout/MainLayout';
import LoaderFetch from '../../layouts/LoaderFetch/LoaderFetch';
import { useSelector } from 'react-redux';
import { getLoggedInFromStore } from '../../../store/general/selectors';
import { getActiveInstallationClientName } from 'store/auth/selectors';
import useSetDocumentTitle from 'hooks/useSetDocumentTitle';
import usePathNavigationPersistence from './hooks/usePathNavigationPersistence';
import { ToastContainer } from 'react-toastify';
import useTrackAndClearFormOnRouteChange from './hooks/useTrackAndClearFormOnRouteChange ';

type AuthRouteProps = {
  loading?: boolean;
};

const AuthRoute: FC<AuthRouteProps> = ({ loading }) => {
  const loggedIn = useSelector(getLoggedInFromStore);
  const activeInstallationName = useSelector(getActiveInstallationClientName);
  const from = useLocation().state?.from;
  useSetDocumentTitle(activeInstallationName);
  usePathNavigationPersistence();
  useTrackAndClearFormOnRouteChange();

  if (loading) {
    return (
      <MainLayout>
        <LoaderFetch />
      </MainLayout>
    );
  }

  return loggedIn ? (
    <>
      <ToastContainer />
      <Outlet />
    </>
  ) : (
    <Navigate to={PATH_LOGIN} state={{ from }} />
  );
};

export default AuthRoute;
