import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import { t } from 'services/utils/translation';

export const getDocumentsUIText = () => {
  const getHintForSentToArchive = extractGetLeftValueIfTrue(
    t('Отправлен но в архив'),
    t('Не Отправлен но в архив ')
  );

  const TO_SENT_TO_ARCHIVE_TEXT = t('Отправить в архив');

  const getHintForReceivedToArchive = extractGetLeftValueIfTrue(
    t('Принято в архив'),
    t('Не принято в архив ')
  );

  const TO_RECEIVED_TO_ARCHIVE_TEXT = t('Принять в архив');

  const getTitleTable = extractGetLeftValueIfTrue(
    t('Документы'),
    t('Документы для принятия в архив')
  );

  return {
    getTitleTable,
    TO_RECEIVED_TO_ARCHIVE_TEXT: TO_RECEIVED_TO_ARCHIVE_TEXT,
    getHintForReceivedToArchive: getHintForReceivedToArchive,
    TO_SENT_TO_ARCHIVE_TEXT,
    getHintForSentToArchive,
  };
};
