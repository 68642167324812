import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTaskCurrentValues } from 'store/task/actions';
import { getTaskFormValuesFromStore } from 'store/task/selectors';
import { useFormikContext } from 'formik';
import { IFormValuesTask } from '../TaskForm';
import Task from 'models/Task';
import { NEW_TASK_KEY } from '../constants/NEW_TASK';
import useBeforeOnload from 'hooks/useBeforeOnload/useBeforeOnload';
import isInitialValueTaskAndCurrentValueEqual from 'pages/internal-task/task/TaskEditPage/utils/isInitialValueTaskAndCurrentValueEqual';
//TODO много флагов - здесь нужен рефакторинг. мы при любом сохранеии должны отменять сохранение value
const useSaveTaskFieldValues = (
  isSubmit: boolean,

  setCurrentFormValues?: React.Dispatch<
    React.SetStateAction<IFormValuesTask | null>
  >
) => {
  const { values } = useFormikContext<IFormValuesTask | Task>();
  const dispatch = useDispatch();
  const formValuesFromStore = useSelector(getTaskFormValuesFromStore);

  const valuesRef = useRef<IFormValuesTask | Task>(values);
  const currentTaskPk = values?.taskPk;
  const isNew = !values?.taskPk;
  const initialFormValues = currentTaskPk
    ? formValuesFromStore?.[currentTaskPk]?.initial
    : null;
  const [isMounted, setIsMounted] = useState(false);

  const isCreatingSubtask = useMemo(() => {
    return Boolean(
      (!values?.taskPk && values?.internal_task_parent_internal_task_fk) ||
        (!values?.taskPk && values?.internal_task_parent_external_request_fk)
    );
  }, [values]);

  // Проверка на необходимость сохранения формы
  const shouldSaveFormValues = useCallback(() => {
    if (isSubmit) {
      return false;
    } else {
      const isEqualValues = isInitialValueTaskAndCurrentValueEqual(
        valuesRef.current,
        initialFormValues
      );
      return isMounted && !isCreatingSubtask && !isEqualValues;
    }
  }, [initialFormValues, isCreatingSubtask, isMounted, isSubmit]);

  const handleSave = useCallback(
    (currentValues: IFormValuesTask) => {
      dispatch(saveTaskCurrentValues(`${currentValues.taskPk}`)(currentValues));
    },
    [dispatch]
  );

  const handleSaveNew = useCallback(
    (currentValues: IFormValuesTask) => {
      dispatch(saveTaskCurrentValues(NEW_TASK_KEY)(currentValues));
    },
    [dispatch]
  );

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  // Устанавливаем текущие значения формы для хука useManageTaskFormEntry при изменении формы
  useEffect(() => {
    if (setCurrentFormValues) {
      setCurrentFormValues(values);
    }
  }, [setCurrentFormValues, values]);

  // Сохранение новых значений при размонтировании для новой задачи
  useEffect(() => {
    return () => {
      if (isNew && shouldSaveFormValues()) {
        handleSaveNew(valuesRef.current);
      }
    };
  }, [
    handleSaveNew,
    initialFormValues,
    isCreatingSubtask,
    isMounted,
    isNew,
    shouldSaveFormValues,
  ]);

  // Сохранение значений для существующих задач при размонтировании
  useEffect(() => {
    return () => {
      if (initialFormValues && !isNew && shouldSaveFormValues()) {
        handleSave(valuesRef.current);
      }
    };
  }, [
    handleSave,
    initialFormValues,
    isCreatingSubtask,
    isMounted,
    isNew,
    shouldSaveFormValues,
  ]);

  useBeforeOnload(() => {
    if (!isNew && initialFormValues && shouldSaveFormValues()) {
      handleSave(valuesRef.current);
    }
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return null;
};

export default useSaveTaskFieldValues;
