import { useSaveValueStore, SAVE_FIELD_VALUES_ACTION } from 'hooks/useSaveValueStore';
import useExtractHandleBlurWrappers from 'hooks/useHandleBlurWrapersForExtenalRequest';
import { useFormikContext } from 'formik';
import { IFormValuesExternalRequestFromTask } from '../../../../pages/external-request/external-requests/ExternalRequestFromTask/ExternalRequestFromTask';
import ExternalRequest from 'models/ExternalRequest';

const useHandleBlurWrappersForExternalRequest = () => {
  const { values, handleBlur } = useFormikContext<
    IFormValuesExternalRequestFromTask | ExternalRequest
  >();

  const {
    external_request_external_code_number,
    external_request_source_of_information,
    external_request_reporter_full_name,
    external_request_reporter_phone_number,
    external_request_description,
    external_request_payment_amount,
    external_request_address_of_incident,
  } = values;

  const saveValueInStoreForExternalRequest = useSaveValueStore(
    SAVE_FIELD_VALUES_ACTION.externalRequest
  );

  const handleBlurCodeNumber = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_external_code_number });

  const handleBlurSourceOfInformation = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_source_of_information });

  const handleBlurReporterFullname = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_reporter_full_name });

  const handleBlurReporterPhoneNumber = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_reporter_phone_number });

  const handleBlurDescription = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_description });

  const handleBlurPaymentAmount = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_payment_amount });

  const handleBlurAddress = useExtractHandleBlurWrappers(
    handleBlur,
    saveValueInStoreForExternalRequest
  )({ external_request_address_of_incident });

  return {
    handleBlurCodeNumber,
    handleBlurSourceOfInformation,
    handleBlurReporterFullname,
    handleBlurReporterPhoneNumber,
    handleBlurDescription,
    handleBlurPaymentAmount,
    handleBlurAddress,
  };
};

export default useHandleBlurWrappersForExternalRequest;
