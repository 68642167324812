import { IExternalRequestFilters } from '../../services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import {
  SET_INITIAL_EXTERNAL_REQUEST,
  CLEAR_EXTERNAL_REQUEST_VALUE,
  SET_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
  SET_IS_ACTIVE_PK_FILTER,
  SET_IS_ACTIVE_BILLING_ACCOUNT_ARRAY_FILTER,
  SET_IS_ACTIVE_WORK_CATEGORY_FILTER,
  SET_ACCESS_POLICY_EXTERNAL_REQUEST,
} from './actionTypes';
import {
  SET_MARKED_EXTERNAL_REQUEST,
  EXTERNAL_REQUESTS_SAVE_FILTERS,
  EXTERNAL_REQUESTS_CLEAR_FILTERS,
  SET_EXTERNAL_REQUEST_FIELDS_VALUE,
} from './actionTypes';
import { initialExternalRequestFilters } from './initialExternalRequestFilters';
import { SET_EXTERNAL_REQUEST_FIRST_STATUS } from './actionTypes';
import IClientSelectOptionV2 from '../../services/api/interfacesApi/IClientSelectOptionV2';
import { IFormValuesExternalRequest } from '../../components/external-request/ExternalRequestForm/ExternalRequestForm/ExternalRequestForm';
import { IExternalRequestClientAccessPolicy } from './initial';

//FILTERS

export const saveExternalRequestFilters = (
  filters: IExternalRequestFilters
) => ({
  type: EXTERNAL_REQUESTS_SAVE_FILTERS,
  payload: filters,
});

export const clearExternalRequestFilters = (billingAccountId?: number) => ({
  type: EXTERNAL_REQUESTS_CLEAR_FILTERS,
  payload: !billingAccountId
    ? initialExternalRequestFilters
    : {
        ...initialExternalRequestFilters,
        external_request_billing_account_fk: billingAccountId,
      },
});

export const setMarkedExternalRequest = (id: number) => ({
  type: SET_MARKED_EXTERNAL_REQUEST,
  payload: id,
});

export const setIsActiveImplementerDkArrayFilter = (
  isActiveImplementerDkArrayFilter: boolean
) => ({
  type: SET_IS_ACTIVE_IMPLEMENTER_DK_ARRAY_FILTER,
  payload: isActiveImplementerDkArrayFilter,
});

export const setIsActivePkFilter = (IsActivePkFilter: boolean) => ({
  type: SET_IS_ACTIVE_PK_FILTER,
  payload: IsActivePkFilter,
});

export const setIsActiveBillingAccountArrayFilter = (
  IsActiveBillingAccountArrayFilter: boolean
) => ({
  type: SET_IS_ACTIVE_BILLING_ACCOUNT_ARRAY_FILTER,
  payload: IsActiveBillingAccountArrayFilter,
});

export const setIsActiveWorkCategoryFilter = (
  isActiveWorkCategoryFilter: boolean
) => ({
  type: SET_IS_ACTIVE_WORK_CATEGORY_FILTER,
  payload: isActiveWorkCategoryFilter,
});

//FORM-VALUES

export const setInitialExternalRequest = (
  formValues: IFormValuesExternalRequest
) => {
  return {
    type: SET_INITIAL_EXTERNAL_REQUEST,
    payload: formValues,
  };
};

export const saveExternalRequestFieldValue = (
  payload?: IFormValuesExternalRequest
) => ({
  type: SET_EXTERNAL_REQUEST_FIELDS_VALUE,
  payload,
});

export const clearExternalRequestFieldValue = () => ({
  type: CLEAR_EXTERNAL_REQUEST_VALUE,
});

export const setExternalRequestFirstStatus = (
  firstStatus?: IClientSelectOptionV2
) => ({
  type: SET_EXTERNAL_REQUEST_FIRST_STATUS,
  payload: firstStatus,
});

// OTHER

export const setExternalRequestAccessPolicy = (
  payload: IExternalRequestClientAccessPolicy
) => ({
  type: SET_ACCESS_POLICY_EXTERNAL_REQUEST,
  payload,
});
