import { getFileLink } from 'services/api/documents/file-bucket/fileBucketApi';

const DELAY_MS = 400;

const onDownloadAll = (fileNames: string[]) => {
  const urls = fileNames.map((fileName: string) => getFileLink(fileName));
  urls.forEach((url: string, i: number) => {
    setTimeout(() => (window.location.href = url), DELAY_MS + i * DELAY_MS);
  });
};

export default onDownloadAll;
