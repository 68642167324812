export const getExternalParentFk = (props: {
  parentFk?: number;
  pk?: number;
  isDuplicate?: boolean;
  isChildrenExternalRequest?: boolean;
}) => {
  const { parentFk, isDuplicate, isChildrenExternalRequest, pk } = props;
  if (isDuplicate && !isChildrenExternalRequest) {
    return undefined;
  }
  return isChildrenExternalRequest ? pk : parentFk;
};
