import useGetRefreshingData from 'hooks/useGetRefreshingData';
import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IEventWrapper } from 'services/api/organizationsV2/event/IEventWrapper';
import { readEventApi } from 'services/api/organizationsV2/event/eventApi';
import toEventDetails from './toEventDetails/toEventDetails';
import { useFormikContext } from 'formik';
import ExternalRequest from 'models/ExternalRequest';
import { targetAccessPolicyEvent } from 'services/constants/TargetForAccessPolicy/TargetAccessPolicyOrganization';
import useHaveAccessPolicyToActs from 'hooks/useHaveAccessPolicyToActs/useHaveAccessPolicyToActs';
import { IS_EVENT_CLOSED_KEY } from 'services/constants/IS_EVENT_CLOSED_KEY';

const useGetEventDetails = (eventPk?: number) => {
  const location = useLocation();
  const { setFieldValue } = useFormikContext<ExternalRequest>();

  const initialParams = useMemo(() => {
    return { pk: Number(eventPk) };
  }, [eventPk]);

  const [eventHaveAccessToRead] = useHaveAccessPolicyToActs({
    targetAccessPolicy: targetAccessPolicyEvent,
    targetAction: ['R'],
  });

  const { data: eventDetails, isLoading: isLoadingEvent } =
    useGetRefreshingData({
      getDataApi: readEventApi,
      params: initialParams,
      condition: !!eventPk && eventHaveAccessToRead,
      convertData: (response: IEventWrapper) => toEventDetails(response.event),
      initialData: null,
    });

  useEffect(() => {
    if (eventDetails) {
      setFieldValue(IS_EVENT_CLOSED_KEY, eventDetails.isClosed);
    }
  }, [eventDetails, eventDetails?.isClosed, setFieldValue]);

  return { isLoadingEvent, eventDetails, location };
};

export default useGetEventDetails;
