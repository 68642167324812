import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IExternalRequestFilters } from 'services/api/external-request/external-request/useGetExternalRequestNavigationHandlers';
import { getBillingAccountExternalRequestTableFiltersFromStore } from 'store/billingAccount/selectors';
import { getEventExternalRequestFiltersFromStore } from 'store/event/selectors';
import { getExternalRequestFiltersFromStore } from 'store/externalRequest/selectors';
import { initialExternalRequestFilters } from 'store/externalRequest/initialExternalRequestFilters';

type PropsTypeUseGetInitialExternalRequestFilters = {
  eventPk?: number;
  billingAccountPk?: number;
};

const useGetInitialExternalRequestFilters = ({
  eventPk,
  billingAccountPk,
}: PropsTypeUseGetInitialExternalRequestFilters) => {
  const defaultTableFilters = useSelector(
    getExternalRequestFiltersFromStore,
    isEqual
  );

  const defaultBillingAccountFilters = useSelector(
    getBillingAccountExternalRequestTableFiltersFromStore,
    isEqual
  );

  const defaultEventExternalRequestFilters = useSelector(
    getEventExternalRequestFiltersFromStore,
    isEqual
  );

  const initialParams: IExternalRequestFilters = useMemo(() => {
    if (billingAccountPk) {
      return {
        ...initialExternalRequestFilters,
        ...defaultBillingAccountFilters?.[billingAccountPk],
        external_request_billing_account_fk: billingAccountPk,
      };
    }
    if (eventPk) {
      return {
        ...initialExternalRequestFilters,
        ...defaultEventExternalRequestFilters?.[eventPk],
        external_request_event_fk: eventPk,
      };
    }

    return defaultTableFilters;
  }, [
    billingAccountPk,
    defaultBillingAccountFilters,
    defaultEventExternalRequestFilters,
    defaultTableFilters,
    eventPk,
  ]);

  return initialParams;
};

export default useGetInitialExternalRequestFilters;
