import { t } from '../../services/utils/translation';
import { mergeArraysByProperty } from '../../services/utils/misc/misc';
import { SetStateAction, useCallback } from 'react';
import { IForSelectRequestV2 } from 'services/utils/generateMethodForSelect/generateMethodForSelect';
import { IBaseResponse } from 'services/api/interfacesApi/IBaseResponse';
import { IForSelectResponseV2 } from 'components/controls/DynamicSelectFormikPaginatedApiV2/IFroSelectOptionV2';
import IBaseParams from 'services/api/interfacesApi/IBaseParams';
import { OptionsList } from 'react-select-async-paginate';
import { useGetPost } from '../useGetPost';
import IClientSelectOptionV2 from 'services/api/interfacesApi/IClientSelectOptionV2';
import useExtractHandleSelectResponse from './useExtractHandleSelectResponse';

const ERROR_MESSAGE = t(
  'Возникли проблемы при загрузке данных. Обратитесь, пожалуйста, к администратору'
);

type PropsType = {
  selectHandler: (
    params: IBaseParams<IForSelectRequestV2>
  ) => Promise<IBaseResponse<IForSelectResponseV2>>;
  setLoadedOptions: (value: SetStateAction<IClientSelectOptionV2[]>) => void;
  loadedOptions: IClientSelectOptionV2[];
  currentId?: string;
};

const useLoadOptionsInForSelect = (props: PropsType) => {
  const { selectHandler, setLoadedOptions, loadedOptions, currentId } = props;

  const loadOptions = useCallback(
    (payload: IForSelectResponseV2) =>
      setLoadedOptions(
        mergeArraysByProperty(loadedOptions, payload.list_of_items)
      ),
    [loadedOptions, setLoadedOptions]
  );

  const request = useGetPost({
    fetchApi: selectHandler,
    errorMessage: ERROR_MESSAGE,
    viewToast: false,
    handlePayload: loadOptions,
  });

  const handleSelectResponse = useExtractHandleSelectResponse(currentId);

  return useCallback(
    async (
      search: string,
      alreadyLoadedOptions: OptionsList<any>,
      { page }: any
    ) => {
      const params = { search, page };
      const payload = await request(params);
      return handleSelectResponse(page, payload);
    },
    [handleSelectResponse, request]
  );
};

export default useLoadOptionsInForSelect;
