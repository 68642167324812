import { useSelector } from 'react-redux';

import { getCurrentEmployeeAccessPolicyFromStore } from 'store/general/selectors';
import { TCRUDA } from '../../services/api/staff/access-policy/IAccessPolicy';
import { useMemo } from 'react';
import ITargetAccessPolicy from 'services/interfaces/ITargetAccessPolicy';
import haveAccessPolicy from './haveAccessPolicy';

type PropsType = {
  targetAccessPolicy: ITargetAccessPolicy;
  targetAction: TCRUDA[];
  dontCall?: boolean;
};

const useHaveAccessPolicyToActs = ({
  targetAccessPolicy,
  targetAction: targetActions,
  dontCall = false,
}: PropsType): boolean[] => {
  const accessPolicyStatement = useSelector(
    getCurrentEmployeeAccessPolicyFromStore
  );
  return useMemo(() => {
    if (!dontCall) {
      return haveAccessPolicy(
          accessPolicyStatement,
          targetAccessPolicy,
          targetActions
      );
    } else {
      return [];
    }
  }, [accessPolicyStatement, dontCall, targetAccessPolicy, targetActions]);
};

export default useHaveAccessPolicyToActs;
